import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Button, Checkbox, Paper, Divider } from '@mui/material';
import { getTodayDateTime } from 'services/utils/date-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from 'services/utils/users-service';
import {
  verifyAllergies,
  markEmrConfirmed,
  markPatientConfirmedAllergies,
} from 'actions/action-allergies';
import { getAllergiesOrderedBySeverity } from 'services/utils/allergy-service';

import { QuaternaryVerificationPanel } from 'components/form/verification/verification-panel';
import compose from 'recompose/compose';
import {
  ALLERGIES_NOT_VERIFIED,
  ALLERGIES_VERIFIED,
  PATIENT_ALLERGY_STATUS_ACTIVE,
  NO_ALLERGY_INFORMATION_AVAILABLE_ID,
  ENSURE_ALLERGIES_VERIFIED,
} from 'constants/index';
import { resolveAudit } from 'actions/action-audit';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import { ReactSelect } from 'components/form/field/react-select';
import { CompleteIcon } from 'components/icons/icons';
import classNames from 'classnames';
import PatientAllergiesForm from './patient-allergies-form';
import { styles } from '../patient-clinical-styles';
import PatientAllergiesTable from './patient-allergies-table';

export const RENDERING_SECTIONS = {
  ALLERGIES_LIST: 'ALLERGIES_LIST',
  ADD_ALLERGY_FORM: 'ADD_ALLERGY_FORM',
};

function AllergiesList(props) {
  const {
    classes,
    minimal,
    stretched,
    options,
    readOnly = false,
    allergies: allergiesFromProp = null,
    cancelAddForm = null,
    postSave = null,
  } = props;

  const patient = useSelector(state => state.patient);
  const auditState = useSelector(state => state.audit);
  const currentUser = useSelector(state => state.auth.currentUser);
  let allergies = useSelector(state => state.allergies.allergiesList);

  if (
    allergiesFromProp !== null &&
    allergiesFromProp !== undefined &&
    Array.isArray(allergiesFromProp)
  ) {
    allergies = allergiesFromProp;
  }

  const users = useSelector(state => state.lookups.users);
  const allergyStatuses = useSelector(state => state.lookups.allergyLookups.statuses);

  const [statusFilter, setStatusFilter] = useState([PATIENT_ALLERGY_STATUS_ACTIVE]);

  const allergiesToPass = getAllergiesOrderedBySeverity(allergies, statusFilter);

  const opts = {
    showAllergyStatus: true,
    showVerificationPanel: true,
    showTopActionsBar: true,
    showAllergiesList: true,
    showAddAllergyForm: false,
    renderingOrder: [RENDERING_SECTIONS.ADD_ALLERGY_FORM, RENDERING_SECTIONS.ALLERGIES_LIST],
    ...options,
  };

  const showNoKnownAllergiesCheckbox =
    patient &&
    (!patient.allergies_verification_status_id ||
      !patient.allergies_verification_status_id === ALLERGIES_NOT_VERIFIED ||
      allergiesToPass.length === 0 ||
      allergiesToPass.some(
        allergy =>
          allergy.status_id === PATIENT_ALLERGY_STATUS_ACTIVE &&
          NO_ALLERGY_INFORMATION_AVAILABLE_ID,
      ));

  const [noKnownAllergiesCheckbox, setNoKnownAllergiesCheckbox] = useState(
    patient && !showNoKnownAllergiesCheckbox,
  );
  const [displayAddAllergy, setDisplayAddAllergy] = useState(false);

  const dispatch = useDispatch();

  const handleVerify = () => {
    const payload = {
      patient_id: patient.id,
      verification_status_id: ALLERGIES_VERIFIED,
      verified_dt: convertToArborDate(getTodayDateTime()).getUtcDatetime(),
    };

    if (patient.allergies_verification_status_id !== ALLERGIES_VERIFIED) {
      resolveAuditDefinition({
        auditDefinitionType: ENSURE_ALLERGIES_VERIFIED,
        auditState,
        patient,
        resolverFunction: resolvedAudit => {
          dispatch(resolveAudit(resolvedAudit));
        },
      });
    }

    dispatch(verifyAllergies(payload));
  };

  const handleMarkEmrConfirmed = () => {
    if (patient) {
      dispatch(markEmrConfirmed(patient.id, true));
    }
  };

  const handleMarkPatientConfirmedAllergies = () => {
    if (patient) {
      dispatch(markPatientConfirmedAllergies(patient.id, true)).then(() => {
        handleVerify();
      });
    }
  };

  const handleNoKnownAllergies = () => {
    setNoKnownAllergiesCheckbox(!noKnownAllergiesCheckbox);
  };

  const verifiedUser = patient
    ? getUserById(patient.allergies_verification_verified_by, users)
    : null;

  const cancelFromNoKnownAllergies = () => {
    setDisplayAddAllergy(false);
    setNoKnownAllergiesCheckbox(!noKnownAllergiesCheckbox);
  };

  const handleAllergyStatusChange = e => {
    if (e && e.length > 0) {
      setStatusFilter(e.map(o => o.value));
    } else {
      setStatusFilter(null);
    }
  };

  const getVerifyDateForDisplay = () => {
    if (patient) {
      if (patient.allergies_verification_verified_dt) {
        return patient.allergies_verification_verified_dt;
      }
      if (patient.allergies_confirmed_with_patient_date) {
        return patient.allergies_confirmed_with_patient_date;
      }
    }
    return null;
  };

  const renderAllergiesList = () => (
    <>
      {allergies && allergies.length > 0 ? (
        <Grid item xs={12}>
          <Paper elevation={2} className={classes.allergyContainer}>
            <PatientAllergiesTable allergies={allergiesToPass} readOnly={readOnly} />
            {!readOnly && (
              <QuaternaryVerificationPanel
                type="allergy"
                verificationId={
                  patient && patient.allergies_verification_status_id
                    ? patient.allergies_verification_status_id
                    : ALLERGIES_NOT_VERIFIED
                }
                handleVerify={handleVerify}
                handleMarkEmrConfirmed={handleMarkEmrConfirmed}
                handleMarkPatientConfirmed={handleMarkPatientConfirmedAllergies}
                emrConfirmed={patient && !!patient.allergies_confirmed_with_emr}
                verifiedBy={verifiedUser}
                verifiedDate={patient.allergies_verification_verified_dt}
                confirmedWithPatientDate={getVerifyDateForDisplay()}
                auditRules={[ENSURE_ALLERGIES_VERIFIED]}
                user={currentUser}
                disabled={!patient}
              />
            )}
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Paper elevation={2} className={classes.allergyContainer}>
            <Grid container alignItems="center">
              {patient && showNoKnownAllergiesCheckbox && (
                <Checkbox
                  checked={noKnownAllergiesCheckbox}
                  color="primary"
                  onChange={handleNoKnownAllergies}
                  value="noKnownAllergies"
                  id="patient_no_know_allergies"
                  data-qa-id="no-known-allergies-checkbox"
                />
              )}
              <Typography variant="h6" component="span">
                No known Allergies
              </Typography>
              {noKnownAllergiesCheckbox && (
                <Grid item xs={12}>
                  <PatientAllergiesForm
                    cancelHandler={cancelFromNoKnownAllergies}
                    noAllergiesForm
                    verificationId
                    allergies={allergies}
                    submitCallback={postSave}
                    data-qa-id="no-known-allergies-form"
                  />
                </Grid>
              )}
            </Grid>
            {opts.showVerificationPanel && (
              <QuaternaryVerificationPanel
                type="allergy"
                verificationId={
                  patient && patient.allergies_verification_status_id
                    ? patient.allergies_verification_status_id
                    : ALLERGIES_NOT_VERIFIED
                }
                handleVerify={handleVerify}
                handleMarkEmrConfirmed={handleMarkEmrConfirmed}
                emrConfirmed={patient && !!patient.allergies_confirmed_with_emr}
                verifiedBy={verifiedUser}
                verifiedDate={patient ? patient.allergies_verification_verified_dt : null}
                confirmedWithPatientDate={getVerifyDateForDisplay()}
                auditRules={[ENSURE_ALLERGIES_VERIFIED]}
                user={currentUser}
                disabled
              />
            )}
          </Paper>
        </Grid>
      )}
    </>
  );

  const renderAddAllergyForm = () => (
    <Paper elevation={2} className={classes.addAllergyFormContainer}>
      <Grid container>
        <Grid item xs={12} className={classes.addElementTitleWrapper}>
          <Typography className={classes.addElementTitle}>Add New Allergy</Typography>
        </Grid>
        <Grid item xs={12} className={classes.smallPaddedContainer}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <PatientAllergiesForm
            submitCallback={postSave}
            cancelHandler={() => (cancelAddForm ? cancelAddForm() : setDisplayAddAllergy(false))}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  const renderInOrder = () => (
    <>
      {opts.renderingOrder.map(section => {
        switch (section) {
          case RENDERING_SECTIONS.ADD_ALLERGY_FORM: {
            return (displayAddAllergy || opts.showAddAllergyForm) && renderAddAllergyForm();
          }
          case RENDERING_SECTIONS.ALLERGIES_LIST: {
            return opts.showAllergiesList && renderAllergiesList();
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );

  const headerClassname = classNames(classes.allergyHeader, {
    [classes.allergyHeaderStretched]: stretched,
  });
  return (
    <Grid
      container
      alignItems="center"
      className={minimal || stretched ? null : classes.clinicalContainer}
    >
      {opts.showTopActionsBar && (
        <>
          <Grid item xs={2}>
            {!minimal && (
              <Typography variant="h6" component="span" className={headerClassname}>
                Allergies
              </Typography>
            )}
          </Grid>
          <Grid item xs={10}>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.allergyHeader}
              alignItems="center"
            >
              <Grid item xs={5} />
              <Grid item xs={4}>
                {opts.showAllergyStatus && (
                  <ReactSelect
                    name="allergyStatus"
                    label="Allergy Status"
                    handleOnChange={e => handleAllergyStatusChange(e)}
                    defaultValues={[{ value: PATIENT_ALLERGY_STATUS_ACTIVE, label: 'Active' }]}
                    className={classes.statusFilter}
                    fields={
                      allergyStatuses
                        ? allergyStatuses.map(item => ({
                            value: item.id,
                            label: item.value,
                          }))
                        : []
                    }
                  />
                )}
              </Grid>
              {!readOnly && (
                <Grid item xs={3}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      className={classes.rowButton}
                      disableElevation
                      data-qa-id="allergy_reverify_button"
                      onClick={handleVerify}
                    >
                      <Grid container alignItems="center">
                        <>
                          <CompleteIcon />
                        </>
                        <Typography className={classes.buttonIconSpacing} variant="button">
                          Reverify
                        </Typography>
                      </Grid>
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.rowButton}
                      disableElevation
                      onClick={() => setDisplayAddAllergy(true)}
                      disabled={displayAddAllergy}
                    >
                      <Typography variant="button">+ Add</Typography>
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {renderInOrder()}
    </Grid>
  );
}

export default compose(withStyles(styles, { withTheme: true }))(AllergiesList);
