import { evaluateCondition } from './helpers-condition';

export const getFormLabel = (providers, field) => {
  if (!field.label) {
    return undefined;
  }
  const req =
    !field.readOnly &&
    evaluateCondition(providers.config, providers, field.required, providers.formValues);
  return `${field.label}${field.label && req ? ' *' : ''}${
    field.label && field.addColon ? ':' : ''
  }`;
};

// eslint-disable-next-line no-confusing-arrow
export const getFieldDisabled = (providers, field) => {
  const taskFieldTransitions =
    // eslint-disable-next-line camelcase
    providers?.auth?.currentUser?.permissions?.tasks_field[
      providers?.task?.taskType.toLowerCase()
    ] || {};
  // eslint-disable-next-line camelcase
  const fieldPermissions = taskFieldTransitions?.field_transitions || null;
  if (
    fieldPermissions &&
    fieldPermissions[field.property] &&
    !fieldPermissions[field.property].some(p => p.canEdit)
  ) {
    return true;
  }
  if (field.disabled) {
    return evaluateCondition(providers.config, providers, field.disabled, providers.formValues);
  }
  return false;
};

export const generateQAID = (field, providers) => {
  const { qaId } = field;
  if (providers && providers.task && qaId) {
    return `${qaId}_${providers.task.taskType}_${
      providers && providers.task ? providers.task.id : null
    }`;
  }
  if (qaId) {
    return qaId;
  }
  return field.property;
};
