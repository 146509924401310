import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Field } from 'redux-form';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';
import { renderDropdown, renderRadioGroup } from 'components/form/field/redux-field';
import { secureLinkAutomation, secureLinkAutomationStatus } from 'constants/lists';
import { TherapyAdministrationStatuses } from 'constants/therapy-administration-statuses';
import { TherapyTypes, DispensingStatusValue } from 'constants/enums';
import { windowFeatureIsEnabled } from 'config/window-features';
import { required } from 'components/form/validation/validation';
import { styles } from './patient-demographics-styles';

type Props = {
  formId: string;
  classes: any;
  formValues: any;
};

const AUTO_SECURE_LINK_FEATURE = 'auto_secure_link';

const SecureLinkAutomationField: React.FC<any> = (props: Props): JSX.Element => {
  const { classes, formValues } = props;

  const therapies = useSelector((state: any) => state?.therapies?.data);

  const anchorTherapyOptions = Object.values(therapies || {})
    .filter((therapy: any) => {
      if (
        therapy.clinic_administered ||
        therapy.dispensing_status_id !== DispensingStatusValue.OptIn
      ) {
        // cannot be clinically administered or any dispensing status
        // that is not Opt-in
        return false;
      }
      if (
        ![
          TherapyAdministrationStatuses.PreTherapy,
          TherapyAdministrationStatuses.OnTherapy,
        ].includes(therapy.administration_status_id)
      ) {
        // must be on-therapy or pre-therapy
        return false;
      }
      if (
        ![
          TherapyTypes.NonSpecialty,
          TherapyTypes.Specialty,
          TherapyTypes.ChronicDiseaseManagement,
          TherapyTypes.SpecialtyLite,
        ].includes(therapy.therapy_type)
      ) {
        // must be Ancillary, SP, CDM, or L
        return false;
      }
      if (therapy.therapy_type === TherapyTypes.NonSpecialty && therapy.as_needed) {
        // Ancillary cannot be PRN
        return false;
      }
      return true;
    })
    .map((therapy: any) => ({
      value: therapy.id,
      label: `${therapy.drug_name} ${therapy.strength} ${therapy.strength_unit_of_measure} ${therapy.dosage_form_description}`,
    }));

  const autoSecureLinkMap = secureLinkAutomation.map(item => ({
    ...item,
    disabled:
      Number(item.value) === secureLinkAutomationStatus.AUTOMATION &&
      !windowFeatureIsEnabled(AUTO_SECURE_LINK_FEATURE),
  }));

  return (
    <>
      <Typography style={{ fontWeight: 500, fontSize: '0.8215rem' }}>
        Fill Coordination Secure Link Automation
      </Typography>
      <Grid container alignItems="center" style={{ paddingLeft: '20px' }}>
        <Grid item xs={5}>
          <Field
            name="auto_secure_link"
            radioMap={autoSecureLinkMap}
            component={renderRadioGroup}
            className={classes.input}
            validate={[required]}
            width="100%"
            caption
          />
        </Grid>
        {Number(formValues.auto_secure_link) === secureLinkAutomationStatus.AUTOMATION ? (
          <Grid item xs={5} className={classes.dropdownContainer}>
            <Field
              id="asl_anchor_therapy_id"
              name="asl_anchor_therapy_id"
              label="Anchor Therapy"
              validate={[required]}
              component={renderDropdown}
              fields={anchorTherapyOptions}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default withStyles(styles)(SecureLinkAutomationField);
