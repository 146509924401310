import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Grid, Typography, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  excludeScheduleDrugOutcome,
  editScheduleOutreach,
  fetchTaskCounts,
} from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDropdown, renderTextField } from 'components/form/field/redux-field';
import { SmallSpacer } from 'components/spacer/spacer';
import { required } from 'components/form/validation/validation';
import { EXCLUDE_THERAPY_FORM, EXCLUDE_AR_THERAPY } from 'constants/index';
import { excludedReasons } from 'constants/lists';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { workListChanged } from 'actions/action-patient';
import { areAllDrugsExcluded } from 'services/utils/task-service';
import { styles } from './schedule-outreach-details-styles';

const ScheduleOutreachTherapyExcluding = props => {
  const {
    classes,
    selectedDrugs,
    scheduleOutreachId,
    drugs,
    editScheduleOutreach, // eslint-disable-line
    workListChanged, // eslint-disable-line
    fetchTaskCounts, // eslint-disable-line
    excludeScheduleDrugOutcome, // eslint-disable-line
    setTaskStatus,
    handleClose,
    handleSubmit,
    pristine,
    submitting,
    reasonFormValues,
  } = props;

  const getExcludedReasonFromFormValues = (formValues, drugId) => {
    const reason = formValues[`${drugId}_reason`];
    if (reason === 'Other') {
      const otherReason = formValues[`${drugId}_other_reason`];
      return `${reason}: ${otherReason}`;
    }
    return reason;
  };

  const onSubmit = values => {
    // If all drugs are excluded, set status to 'No Valid Therapies
    const selectedDrugIds = selectedDrugs.map(d => d.id);
    const notSelectedDrugs = drugs.filter(d => !selectedDrugIds.includes(d.id));
    const allDrugsExcluded = areAllDrugsExcluded(notSelectedDrugs);
    if (allDrugsExcluded) {
      setTaskStatus('No Valid Therapies'); // set status to ScheduleOutreachDetails local state
      editScheduleOutreach({ id: scheduleOutreachId, status_id: 10016 }).then(() => {
        workListChanged();
        fetchTaskCounts();
      });
    }

    // Construct the payload from the form values
    const payload = {
      drugs: selectedDrugs.map(selectedDrug => {
        const drugId = selectedDrug.id;
        const excludedReason = getExcludedReasonFromFormValues(values, drugId);
        return {
          id: drugId,
          excluded_reason: excludedReason,
          exclude_id: EXCLUDE_AR_THERAPY,
        };
      }),
    };

    return excludeScheduleDrugOutcome(payload).then(() => {
      handleClose();
    });
  };

  const renderDrug = drug => {
    const drugId = drug.id;
    const reasonField = `${drugId}_reason`;
    const otherReasonField = `${drugId}_other_reason`;
    return (
      <Grid container>
        <Grid item>
          <SmallSpacer />
          <Typography
            variant="subtitle1"
            id={`${drugId}_drug_name`}
            className={classes.excludedDrugLabel}
          >
            {`${drug.drug_name} ${drug.strength || ''}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name={reasonField}
            label="Reason"
            width="100%"
            validate={[required]}
            component={renderDropdown}
            fields={excludedReasons}
            id={reasonField}
          />
        </Grid>
        {reasonFormValues && reasonFormValues[reasonField] === 'Other' && (
          <Field
            name={otherReasonField}
            label="Other Reason"
            width="100%"
            validate={[required]}
            component={renderTextField}
            id={otherReasonField}
          />
        )}
      </Grid>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.excludedForm} autoComplete="off">
      <Grid item>
        <Typography variant="h6" id="decline_header">
          Decline Therapies
        </Typography>
        <Divider />
      </Grid>
      {selectedDrugs.map(selectedDrug => renderDrug(selectedDrug))}
      <Grid item xs={12}>
        <ConfirmationPanel
          handleCancel={handleClose}
          disableSubmit={pristine || submitting}
          buttonIdPrefix="submit_exclude_drug"
        />
      </Grid>
    </form>
  );
};

const mapStateToProps = state => {
  const { patient } = state;
  const reasonFormValues = getFormValues(EXCLUDE_THERAPY_FORM)(state);
  return {
    patientId: patient.id,
    reasonFormValues,
    form: EXCLUDE_THERAPY_FORM,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    excludeScheduleDrugOutcome,
    editScheduleOutreach,
    fetchTaskCounts,
    workListChanged,
  }),
)(reduxForm({})(ScheduleOutreachTherapyExcluding));
