import { takeLatest, call, put, select, takeEvery, debounce, all, fork } from 'redux-saga/effects';
import { addNoteNoApi, addNote, fetchNewNotes } from 'actions/action-notes';
import {
  fetchRelatedTasks,
  fetchSpecificQueueTaskCountSuccess,
  setSelectedTasks,
} from 'actions/action-tasks';
import { change } from 'redux-form';
import xor from 'lodash/xor';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import difference from 'lodash/difference';
import { fetchClinicalData } from 'actions/action-clinical-data';
import { ClinicalDataClient } from 'clients/clinical-data';
import { getTaskKey } from 'containers/patient/tasks-new/tasks-table/utils';
import { MED_SYNC_UPDATE_SUCCESS } from 'containers/patient/med-sync/med-sync-actions';
import { updateFCDispensingPharmacyInRedux } from 'actions/action-tasks';
import { updateTherapiesDispensingPharmacyInRedux } from 'actions/action-therapies';
import { calculateNextNeedsByDate } from 'containers/patient/med-sync/med-sync-utils';
import { isAxiosError } from 'axios';
import { logger } from 'winston-logger';
import {
  DATA_TASKS_REQUEST,
  DATA_TASKS_SUCCESS,
  DATA_TASKS_FAILURE,
  DATA_TASK_COUNTS_FETCH,
  DATA_TASK_COUNTS_REQUEST,
  DATA_TASK_COUNTS_SUCCESS,
  DATA_TASK_COUNTS_FAILURE,
  WORK_LIST_CHANGED,
  SIDEBAR_FILTERS_CLEAR_IDS,
  SIDEBAR_FILTERS_CLEAR,
  SIDEBAR_FILTERS_SET,
  FETCH_USER_PREFERENCES,
  ADD_TASK_FILTER_SERVICE_GROUP,
  DELETE_TASK_FILTER_SERVICE_GROUP,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  EDIT_THERAPY,
  MARK_PATIENT_DECEASED,
  SELECTED_PATIENT_ID,
  FETCH_NEW_NOTES,
  REFRESH_THERAPIES_AND_TASKS,
  TASK_STATUS_OUTSTANDING,
  TASK_STATUS_IN_PROGRESS,
  TOGGLE_PIN_WORKFLOW,
  TOGGLE_PIN,
  FETCH_NOTES_SIDEBAR,
  FETCH_NOTES_SIDEBAR_FAILURE,
  ADD_NOTE_COMMUNICATION,
  SEND_SMS_TO_PATIENT_SUCCESS,
  CANCEL_PA_TASK,
  LINK_FINANCIAL_ASSISTANCE,
  SET_SELECTED_TASK_KEYS,
  SET_SHOW_SET_SELECTED_TASKS,
  BULK_TASKS_UPDATED,
  BULK_TASKS_UPDATE_REQUEST,
  INTEGRATE_UPDATED_TASKS,
  FETCH_TASKS,
  DUR_CREATE_NEW_SUCCESS,
  FA_GRANT_NOTIFICATION,
  TASK_LIFECYCLE_ACTIONS,
  SET_DRAFT_TASK,
  REQUEST_DRAFT_TASK,
  SELECT_TASK_KEY,
  TOGGLE_SELECTED_TASK_ROW_KEY,
  CLEAR_SELECTED_TASK_KEY,
  CSL,
  DC,
  FETCH_RELATED_TASK_CLINICAL_DATA,
  DUR,
  UPDATE_ELIGIBILITY_CHECK,
  UPDATE_PATIENT_INSURANCES,
  ADD_APPOINTMENT_REFERRAL,
  REENROLL_PATIENT,
} from '../constants';
import HTTP from '../services/http';
import { getQueryFilters } from '../services/utils/filters-service';
import { fetchPaymentMethodsState } from '../slices/patient-payment-methods-slice';
import { notifyError, notifySuccess, notifyWarning } from '../actions/action-notifications';
import { DraftFillCoordinationClient } from '../clients/draft-fill-coordination-client';
import {
  getIsTaskKeySelected,
  selectDraftTasksState,
  selectSelectedTasks,
} from '../containers/patient/tasks-new/selectors';
import { autoUpsertPatientPbmInsurance } from '../actions/action-eligibility-check';
import { fetchMedicalInsurances, fetchPbmInsurances } from '../actions/action-financials';

const CACHE_LIFETIME = 20000;

class TaskSearchKeeper {
  constructor() {
    this.taskResultsPerUrl = {};
    this.previousUrl = null;
    this.callsCount = 0;
  }

  getTasks = async (url, makeRequestFunction, cancelRequestFunction) => {
    const valueInCache = this.taskResultsPerUrl[url];

    if (valueInCache) {
      const expiredCache =
        new Date().getTime() - valueInCache.calledTime.getTime() > CACHE_LIFETIME;

      if (valueInCache.results && !expiredCache) {
        return valueInCache.results;
      }
    }

    this.taskResultsPerUrl[url] = {
      results: null,
      calledTime: new Date(), // check if needed or not
      cancelRequest: cancelRequestFunction,
    };

    if (this.previousUrl && this.taskResultsPerUrl[this.previousUrl] && this.callsCount >= 2) {
      // If there is an ongoing API call (for a different URL or the same, it does not matter)
      // then cancel that previous API call and only make the most recent one
      // We keep track of the calls count, since we always need to wait for the first 2 API calls
      // to have the init data
      this.taskResultsPerUrl[this.previousUrl].cancelRequest();
    }

    // Set the previous URL as the current one for the next search to be done
    this.previousUrl = url;
    this.callsCount += 1;
    const taskResults = await makeRequestFunction(url);
    this.previousUrl = null;

    this.taskResultsPerUrl[url].results = taskResults;
    this.taskResultsPerUrl[url].calledTime = new Date();
    return taskResults;
  };
}

const taskSearchKeeper = new TaskSearchKeeper();

const fetchTaskCounts = async (sidebarFilters, sidebarTaskType, forceTaskType) => {
  const httpAbortController = new AbortController();
  const requestConfig = { signal: httpAbortController.signal };

  const queryParameterString = getQueryFilters(sidebarFilters, sidebarTaskType, {
    addEncodedTaskTypeStatusIds: true,
  });
  // Given the same set of query params, check if they changed from last time API was called
  let url = `/tasks/count?${queryParameterString}`;

  if (forceTaskType) {
    url += `&only_task_type=${forceTaskType}`;
  }

  // Plus, is there is a recent API call made for the same query params, then reuse prev call
  const makeRequestFunction = async _url =>
    HTTP.get(_url, requestConfig).then(payload => ({
      ...payload,
      data: payload.data.tasks,
    }));
  const cancelRequestFunction = () => httpAbortController.abort();

  return taskSearchKeeper.getTasks(url, makeRequestFunction, cancelRequestFunction);
};

function* workerFetchTasks(actionBase) {
  const { selectedPatientId } = yield select();

  if (
    actionBase &&
    actionBase.payload &&
    actionBase.payload.data &&
    actionBase.payload.data.post_ar_therapy_add
  ) {
    return;
  }
  if (selectedPatientId) {
    // eslint-disable-next-line max-len
    const url = `/patients/${selectedPatientId}/tasks?categories=${TASK_STATUS_OUTSTANDING},${TASK_STATUS_IN_PROGRESS}&include_today=1&last_reviewed_dur=1`;
    const request = HTTP.get(url);
    yield put({
      type: DATA_TASKS_REQUEST,
      payload: request,
      meta: { patientId: selectedPatientId },
    });

    try {
      const response = yield call(() => request);
      yield put({ type: DATA_TASKS_SUCCESS, payload: response.data.tasks });
    } catch (e) {
      yield put({ type: DATA_TASKS_FAILURE, payload: e });
    }
  }
}

function* workerTaskCounts(action) {
  const state = yield select();
  if (!state.userPreferences.loaded) {
    return;
  }
  const { sidebarFilters, selectedPatientId, sidebarTaskType } = state;

  const sidebarFilterData = sidebarFilters && sidebarFilters.data ? sidebarFilters.data.task : null;
  yield put({ type: DATA_TASK_COUNTS_REQUEST });
  try {
    const response = yield call(
      fetchTaskCounts,
      sidebarFilterData,
      sidebarTaskType,
      // Force to fetch only this queue, regardless of the current active one
      action.payload?.forceTaskType,
      action.payload?.skipTimeoutReset,
    );
    const { data } = response;

    if (action.payload?.forceTaskType) {
      // update only specific queue count not all of them
      yield put(fetchSpecificQueueTaskCountSuccess(action.payload.forceTaskType, response.data));
    } else {
      // update all tasks counts
      yield put({
        type: DATA_TASK_COUNTS_SUCCESS,
        payload: data,
        meta: { patientId: selectedPatientId },
      });
    }
  } catch (error) {
    yield put({ type: DATA_TASK_COUNTS_FAILURE });
  }
}

function* workerFetchNewNotesSaga(action) {
  const { payload } = action;
  const newNotes = payload && payload.data && payload.data.notes ? payload.data.notes : [];
  if (newNotes && newNotes.length > 0) {
    yield put(addNoteNoApi(newNotes));
  }
}

function* workerSetNewSMSNotes(action) {
  const { payload } = action;
  if (payload.noteId) {
    yield put(fetchNewNotes({ note_id: payload.noteId }));
  }
}

function* workerFetchNotesOnPin(action) {
  const state = yield select();
  const existingTasks = Object.values(state?.tasks?.data || {});
  const { payload } = action;
  yield put({ type: TOGGLE_PIN, payload });
  try {
    // eslint-disable-next-line
    const toggledNote = payload?.data?.toggled_note;
    const toggledNoteTags = toggledNote.tags;
    const pinnedAtAllLevels =
      toggledNote.changed_is_all_of_type &&
      toggledNoteTags.some(tag => tag.is_pinned === 1) &&
      toggledNoteTags.some(tag => tag.is_all_of_type === 1);
    const unpinnedAtAllLevels =
      toggledNote.changed_is_all_of_type && toggledNoteTags.some(tag => tag.is_pinned === 0);
    if (pinnedAtAllLevels || unpinnedAtAllLevels) {
      yield put({
        type: FETCH_NOTES_SIDEBAR,
        payload: {
          data: {
            notes: state?.notes?.allNotes || [],
          },
        },
        meta: { patientId: state.selectedPatientId, tasks: existingTasks },
      });
    }
  } catch {
    yield put({ type: FETCH_NOTES_SIDEBAR_FAILURE });
  }
}
function* workerAddCommunicationSaga(action) {
  const { payload } = action;
  const { note, formId, followupDt } = payload;
  try {
    yield put(addNote(note));
    if (followupDt) {
      if (formId) {
        yield put(change(formId, 'followup_dt', followupDt));
      }
    }
  } catch (error) {
    yield put({ type: `${action.type}_ERROR` });
  }
}

/**
 * When tasks are selected and then shown, fetch any payment methods
 * that are needed for displaying any selected FC tasks.
 */
function* workerFetchPaymentMethods(action) {
  const { payload } = action;
  const state = yield select();
  const {
    selectedPatientId,
    tasks: { showSelectedTasks, data: tasksData },
    paymentMethods,
  } = state;
  const isShown = action.type === SET_SHOW_SET_SELECTED_TASKS ? !!payload : !!showSelectedTasks;
  if (!isShown || !selectedPatientId || paymentMethods.loading) {
    return;
  }
  const nextTasks =
    action.type === SET_SELECTED_TASK_KEYS
      ? payload.map(taskKey => tasksData[taskKey])
      : selectSelectedTasks(state);
  if (!nextTasks.length) {
    return;
  }
  const paymentMethodIdsNeeded = uniq(
    nextTasks.flatMap(task =>
      task.taskType === 'FC' && Array.isArray(task.payment_method_ids)
        ? task.payment_method_ids
        : [],
    ),
  );
  const existingPaymentMethodIds = Object.keys(paymentMethods.entities).map(Number);
  const paymentIdsToFetch = difference(paymentMethodIdsNeeded, existingPaymentMethodIds);
  if (paymentIdsToFetch.length) {
    yield put(
      fetchPaymentMethodsState({
        patientId: selectedPatientId,
        paymentMethodIds: paymentIdsToFetch,
      }),
    );
  }
}

function* workerHandleSetSelectedTasks({ type, payload }) {
  const selectedTaskKeys = yield select(state => state.tasks.selectedTaskKeys);
  switch (type) {
    case TOGGLE_SELECTED_TASK_ROW_KEY:
      return yield put(setSelectedTasks(xor(selectedTaskKeys, [].concat(payload)).sort()));
    case CLEAR_SELECTED_TASK_KEY:
      return yield put(setSelectedTasks(without(selectedTaskKeys, ...[].concat(payload)).sort()));
    case SELECT_TASK_KEY:
      return yield put(setSelectedTasks(uniq(selectedTaskKeys.concat(payload)).sort()));
    default:
      break;
  }
  return null;
}

function* workerFetchFCSelfService({ payload }) {
  const { taskIds } = payload;
  // Get FC SS Updated
  const request = HTTP.post('/tasks/fc/fc_ss', { task_ids: taskIds });
  const response = yield call(() => request);

  if (response.status === 200) {
    yield put({
      type: INTEGRATE_UPDATED_TASKS,
      payload: { tasks: response?.data?.tasks },
    });
  }
}

/**
 * Wait for user to stop interacting with tasks before requesting
 * additional payment methods data
 */
function* debounceWorkerFetchPaymentMethods() {
  yield debounce(600, [SET_SELECTED_TASK_KEYS], workerFetchPaymentMethods);
}

function* workerShowUIMessagesForTaskUpdates({ payload }) {
  if (payload.data?.messages?.length) {
    // eslint-disable-next-line no-restricted-syntax
    yield all(
      payload.data.messages.map(message => {
        if (message.type === 'error') {
          return put(notifyError(message.text));
        }
        if (message.type === 'warn') {
          return put(notifyWarning(message.text));
        }
        return put(notifySuccess(message.text));
      }),
    );
  }
}

// ARBOR-11486 - clear these after task is saved or closed
function* workerFetchDraftTasks({ payload }) {
  const draftTaskState = yield select(selectDraftTasksState);
  // Only used for fill-coordination tasks right now.
  const taskKey = `FC${payload.taskId}`;
  if (draftTaskState[taskKey]) {
    // Don't request it again
    return;
  }
  const response = yield call(() => DraftFillCoordinationClient.fetch(payload.taskId));
  if (response.data) {
    yield put({
      type: SET_DRAFT_TASK,
      payload: {
        taskKey,
        data: response.data,
      },
    });
  }
}

function* workerFetchRelatedTaskClinicalData({ payload: task }) {
  const patientId = yield select(state => state.selectedPatientId);
  const isTaskSelected = yield select(state => getIsTaskKeySelected(state, getTaskKey(task)));
  if (isTaskSelected) {
    // eslint-disable-next-line func-names
    yield fork(function* () {
      if (task.taskType === CSL && patientId) {
        yield put(fetchRelatedTasks(task.id, CSL));
        const res = yield call(() => ClinicalDataClient.fetch(patientId));
        yield put(fetchClinicalData(res.data));
      }
      if (task.taskType === DC && patientId) {
        const res = yield call(() => ClinicalDataClient.fetch(patientId));
        yield put(fetchClinicalData(res.data));
      }
    });
  }
}

function* workerUpsertPatientPbmInsuranceFromEligibilityCheck() {
  const patientId = yield select(state => state.selectedPatientId);
  if (patientId) {
    const res = yield call(() => autoUpsertPatientPbmInsurance(patientId));
    yield put(res);
  }
}

const FILL_COORDINATION_TRANSITION = 'FILL_COORDINATION_TRANSITION';
function* workerSetTasksAfterMedSyncUpdate(action) {
  // Update the FC forms after med sync is updated
  const fcForms = yield select(state => state.form[FILL_COORDINATION_TRANSITION]);
  if (fcForms) {
    const { medSyncData } = action.payload;
    // Just look at the form data to see which tasks are relevant
    const formTaskIdsToUpdate = Object.keys(fcForms.values.days_supply);
    // eslint-disable-next-line no-restricted-syntax
    for (const taskId of formTaskIdsToUpdate) {
      // Lookup therapy_id by taskId
      const therapyId = yield select(state => state.tasks.data[`FC${taskId}`?.therapy_id]);
      if (therapyId) {
        // Lookup the new medSyncData by the task's therapy_id
        const medSyncItem = medSyncData.find(medSync => medSync.id === therapyId);
        // If any exists, change the form values
        if (medSyncItem) {
          const { daysSupply, needsbyDate } = medSyncItem;
          yield put(change(FILL_COORDINATION_TRANSITION, `days_supply.${taskId}`, daysSupply));
          yield put(change(FILL_COORDINATION_TRANSITION, `needsby_date.${taskId}`, needsbyDate));
          const nextNeedsByDate = calculateNextNeedsByDate(daysSupply, needsbyDate);
          yield put(
            change(FILL_COORDINATION_TRANSITION, `next_needsby_date.${taskId}`, nextNeedsByDate),
          );
        }
      }
    }
  }
}

/**
 * workerUpdateTasksBundleRequest()
 * Handles updated tasks response (bundle) after firing editTasks() action (BULK_TASKS_UPDATE_REQUEST)
 */
function* workerUpdateTasksBundleRequest({ payload }) {
  if (payload.data?.updated_tasks) {
    if (payload.data?.updated_tasks.some(task => task.taskType === DUR)) {
      // If DUR is being updated, need to parse out interaction interventions and add to payload
      const interventions =
        payload.data.updated_tasks
          .find(x => x.taskType === DUR)
          ?.interactions?.filter?.(int => int.intervention_id !== null)
          .map(interaction => interaction.intervention) ?? [];
      if (interventions.length) {
        // Create top-level intervention tasks from DUR interactions data
        payload.data.updated_tasks = [...interventions, payload.data.updated_tasks];
      }
    }
    yield put({ type: BULK_TASKS_UPDATED, payload });
    yield put(notifySuccess('Tasks updated successfully.'));
    if (!payload.data?.updatedDispPharmTherapies) {
      return payload;
    }
  }
  if (payload.data?.updatedDispPharmTasks && payload.data?.updatedDispPharmacy) {
    const updatedFCPayload = {
      fcIds: payload.data.updatedDispPharmTasks
        .filter(task => task.taskType === 'FC')
        .map(task => task.id),
      pharmacy: payload.data?.updatedDispPharmacy,
    };
    yield put(updateFCDispensingPharmacyInRedux(updatedFCPayload));
  }

  if (payload.data?.updatedDispPharmTherapies && payload.data?.updatedDispPharmacy) {
    const updatedTherapiesPayload = {
      therapyIds: payload.data.updatedDispPharmTherapies.map(therapy => therapy.id),
      pharmacy: payload.data.updatedDispPharmacy,
    };
    yield put(updateTherapiesDispensingPharmacyInRedux(updatedTherapiesPayload));
    yield put(notifySuccess('Preferred Dispensing Pharmacy Updated Successfully.'));
    return payload;
  }
  logger.error('Update FC task error', payload);
  if (isAxiosError(payload) && payload.response?.data?.message) {
    yield put(notifyError(payload.response?.data?.message));
  } else {
    yield put(notifyError(payload.message ?? 'Unable to edit tasks'));
  }
  return payload;
}

export function* watcherTasksSaga() {
  const actions = [
    WORK_LIST_CHANGED,
    SIDEBAR_FILTERS_CLEAR_IDS,
    SIDEBAR_FILTERS_CLEAR,
    SIDEBAR_FILTERS_SET,
    FETCH_USER_PREFERENCES,
    ADD_TASK_FILTER_SERVICE_GROUP,
    DELETE_TASK_FILTER_SERVICE_GROUP,
    ADD_THERAPY,
    ADD_THERAPY_AR,
    EDIT_THERAPY,
    MARK_PATIENT_DECEASED,
    REFRESH_THERAPIES_AND_TASKS,
    DUR_CREATE_NEW_SUCCESS,
    FA_GRANT_NOTIFICATION,
  ];
  yield takeEvery([...actions, DATA_TASK_COUNTS_FETCH], workerTaskCounts);
  yield takeLatest(
    [
      BULK_TASKS_UPDATED,
      MARK_PATIENT_DECEASED,
      SELECTED_PATIENT_ID,
      REFRESH_THERAPIES_AND_TASKS,
      EDIT_THERAPY,
      CANCEL_PA_TASK,
      DUR_CREATE_NEW_SUCCESS,
      FA_GRANT_NOTIFICATION,
      REENROLL_PATIENT,
    ],
    workerFetchTasks,
  );
  yield takeLatest(FETCH_NEW_NOTES, workerFetchNewNotesSaga);
  yield takeLatest(TOGGLE_PIN_WORKFLOW, workerFetchNotesOnPin);
  yield takeLatest(ADD_NOTE_COMMUNICATION, workerAddCommunicationSaga);
  yield takeEvery(SEND_SMS_TO_PATIENT_SUCCESS, workerSetNewSMSNotes);
  yield takeLatest(
    [TOGGLE_SELECTED_TASK_ROW_KEY, CLEAR_SELECTED_TASK_KEY, SELECT_TASK_KEY],
    workerHandleSetSelectedTasks,
  );
  yield takeLatest(LINK_FINANCIAL_ASSISTANCE, workerFetchTasks);
  yield takeLatest([SET_SELECTED_TASK_KEYS], debounceWorkerFetchPaymentMethods);
  yield takeLatest([SET_SHOW_SET_SELECTED_TASKS], workerFetchPaymentMethods);
  yield takeLatest([FETCH_TASKS], workerFetchFCSelfService);
  yield takeLatest(
    [BULK_TASKS_UPDATED, TASK_LIFECYCLE_ACTIONS.EDIT.FILL_COORDINATION],
    workerShowUIMessagesForTaskUpdates,
  );
  yield takeEvery(REQUEST_DRAFT_TASK, workerFetchDraftTasks);
  yield takeEvery(FETCH_RELATED_TASK_CLINICAL_DATA, workerFetchRelatedTaskClinicalData);
  yield takeLatest(MED_SYNC_UPDATE_SUCCESS, workerSetTasksAfterMedSyncUpdate);
  yield takeLatest(BULK_TASKS_UPDATE_REQUEST, workerUpdateTasksBundleRequest);
  yield takeLatest(ADD_APPOINTMENT_REFERRAL, workerUpsertPatientPbmInsuranceFromEligibilityCheck);
}
