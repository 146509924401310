import {
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
} from 'constants/index';
import { TaskId, TherapyId } from 'interfaces/RecordTypes';
import { IContactList } from 'interfaces/redux/IContactList';
import { IUser } from 'interfaces/redux/ILookups';
import { IPatient } from 'interfaces/redux/IPatient';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import { WithStyles } from '@mui/styles';
import { IAdditionalMedication } from 'interfaces/fill-coordination/bundle-types';
import { IDispensingPharmacy } from 'interfaces/redux/IDispensingPharmacy';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { IExistingIntervention } from 'models/tasks/DraftFillCoordinationDetails';
import { IAddress } from 'components/document/generator/interfaces/fc-types';
import { styles } from './fill-coordination.styles';

/**
 * Any of the fields that are "Record<TaskId, T>" are
 * the questions that are duplicated for each task
 */
export interface IFillCoordinationEditFormFields {
  additional_medications: IAdditionalMedication[];
  address: { value: IAddress | number; label: string };
  ancillary_supplies: string;
  apply_choice_to_therapies_and_FCs?: boolean;
  canceled_status_id: number;
  changes_in_medication: boolean;
  cold_chain_packing_required: boolean;
  copay_amount: Record<TaskId, number | undefined>;
  courier_type?: number | undefined;
  days_supply: Record<TaskId, number>;
  delivery_dt: moment.Moment | undefined;
  dispense_qty: Record<TaskId, number>;
  dispensing_pharmacy: IDispensingPharmacy | null;
  email: { value: string; label: string } | undefined;
  followup_dt: moment.Moment | undefined;
  hospital_visit: boolean;
  in_progress_reason: string;
  is_notice_privacy_practices_sent: boolean;
  location: any;
  medicaid_only: boolean;
  method: number;
  needsby_date: Record<TaskId, moment.Moment>;
  new_allergies: boolean;
  new_infections: boolean;
  new_medical_conditions: boolean;
  next_needsby_date: Record<TaskId, moment.Moment>;
  notes: string;
  on_hand_qty: Record<TaskId, number>;
  order_notes: string;
  order_type_address: string;
  patient_acknowledges: boolean;
  patient_advised_pickup: boolean;
  patient_missed_doses: boolean;
  patient_questions: boolean;
  payment_method_on_file: boolean;
  preferred_phone_number: { value: string; label: string } | undefined | null;
  preferred_rx_delivery_method?: { value: number; label: string } | null;
  rx_number: Record<TaskId, string | undefined>;
  ship_date: moment.Moment | undefined;
  shipping_method: string;
  side_effects: boolean;
  signature_required: boolean | number;
  special_instructions: string;
  spoke_to_patient_dt: moment.Moment | undefined;
  status_id: number;
  to_md: boolean;
  to_patient: boolean;
  ups_packaging_type?: ILabelValueNumber;
  urgent: boolean;
  weather: any;
  welcome_kit_sent: boolean;
  wk_pp_received: boolean;
  one_time_fill?: Record<TaskId, boolean>;
  therapy_ids_to_remove?: number[];
  fc_ids_in_bundle?: number[];
  drop_ship_med: Record<TaskId, boolean | undefined>;

  // legacy fields - removed from the new form
  // TODO: remove these fields when fc_order_form_generate_doc feature flag is removed
  additional_reason: string | undefined;
  completed_dt: moment.Moment | undefined;
  copay_note: string | undefined;
  last_checked_dt: moment.Moment | undefined;
  order_last_received_dt: moment.Moment | undefined;
  service_group_id: Record<TaskId, number>;

  // Document upload fields
  [UPLOAD_DOC_FILENAME_FIELD]: string;
  [UPLOAD_DOC_FILETYPE_FIELD]: string;
  [UPLOAD_DOC_FILENOTE_FIELD]: string;

  payment_method_ids?: number[];
  authorization_code?: Record<TaskId, string>;
}

export enum SideEffectType {
  Common = 1,
  MissedMedicationDose = 2,
}

// @TODO - this types are also used in CSL , find a place to put them in order to share it between both modules
export type CausedByTypes = 'therapy' | 'med' | 'unknown';

export interface IShortIntervention {
  status_id: number;
  reaction: string | null;
  suspected_caused_by: { id?: number; type: CausedByTypes; text: string } | null;
  category_id: number | null;
  type_id: number | null;
  details: string | null;
  sideeffect_type: SideEffectType;
}

export interface IInterventionPayload {
  status_id: number;
  type_id: number;
  category_id: number;
  details: string;
  therapy_id: number | null | undefined;
  medication_ids: number[] | null;
}

export enum EditComponentMode {
  ChangeStatus = 'changeStatus',
  Edit = 'edit',
}

export interface IProps extends WithStyles<typeof styles> {
  areMedicationChanges: boolean;
  changeAncillarySupplies?(value: string): void;
  changeNewInterventionsHandler?(newInterventions: IShortIntervention[]): void;
  componentMode: EditComponentMode;
  contactList: IContactList;
  deliveryMethodVal: any;
  existingInterventions: IExistingIntervention[];
  form: string;
  formName: string;
  groupedFCIds: number[];
  haveNewAllergies: boolean;
  haveNewInfections?: boolean;
  haveNewSideEffects: boolean;
  hospitalVisitDisableNo: boolean;
  methodIsChartReview: any;
  newInterventions?: IShortIntervention[];
  onSelectTaskIds: (
    newSelectedTasks: { newSelectedTaskId: number; newSelectedTaskType: string }[],
  ) => void;
  otcProvider: any;
  patient: IPatient;
  patientMissedDoses: boolean;
  paymentMethodOnFileSelected?: boolean;
  patientQuestionsDisableNo: boolean;
  setShippingAddressDialogSettings: (value: any) => void;
  setShippingAddressIsNotPatientPreferred: (value: boolean) => void;
  selectedAncillarySupplies: string;
  selectedStatus: number;
  siblingTasks: { task: IFillCoordinationTask; therapy: ITherapy }[];
  statusReason: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FDCStatusReason?: string;
  statuses: any;
  tasks: IFillCoordinationTask[];
  therapies?: Record<TherapyId, ITherapy>;
  totalCopayAmount: string | null;
  userData: IUser[];
  upsPackagingTypeSelected?: any;
  handleExpectedDeliveryDateChange?: (deliveryDate: Date) => void;
  showTherapiesNdcsWarning?: boolean;
  allTasks: IFillCoordinationTask[];
}
