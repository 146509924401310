/* eslint-disable max-len */
import uuid from 'uuid/v4';
import React, { Component, Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  Button,
  Typography,
  Box,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import compose from 'recompose/compose';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { SmallSearch, EditAdd, EditDelete } from 'components/icons/icons';
import { Info as InfoIcon } from '@mui/icons-material';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { addNote } from 'actions/action-notes';
import {
  renderDropdown,
  renderCheckbox,
  renderNumberField,
  renderCheckboxHorizontal,
  renderReactRadioGroup,
  renderTextField,
} from 'components/form/field/redux-field';
import { parseNoteToDb } from 'services/utils/note-service';
import FieldLabel from 'components/form/field/field-label';
import FormHeader from 'components/form/header/header';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import Divider from 'components/divider';
import OverrideFieldSection from 'components/form/field/override-field-section';
import {
  ADD_PATIENT_THERAPY_FORM,
  THERAPY_ADMIN_STATUS_PRE_THERAPY,
  THERAPY_ADMIN_STATUS_ON_THERAPY,
  THERAPY_ENROLLMENT_STATUS_REFERRAL_ID,
  PRESCRIBING_CLINIC_METHOD,
  DISPENSING_STATUSES,
  DISPENSING_UNDECIDED_REASONS,
  THERAPY_ENROLLMENT_OPTOUT,
  DOSIS_REGIMEN_CHARACTER_LIMIT,
  THERAPY_LEAD_SOURCE_CLINICIAN_REFERRAL,
  AR_PROVIDER_REFERRAL,
  MED_SYNC_STATUS,
  CDM,
  RS,
  OPT_IN,
  OPT_OUT,
} from 'constants/index';
import { TherapyWorkflowLabel, MedicationReferralStatus } from 'constants/enums';
import {
  emrStatusDropdown,
  noteTagTypes,
  ExternalDispensingPharmacyReasons,
} from 'constants/lists';
import { addTherapy, addTherapyLinkDrug } from 'actions/action-therapies';
import { fetchTaskCounts, editTasks } from 'actions/action-tasks';
import {
  validateDate,
  required,
  therapyNBDWithinDaysSupply,
} from 'components/form/validation/validation';
import { getTodayDateTime } from 'services/utils/date-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { parseFullName } from 'parse-full-name';
import {
  fetchDiagnosisCodes,
  fetchPhysicians,
  fetchGpis,
  fetchGpisNoDebounce,
  fetchClinics,
  fetchPharmacies,
  getTherapyStatusId,
  handlePrescribingClinic,
  getTherapyStatuses,
  statusArrayForEdit,
  specialtyTypeFormatter,
} from 'services/utils/therapy-service';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { getInitialServiceGroupForReactSelect } from 'services/utils/task-service';
import { addMedication, updateMedication } from 'actions/action-medications';
import {
  updateLocalUserPreferencesRecentClinics,
  updateLocalUserPreferencesRecentPrescribers,
  updateLocalUserPreferencesRecentPharmacies,
} from 'actions/action-user';
import boolean from 'boolean';
import EligibilityCheck from 'containers/patient/financials/eligibility/eligibility-check';
import { setMedicationExcludedValue } from 'services/utils/reconciliation-service';
import RenderTherapyLeadSource from 'components/radio-button-group/render-therapy-lead-source';
import RenderTherapyRxType from 'components/radio-button-group/render-therapy-rx-type';
import RenderTherapyPrescribingClinic from 'components/radio-button-group/render-therapy-prescribing-clinic';

import { buildQaId } from 'utils/build-qa-id';
import renderDaysSelector from 'components/dynamic-form/renders/render-days-selector';
import { dischargePatient, getChecklistData } from 'actions/action-patient';
import { isEmpty } from 'lodash';
import { MedSynchronized } from 'containers/patient/med-sync/components/medSyncIcon';
import { windowFeatureIsEnabled } from 'config/window-features';
import createMemoReduxForm from 'utils/create-memo-redux-form';
import { styles } from './add-patient-therapy-styles';
import NpiLookup from '../npi-lookup';
import EditTherapyEnrollment from '../edit-therapy-enrollment-simple';
import { isDosingRegimenRequired } from '../util';
import { PatientTherapiesClient } from '../../../../clients/patient-therapies';
import { RunBiFromAR } from '../../../tasks/fill-coordination/bi-sections/run-bi-from-ar';

const qaIdBuilder = buildQaId('dosing-regimen', '.');

class AddPatientTherapy extends Component {
  constructor(props) {
    super(props);
    const { selectedDrug } = props;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDosisRegimenChange = this.handleDosisRegimenChange.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveColumnClinic = this.handleSaveColumnClinic.bind(this);
    this.handleSaveColumnPhysician = this.handleSaveColumnPhysician.bind(this);
    this.handleSaveColumnPharmacy = this.handleSaveColumnPharmacy.bind(this);
    this.handleSaveColumnTransferPharmacy = this.handleSaveColumnTransferPharmacy.bind(this);
    this.renderDosisRegimenTextField = this.renderDosisRegimenTextField.bind(this);
    this.handlePharmacyNpiChange = this.handlePharmacyNpiChange.bind(this);
    this.handleTransferPharmacyNpiChange = this.handleTransferPharmacyNpiChange.bind(this);
    this.handleFetchGpisOptions = this.handleFetchGpisOptions.bind(this);
    this.icdVerification = this.icdVerification.bind(this);
    this.icdVerificationDialogBox = this.icdVerificationDialogBox.bind(this);

    this.state = {
      isSpecialty: selectedDrug ? boolean(selectedDrug.is_specialty_drug) : true,
      requireDosingRegimen: selectedDrug
        ? isDosingRegimenRequired(selectedDrug.specialty_type)
        : true,
      npiLookupClinic: false,
      npiLookup: false,
      transferNpiLookup: false,
      serviceGroupDropdown: -1,
      managingClinicDropdown: -1,
      tertiaryDiagnosisAdded: false,
      dosisRegimenValue: '',
      showIcdIndicator: false,
      submittingForm: false,
      dialogOpen: false,
      stateSubmitHandler: null,
      event: null,
      rxTypeId: props.initialValues.rx_type_id,
      prescriber: isEmpty(props.selectedPhysician) ? null : props.selectedPhysician,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { selectedDrug, initialTherapyValues, change, onValueChange, managingClinicNameAndId } =
      this.props;
    if (selectedDrug?.ndc) {
      const initialTherapy = fetchGpisNoDebounce(selectedDrug.ndc, true, true);
      initialTherapy.then(result => {
        let requireDosingRegimen = true;
        if (result && result.data && result.data.drugs && result.data.drugs.length) {
          const therapy = result.data.drugs[0];
          change('therapy', {
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
          });
          requireDosingRegimen = isDosingRegimenRequired(therapy.specialty_type);
        }
        this.setState({
          isSpecialty: boolean(selectedDrug.is_specialty_drug),
          requireDosingRegimen,
        });
      });
    } else {
      this.setState({
        isSpecialty: true,
        requireDosingRegimen: true,
      });
    }
    if (initialTherapyValues) {
      this.handlePhysicianMatch(initialTherapyValues.clinicianName);
    }
    if (managingClinicNameAndId.label !== undefined) {
      onValueChange(selectedDrug, 'managing_clinic_name_and_id', managingClinicNameAndId);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedDiagnosis: prevSelectedDiagnosis, selectedTherapy: prevSelectedTherapy } =
      prevProps;
    const { selectedDiagnosis, selectedTherapy, physician } = this.props;
    this.icdVerification(
      selectedDiagnosis,
      selectedTherapy,
      prevSelectedDiagnosis,
      prevSelectedTherapy,
    );
  }

  icdVerification(selectedDiagnosis, selectedTherapy, prevSelectedDiagnosis, prevSelectedTherapy) {
    if (
      Object.keys(selectedDiagnosis).length &&
      Object.keys(selectedTherapy).length &&
      selectedTherapy.specialty_type.includes(CDM)
    ) {
      if (
        selectedDiagnosis.code &&
        selectedTherapy.specialty_type &&
        (Object.keys(prevSelectedDiagnosis).length || Object.keys(prevSelectedTherapy).length)
      ) {
        PatientTherapiesClient.getTherapyWorkflow(
          selectedDiagnosis.code,
          selectedTherapy.specialty_type,
        ).then(res => {
          const workflowLabel = TherapyWorkflowLabel[res.data?.therapyWorkflow] ?? '';
          if (workflowLabel !== RS) {
            if (!this.state.submittingForm) this.resetIcdIndicator(true);
          } else {
            this.resetIcdIndicator(false);
          }
        });
      }
    } else if (this.state.showIcdIndicator) {
      this.resetIcdIndicator(false);
    }
  }

  resetIcdIndicator(flag) {
    if (this.state.showIcdIndicator !== flag) {
      this.setState({ showIcdIndicator: flag });
    }
  }

  handleSubmit(values) {
    const {
      addTherapy, // eslint-disable-line
      addTherapyLinkDrug, // eslint-disable-line
      addMedication, // eslint-disable-line
      fetchTaskCounts, // eslint-disable-line
      getChecklistData, // eslint-disable-line
      updateMedication, // eslint-disable-line
      failedTherapyForm, // When this prop exist
      addNote, // eslint-disable-line
      reset,
      patientId,
      handleCloseAddForm,
      therapyStatuses,
      selectedTherapy,
      selectedDiagnosis,
      selectedSecondaryDiagnosis,
      selectedTertiaryDiagnosis,
      selectedDrug,
      stagingMedication,
      excludedValues,
      arDrug,
      enrollmentStatuses,
      submitCallback,
    } = this.props;
    let { isSpecialty } = this.state;

    const { dosisRegimenValue } = this.state;
    const { therapyAdministrationStatuses } = therapyStatuses;
    const dispensingStatusId = getTherapyStatusId(enrollmentStatuses, values.dispensing_status);

    if (arDrug) {
      isSpecialty = true;
    }

    if (stagingMedication) {
      stagingMedication.start_dt = convertToArborDate(stagingMedication.start_dt).getUtcDate();
      stagingMedication.end_dt = convertToArborDate(stagingMedication.end_dt).getUtcDate();
    }

    const medicationPayload = {
      patient_id: patientId,
      ndc: selectedTherapy.ndc,
      is_documenting_only: values.is_documenting_only,
      start_dt: convertToArborDate(values.start_date).getUtcDate(),
      end_dt: convertToArborDate(values.end_date).getUtcDate(),
      source_medication_id:
        selectedDrug && selectedDrug.source_medication_id
          ? selectedDrug.source_medication_id
          : uuid(),
      category_code: values.source,
      active: 1,
      source_id: selectedDrug && selectedDrug.source_id ? selectedDrug.source_id : 1,
      status_code: values.emr_status,
    };
    const adminStatusId = getTherapyStatusId(
      therapyAdministrationStatuses,
      isSpecialty ? THERAPY_ADMIN_STATUS_PRE_THERAPY : values.administration_status,
    );

    if (values.is_documenting_only) {
      // upsert medication if therapy is documenting only or during reconciliation
      if (selectedDrug && selectedDrug.id) {
        updateMedication({
          id: selectedDrug.id,
          ...medicationPayload,
        });
      } else {
        addMedication({
          ...stagingMedication,
          ...medicationPayload,
        });
        const comparedMedication = {
          ...stagingMedication,
          ...medicationPayload,
        };
        if (excludedValues) {
          setMedicationExcludedValue(comparedMedication, stagingMedication, excludedValues);
        }
      }
    } else {
      const prescribingClinic = handlePrescribingClinic(
        values.prescribing_method_id,
        values.managing_clinic_name_and_id,
        values.referring_clinic,
        isSpecialty,
      );

      const managingClinicIdValue = values.managing_clinic_name_and_id
        ? values.managing_clinic_name_and_id.value
        : null;

      const managingClinicNameValue = values.managing_clinic_name_and_id
        ? values.managing_clinic_name_and_id.label
        : null;

      const payload = {
        patient_id: patientId,
        ndc: selectedTherapy.ndc,
        lead_source_id: values.lead_source_id,
        rx_type_id: values.rx_type_id,
        referring_clinic_npi: prescribingClinic ? prescribingClinic.npi : null,
        referring_clinic_name: prescribingClinic ? prescribingClinic.name : null,
        referring_clinic_internal_id: prescribingClinic ? prescribingClinic.id : null,
        managing_clinic_id: managingClinicIdValue,
        managing_clinic_name: managingClinicNameValue,
        dispensing_status_id: dispensingStatusId,
        enrollment_status_id: THERAPY_ENROLLMENT_STATUS_REFERRAL_ID,
        // eslint-disable-next-line
        administration_status_id:
          values.rx_type_id === MedicationReferralStatus.Existing.toString()
            ? getTherapyStatusId(therapyAdministrationStatuses, THERAPY_ADMIN_STATUS_ON_THERAPY)
            : adminStatusId,
        referral_dt: isSpecialty ? convertToArborDate(values.referral_dt).getUtcDatetime() : null,
        needsby_date: convertToArborDate(values.needsby_date).getUtcDate(),
        days_supply: values.days_supply,
        is_needsby_per_protocol: values.is_needsby_per_protocol,
        service_group_id: values.service_group_name_and_id.value,
        diagnosis_code: selectedDiagnosis.code,
        secondary_diagnosis_code: selectedSecondaryDiagnosis.code,
        tertiary_diagnosis_code: selectedTertiaryDiagnosis.code,
        prescribing_physician_npi: values.physician ? values.physician.npi : null,
        prescribing_physician_name: values.physician ? values.physician.name : null,
        prescribing_physician_credential: values.physician ? values.physician.credential : null,
        is_specialty: Boolean(isSpecialty),
        dispensing_pharmacy_npi: values.dispensing_pharmacy ? values.dispensing_pharmacy.npi : null,
        dispensing_pharmacy_name: values.dispensing_pharmacy
          ? values.dispensing_pharmacy.name
          : null,
        transfer_pharmacy_npi: values.transfer_pharmacy ? values.transfer_pharmacy.npi : null,
        transfer_pharmacy_name: values.transfer_pharmacy ? values.transfer_pharmacy.name : null,
        therapy_duration: values.therapy_duration,
        active: 1,
        source_id: 1,
        category_code: isSpecialty ? 'outpatient' : values.source,
        external_dispensing_additional_reason: values.external_dispensing_additional_reason,
        dosis_regimen: dosisRegimenValue || null,
        as_needed: values.as_needed ?? false,
        clinic_administered: values.clinic_administered ?? false,
        drop_ship_med: values.drop_ship_med ?? false,
        start_date:
          values.rx_type_id === MedicationReferralStatus.Existing.toString()
            ? convertToArborDate(values.medication_status_start_dt).getUtcDate()
            : null,
      };
      if (arDrug) {
        this.setState({ submittingAR: true });
        // If therapy is added from AR, we need to insert the medication_id
        if (selectedDrug.staging_medication_id) {
          payload.staging_medication = selectedDrug;
        } else {
          payload.medication_id = selectedDrug.medication_id;
        }
        payload.external_dispensing_reason = values.external_dispensing_reason;
        payload.dispensing_pharmacy_doing_business_as = values.dispensing_pharmacy
          ? values.dispensing_pharmacy.doing_business_as
          : null;
        payload.transfer_pharmacy_doing_business_as = values.transfer_pharmacy
          ? values.transfer_pharmacy.doing_business_as
          : null;
        const clinicalSupportStatusId = getTherapyStatusId(
          enrollmentStatuses,
          values.clinical_support_status,
          [OPT_IN, OPT_OUT].includes(values.clinical_support_status)
            ? null
            : values.clinical_support_undecided_reason,
        );
        payload.clinical_support_status_id = clinicalSupportStatusId;
        payload.clinical_support_status_reason =
          clinicalSupportStatusId === THERAPY_ENROLLMENT_OPTOUT
            ? (Array.isArray(values.clinical_support_status_reason) &&
                values.clinical_support_status_reason.join(',')) ||
              values.clinical_support_status_reason
            : null;
        payload.clinical_support_additional_reason = values.clinical_support_additional_reason;
        addTherapyLinkDrug({
          newTherapy: payload,
          drug: { ...selectedDrug, ndc: selectedDrug.ndc || selectedTherapy.ndc },
          onSuccess: () => {
            this.setState({ submittingAR: false });
            handleCloseAddForm();
            getChecklistData(patientId);
          },
          onFail: e => {
            this.setState({ submittingAR: false });
          },
        });
      } else {
        payload.medication_id = selectedDrug ? selectedDrug.id : null;
        // If we add a therapy upon staging medication, we need to use it as a template
        payload.staging_medication = stagingMedication;
        addTherapy(payload).then(addedTherapy => {
          const newTherapy =
            addedTherapy &&
            addedTherapy.payload &&
            addedTherapy.payload.data &&
            addedTherapy.payload.data.therapy
              ? addedTherapy.payload.data.therapy
              : null;
          fetchTaskCounts();
          getChecklistData(patientId);
          if (values.needsby_dt_override_reason) {
            const { value: tagTypeId } = noteTagTypes.find(tag => tag.label === 'Therapy');
            const newValues = parseNoteToDb({ note_text: values.needsby_dt_override_reason });
            const tags = [{ tag_type_id: tagTypeId, resource_id: newTherapy.id }];

            const payloadNotes = {
              patient_id: patientId,
              note_type_id: 1,
              note_text: newValues.note_text,
              is_pinned: 0,
              mentions: newValues.mentions,
              tags,
            };
            addNote(payloadNotes);
          }
        });
      }
    }

    if (failedTherapyForm) {
      handleCloseAddForm(selectedTherapy.ndc);
    } else if (!arDrug) {
      handleCloseAddForm();
    }

    if (!selectedDrug) {
      reset();
    }
    if (submitCallback) {
      submitCallback(excludedValues);
    }
    this.setState({
      showIcdIndicator: false,
      submittingForm: false,
      dialogOpen: false,
      stateSubmitHandler: null,
      event: null,
    });
  }

  handleDosisRegimenChange(event) {
    this.setState({ dosisRegimenValue: event.target.value });
  }

  handleCancelClick() {
    const { reset, handleCloseAddForm, selectedDrug } = this.props;
    handleCloseAddForm();
    if (!selectedDrug) {
      reset();
    }
  }

  handleSaveColumnClinic(clinic) {
    const { updateLocalUserPreferencesRecentClinics, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'referring_clinic', clinic);
    }
    updateLocalUserPreferencesRecentClinics(clinic);
  }

  handleSaveColumnPhysician(physician) {
    const { updateLocalUserPreferencesRecentPrescribers, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'physician', physician);
      this.setState({ prescriber: physician });
    }
    updateLocalUserPreferencesRecentPrescribers(physician);
  }

  handleSaveColumnPharmacy(pharmacy) {
    const { updateLocalUserPreferencesRecentPharmacies, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'dispensing_pharmacy', pharmacy);
    }
    updateLocalUserPreferencesRecentPharmacies(pharmacy);
  }

  handleSaveColumnTransferPharmacy(pharmacy) {
    const { updateLocalUserPreferencesRecentPharmacies, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'transfer_pharmacy', pharmacy);
    }
    updateLocalUserPreferencesRecentPharmacies(pharmacy);
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchPhysiciansOptions(searchText) {
    // eslint-disable-next-line
    return fetchPhysicians(searchText).then(result =>
      result.data
        ? result.data.physicians.map(icd10 => ({
            ...icd10,
            id: icd10.npi,
            label: icd10.name,
            description: icd10.address,
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchDiagnosisOptions(searchText) {
    // eslint-disable-next-line
    return fetchDiagnosisCodes(searchText).then(result =>
      result.data
        ? result.data.icd10s.map(icd10 => ({
            ...icd10,
            id: icd10.code,
            label: icd10.description,
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchGpisOptions(searchText) {
    const { arDrug, ancillaryDrug } = this.props;

    // eslint-disable-next-line no-confusing-arrow
    return fetchGpis(searchText).then(result =>
      result.data
        ? result.data.drugs.map(therapy => ({
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
            ...(arDrug ? { disabled: !therapy.specialty_type } : {}),
            ...(ancillaryDrug ? { disabled: therapy.specialty_type } : {}),
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchClinicOptions(searchText) {
    // eslint-disable-next-line no-confusing-arrow
    return fetchClinics(searchText, false, null).then(result =>
      result.data
        ? result.data.clinics.map(clinic => ({
            ...clinic,
            id: clinic.npi,
            label: clinic.name,
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchPharmacyOptions(searchText) {
    // eslint-disable-next-line no-confusing-arrow
    return fetchPharmacies(searchText).then(result =>
      result.data
        ? result.data.pharmacies.map(pharmacy => ({
            ...pharmacy,
            label: pharmacy.name,
            description: pharmacy.address,
          }))
        : [],
    );
  }

  handleClinicNpiChange(clinic) {
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    change('referring_clinic', {
      ...clinic,
      id: clinic.npi,
      label: clinic.name,
    });
    this.handleSaveColumnClinic(clinic);
  }

  handlePharmacyNpiChange(pharmacy) {
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    change('dispensing_pharmacy', {
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    });
    this.handleSaveColumnPharmacy(pharmacy);
  }

  handleTransferPharmacyNpiChange(pharmacy) {
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    change('transfer_pharmacy', {
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    });
    this.handleSaveColumnTransferPharmacy(pharmacy);
  }

  handlePhysicianMatch(clinician) {
    if (!clinician) {
      return;
    }
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    const { first, last } = parseFullName(clinician.trim());
    if (first && last) {
      fetchPhysicians(`${first} ${last}`, true).then(result => {
        if (result.data.physicians && result.data.physicians.length === 1) {
          const matchedPhysician = result.data.physicians[0];
          change('physician', {
            ...matchedPhysician,
            id: matchedPhysician.npi,
            label: matchedPhysician.name,
            description: matchedPhysician.address,
          });
        }
      });
    }
  }

  renderDosisRegimenTextField() {
    const { classes } = this.props;
    const { dosisRegimenValue, requireDosingRegimen } = this.state;
    return (
      <>
        <Typography className={classes.subtitle}>
          Dosing Regimen {requireDosingRegimen ? '*' : ''}
        </Typography>
        <Field
          component={renderTextField}
          validate={requireDosingRegimen ? [required] : []}
          variant="standard"
          name="dosis_regimen"
          fullWidth
          placeholder="Text about the dosing regimen for this therapy..."
          inputProps={{
            maxLength: DOSIS_REGIMEN_CHARACTER_LIMIT,
          }}
          value={dosisRegimenValue}
          onChange={this.handleDosisRegimenChange}
          helperText={`${dosisRegimenValue.length}/${DOSIS_REGIMEN_CHARACTER_LIMIT}`}
          margin="normal"
          data-qa-id="ar_therapy_form_dosis_regimen"
        />
      </>
    );
  }

  icdVerificationDialogBox(diagnosisCode, diagnosisDescription) {
    const { classes } = this.props;
    const message = `You are pairing this Therapy with a NON RS ICD Code (${diagnosisCode} ${diagnosisDescription})`;
    const question = 'Do you want to continue?';
    return (
      <Dialog open={this.state.dialogOpen} maxWidth="md" data-qa-id="icd_verification_dialogbox">
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.icdValidatorText}>{message}</Typography>
            <Typography className={classes.icdValidatorText}>{question}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => {
              this.setState({
                dialogOpen: false,
                submittingForm: false,
              });
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({
                showIcdIndicator: false,
                submittingForm: false,
              });
              this.state.stateSubmitHandler(this.state.event);
            }}
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleManagingClinicChange = data => {
    const { change, onValueChange, selectedDrug, managingClinics, serviceGroups } = this.props;
    if (onValueChange) onValueChange(selectedDrug, 'managing_clinic_name_and_id', data);

    const clinic = managingClinics?.find(item => item.id === data.value);
    if (!clinic) return;

    const group = serviceGroups?.find(item => item.id === clinic.service_group_id);
    if (!group) return;

    const selectedGroup = {
      ...group,
      value: group.id,
      label: group.display_name,
    };

    if (onValueChange) onValueChange(selectedDrug, 'service_group_name_and_id', selectedGroup);
    change('service_group_name_and_id', selectedGroup);
  };

  render() {
    const {
      arTask,
      handleSubmit,
      submitting,
      classes,
      serviceGroups,
      managingClinics,
      managingClinicNameAndId,
      selectedReferringClinic,
      prescribingMethodId,
      enrollmentStatuses,
      selectedTherapy,
      selectedDiagnosis,
      selectedSecondaryDiagnosis,
      selectedTertiaryDiagnosis,
      selectedPhysician,
      selectedDispensingPharmacy,
      selectedTransferPharmacy,
      selectedPharmacyName,
      selectedIsDocumentingOnly,
      selectedDispensingStatus,
      selectedClinicalStatus,
      selectedDispensingReason,
      selectedClinicalReason,
      recentPharmacies,
      therapyStatuses,
      displayDocumentOnlyOption,
      forceDocumentOnlyOption,
      arDrug,
      medsEnrollment,
      failedTherapyForm,
      formTitle = null,
      bordered = true,
      showEligibilityCheck = true,
      selectedNeedsByDate,
      selectedDaysSupply,
      medicationCategoryCodes,
      onValueChange,
      selectedDrug,
      recentProviders,
      disableTherapyInput,
      medSync,
    } = this.props;

    const { isSpecialty } = this.state;
    const { tertiaryDiagnosisAdded } = this.state;

    const {
      serviceGroupDropdown,
      managingClinicDropdown,
      npiLookupClinic,
      transferNpiLookup,
      npiLookup,
      clinicalSupportStatusId,
    } = this.state;

    const { gpiLookupConfig } = this.props;

    const therapyClinicalDispensingStatuses = statusArrayForEdit(
      getTherapyStatuses(enrollmentStatuses),
    );

    const renderFormHeader = () => {
      if (failedTherapyForm) {
        return <FormHeader header="Add failed Therapy" />;
      }
      if (selectedTherapy.ndc) {
        return (
          <FormHeader
            header={isSpecialty ? 'New Therapy - Specialty' : 'New Therapy - Non Specialty'}
          />
        );
      }
      if (formTitle) {
        return <FormHeader header={formTitle} />;
      }
      return <FormHeader header="New Therapy" />;
    };

    const showDialogBox = () => {
      return this.icdVerificationDialogBox(selectedDiagnosis.code, selectedDiagnosis.description);
    };

    const optIn = selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN;
    const optOut = selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT;

    const { therapyAdministrationStatuses } = therapyStatuses;
    const statusArray = getTherapyStatuses(therapyAdministrationStatuses);
    const statusDropdown = statusArrayForEdit(statusArray);
    const { patientId, back } = this.props;

    const handleNbdInDaysSupply = () =>
      therapyNBDWithinDaysSupply(selectedNeedsByDate, selectedDaysSupply) === true;
    const defaultClinicValue = {
      value: managingClinicNameAndId?.id,
      label: managingClinicNameAndId?.name,
      npi: managingClinicNameAndId?.npi,
    };

    const managingClinicField = (
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={3}>
          <Field
            name="managing_clinic_name_and_id"
            label={`Managing Clinic ${!optOut ? '*' : ''}`}
            validate={!optOut ? [required] : []}
            component={ReactSelectForReduxSingle}
            onChange={this.handleManagingClinicChange}
            state={managingClinicDropdown}
            fields={managingClinics.map(int => ({
              label: int.name,
              value: int.id,
              npi: int.npi,
            }))}
            defaultValue={defaultClinicValue}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteLabel
            idValue={managingClinicNameAndId ? managingClinicNameAndId.npi : null}
            defaultIdValue=""
            label="Managing Clinic NPI"
            useLargeLabel
          />
        </Grid>
      </Grid>
    );

    return (
      <Grid
        container
        justifyContent="center"
        spacing={1}
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          {!!npiLookupClinic && (
            <NpiLookup
              open
              title="Prescribing Clinic"
              values={{
                taxonomy: '',
                organization: selectedReferringClinic ? selectedReferringClinic.name : '',
              }}
              handleChange={item => {
                this.handleClinicNpiChange(item);
              }}
              handleClose={() => {
                this.setState({ npiLookupClinic: false });
              }}
            />
          )}
          {!!npiLookup && (
            <NpiLookup
              open
              title="Pharmacy"
              values={{
                taxonomy: 'pharmacy',
                organization: selectedPharmacyName || '',
              }}
              handleChange={item => {
                this.handlePharmacyNpiChange(item);
              }}
              handleClose={() => {
                this.setState({ npiLookup: false });
              }}
            />
          )}
          {!!transferNpiLookup && (
            <NpiLookup
              open
              title="Transfer From Pharmacy"
              values={{
                taxonomy: 'pharmacy',
                organization: selectedPharmacyName || '',
              }}
              handleChange={item => {
                this.handleTransferPharmacyNpiChange(item);
              }}
              handleClose={() => {
                this.setState({ transferNpiLookup: false });
              }}
            />
          )}
          {this.state.showIcdIndicator && this.state.submittingForm ? showDialogBox() : null}
          <form
            onSubmit={e => {
              const submitHandler = handleSubmit(this.handleSubmit);
              if (!this.state.showIcdIndicator) {
                submitHandler(e);
                e.stopPropagation();
                e.preventDefault();
                return false;
              }
              this.setState({
                submittingForm: true,
                dialogOpen: true,
                stateSubmitHandler: submitHandler,
                event: e,
              });
              e.preventDefault();
            }}
            className={bordered ? classes.addFormContainer : classes.noBorderFormContainer}
            autoComplete="off"
            data-qa-id="ar_therapies_form"
          >
            {/* First Row */}
            <Grid container>
              <Grid item xs={9}>
                {renderFormHeader()}
              </Grid>
              {(displayDocumentOnlyOption || forceDocumentOnlyOption) && (
                <Grid item xs={3}>
                  <Field
                    name="is_documenting_only"
                    label="Documenting only"
                    disabled={forceDocumentOnlyOption || !!medsEnrollment}
                    component={renderCheckboxHorizontal}
                    styleOverride={{
                      justifyContent: 'flex-end',
                    }}
                    style={{
                      padding: '7.5px',
                      transform: 'translateY(-5px)',
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {/* Second Row */}
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6}>
                <Field
                  name="therapy"
                  disabled={disableTherapyInput}
                  label="Therapy *"
                  component={AutocompleteMinigrid}
                  validate={[required]}
                  fetchOptions={this.handleFetchGpisOptions}
                  hint="Search by Therapy or GPI or NDC"
                  columnsToShow={{
                    gpi: 'GPI',
                    drug_name: 'Drug Name',
                    dosage_form_description: 'Form',
                    dose: 'Dose',
                    ldd: 'LDD',
                    specialty_type: 'Specialty Type',
                    ndc: 'NDC',
                  }}
                  minSearchLength={2}
                  qaId="therapy_name"
                  valueFormatter={specialtyTypeFormatter}
                  gpiLookupConfig={gpiLookupConfig}
                  onChange={drug => {
                    if (drug) {
                      this.setState({
                        isSpecialty: boolean(drug.is_specialty_drug),
                        requireDosingRegimen: isDosingRegimenRequired(drug.specialty_type),
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <AutocompleteLabel idValue={selectedTherapy.ndc} defaultIdValue="" label="NDC" />
              </Grid>
              {selectedIsDocumentingOnly && (
                <Grid item xs={3}>
                  <Field
                    name="emr_status"
                    label="Administration *"
                    component={renderDropdown}
                    className={classes.input}
                    validate={[required]}
                    fields={emrStatusDropdown}
                    id="add_therapy_emr_status"
                  />
                </Grid>
              )}
              {(isSpecialty || medsEnrollment) && !selectedIsDocumentingOnly && (
                <Grid item xs={3}>
                  <Field
                    name="referral_dt"
                    label="Referral Date *"
                    component={renderDatePicker}
                    onChange={value =>
                      onValueChange && onValueChange(selectedDrug, 'referral_dt', value)
                    }
                    validate={[required, validateDate]}
                  />
                </Grid>
              )}

              {!optOut && (
                <Grid container item alignItems="flex-start">
                  <Grid item xs={12} onBlur={this.handleTherapyBlur}>
                    {this.renderDosisRegimenTextField()}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* Second Row */}
            {selectedIsDocumentingOnly && (
              <>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={3}>
                    <Field
                      name="start_date"
                      label="Start Date"
                      component={renderDatePicker}
                      validate={[validateDate]}
                      id="add_therapy_start_date"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="end_date"
                      label="End Date"
                      component={renderDatePicker}
                      validate={[validateDate]}
                      id="add_therapy_end_date"
                    />
                  </Grid>
                </Grid>
                <Divider />
              </>
            )}
            {/* Third Row */}
            {!selectedIsDocumentingOnly && (
              <Grid>
                <Grid
                  direction="column"
                  container
                  spacing={2}
                  alignItems="flex-start"
                  className={classes.radioButtonContainer}
                >
                  <Grid container item xs={12} spacing={3}>
                    {(isSpecialty || medsEnrollment) && (
                      <Grid item xs={3}>
                        <FieldLabel fieldName="Medication Status *" />
                        <Field
                          name="rx_type_id"
                          component={RenderTherapyRxType}
                          validate={[required]}
                          onChange={value => this.setState({ rxTypeId: value })}
                        />
                      </Grid>
                    )}
                    {this.state.rxTypeId === MedicationReferralStatus.Existing.toString() &&
                      (isSpecialty || medsEnrollment) && (
                        <Grid item xs={3}>
                          <Field
                            name="medication_status_start_dt"
                            label="Start Date *"
                            component={renderDatePicker}
                            onChange={value =>
                              onValueChange &&
                              onValueChange(selectedDrug, 'medication_status_start_dt', value)
                            }
                            validate={[required, validateDate]}
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid direction="column" container spacing={2} alignItems="flex-start">
                  <Grid container item xs={10} spacing={3} sx={{ mt: optOut ? 1 : 0 }}>
                    <Grid
                      item
                      xs={6}
                      className={this.state.showIcdIndicator ? classes.icdValidator : ''}
                    >
                      <Field
                        name="diagnosis"
                        label={`Diagnosis ${isSpecialty ? '*' : ''}`}
                        component={AutocompleteMinigrid}
                        validate={isSpecialty ? [required] : []}
                        fetchOptions={this.handleFetchDiagnosisOptions}
                        hint="Search by Diagnosis Name or Code"
                        columnsToShow={{
                          code: 'Code',
                          description: 'Description',
                        }}
                        clearable
                      />
                      {this.state.showIcdIndicator && (
                        <Tooltip
                          title={
                            <>
                              <Typography>This therapy is paired with a</Typography>
                              <Typography>NON RS ICD code</Typography>
                            </>
                          }
                          placement="right-start"
                        >
                          <InfoIcon className={classes.icdValidatorIcon} />
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <AutocompleteLabel
                        idValue={selectedDiagnosis.code}
                        defaultIdValue=""
                        label="Diagnosis Code"
                      />
                    </Grid>
                  </Grid>

                  {!optOut && (
                    <Grid container item xs={10} spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="secondary_diagnosis"
                          label="Secondary Diagnosis"
                          component={AutocompleteMinigrid}
                          fetchOptions={this.handleFetchDiagnosisOptions}
                          hint="Search by Diagnosis Name or Code"
                          columnsToShow={{
                            code: 'Code',
                            description: 'Description',
                          }}
                          clearable
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <AutocompleteLabel
                          idValue={selectedSecondaryDiagnosis.code}
                          defaultIdValue=""
                          label="Diagnosis Code"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {tertiaryDiagnosisAdded && !optOut && (
                    <Grid container xs={10} spacing={3} item>
                      <Grid item xs={6}>
                        <Field
                          name="tertiary_diagnosis"
                          label="Tertiary Diagnosis"
                          component={AutocompleteMinigrid}
                          fetchOptions={this.handleFetchDiagnosisOptions}
                          hint="Search by Diagnosis Name or Code"
                          columnsToShow={{
                            code: 'Code',
                            description: 'Description',
                          }}
                          clearable
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <AutocompleteLabel
                          idValue={selectedTertiaryDiagnosis.code}
                          defaultIdValue=""
                          label="Diagnosis Code"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {!optOut && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          this.setState({ tertiaryDiagnosisAdded: !tertiaryDiagnosisAdded })
                        }
                      >
                        {tertiaryDiagnosisAdded ? <EditDelete /> : <EditAdd />}
                        <Typography className={classes.addTertiaryDiagnosis}>
                          Tertiary Diagnosis
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                  <Box pt={2} />
                </Grid>
              </Grid>
            )}
            {(isSpecialty || medsEnrollment) && !selectedIsDocumentingOnly && (
              <>
                {/* Fourth Row */}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FieldLabel fieldName="Referral Source *" />
                    <Field
                      name="lead_source_id"
                      component={RenderTherapyLeadSource}
                      onChange={value =>
                        onValueChange && onValueChange(selectedDrug, 'lead_source_id', value)
                      }
                      validate={[required]}
                      data-qa-id="referral-source-value"
                    />
                  </Grid>
                </Grid>
                <Divider />
              </>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Grid item xs={12}>
                {(isSpecialty || medsEnrollment) &&
                  !selectedIsDocumentingOnly &&
                  managingClinicField}
              </Grid>
              <Grid container spacing={3} alignItems="flex-start">
                {((!isSpecialty && !medsEnrollment) || selectedIsDocumentingOnly) && (
                  <Grid item xs={12}>
                    <Field
                      name="source"
                      id="source"
                      label="Source *"
                      className={classes.radioGroup}
                      radioMap={medicationCategoryCodes.map(category => ({
                        label: category.category,
                        value: category.code,
                      }))}
                      component={renderReactRadioGroup}
                      validate={[required]}
                      width="100%"
                      qaId="add_therapy_source"
                    />
                  </Grid>
                )}
                {(isSpecialty || medsEnrollment) && !selectedIsDocumentingOnly && (
                  <Grid className="prescribing-radio-group" item xs={12}>
                    <FieldLabel fieldName="Prescribing Clinic *" />
                    <Field
                      name="prescribing_method_id"
                      component={RenderTherapyPrescribingClinic}
                      onChange={value =>
                        onValueChange && onValueChange(selectedDrug, 'prescribing_method_id', value)
                      }
                      validate={[required]}
                    />
                  </Grid>
                )}
                {((!isSpecialty && !medsEnrollment) ||
                  prescribingMethodId === PRESCRIBING_CLINIC_METHOD.USE_LOOKUP) &&
                  !selectedIsDocumentingOnly && (
                    <>
                      <Grid item xs={6} className={classes.npiLookupButtonContainer}>
                        <Field
                          name="referring_clinic"
                          label={`Prescribing Clinic${isSpecialty ? ' *' : ''}`}
                          component={AutocompleteMinigrid}
                          validate={isSpecialty ? [required] : null}
                          fetchOptions={this.handleFetchClinicOptions}
                          hint="Search by Clinic Name or NPI"
                          columnsToShow={{
                            name: 'Name',
                            address: 'Address',
                            npi: 'NPI',
                            is_internal: 'Is Internal',
                          }}
                          onChange={this.handleSaveColumnClinic}
                          minSearchLength={2}
                        />
                        <Button
                          className={classes.npiLookupButton}
                          onClick={() => this.setState({ npiLookupClinic: true })}
                        >
                          <SmallSearch />
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <AutocompleteLabel
                          idValue={selectedReferringClinic ? selectedReferringClinic.npi : null}
                          defaultIdValue=""
                          label="Clinic NPI"
                        />
                      </Grid>
                    </>
                  )}
              </Grid>
              {!selectedIsDocumentingOnly && (
                <>
                  <Divider />

                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={6}>
                      <Field
                        name="physician"
                        label={isSpecialty ? 'Prescriber *' : 'Prescriber'}
                        validate={isSpecialty ? [required] : null}
                        component={AutocompleteMinigrid}
                        fetchOptions={this.handleFetchPhysiciansOptions}
                        hint="Search by Physician Last, First Name or NPI"
                        simplistic
                        recentOptions={(recentProviders || []).map(icd10 => ({
                          ...icd10,
                          id: icd10.npi,
                          label: icd10.name,
                          description: icd10.address,
                        }))}
                        onChange={this.handleSaveColumnPhysician}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <AutocompleteLabel
                        idValue={selectedPhysician.npi}
                        defaultIdValue=""
                        label="Physician NPI"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <>
                {!isSpecialty && !medsEnrollment && !selectedIsDocumentingOnly && (
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={4}>
                      <Field
                        name="administration_status"
                        label="Administration *"
                        component={renderDropdown}
                        className={classes.input}
                        validate={[required]}
                        fields={statusDropdown}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="dispensing_status"
                        id="dispensing_status"
                        label="Dispensing *"
                        className={classes.radioGroup}
                        radioMap={therapyClinicalDispensingStatuses}
                        component={renderReactRadioGroup}
                        validate={[required]}
                        width="100%"
                        qaId="dispensing_status"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {optIn && !selectedIsDocumentingOnly && managingClinicField}
                    </Grid>
                    {optIn && (
                      <>
                        <Grid item xs={6} className={classes.npiLookupButtonContainer}>
                          <Field
                            name="transfer_pharmacy"
                            label="Transfer From Pharmacy"
                            component={AutocompleteMinigrid}
                            fetchOptions={this.handleFetchPharmacyOptions}
                            hint="Search by Pharmacy Name or NPI"
                            simplistic
                            recentOptions={(recentPharmacies || []).map(icd10 => ({
                              ...icd10,
                              id: icd10.npi,
                              label: icd10.name,
                              description: icd10.address,
                            }))}
                            onChange={this.handleSaveColumnTransferPharmacy}
                          />
                          <Button
                            className={classes.npiLookupButton}
                            onClick={() => this.setState({ transferNpiLookup: true })}
                          >
                            <SmallSearch />
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <AutocompleteLabel
                            idValue={selectedTransferPharmacy.npi}
                            label="Pharmacy NPI"
                          />
                        </Grid>
                      </>
                    )}
                    {(optIn || optOut) && (
                      <>
                        <Grid item xs={6} className={classes.npiLookupButtonContainer}>
                          <Field
                            name="dispensing_pharmacy"
                            label={`Dispensing Pharmacy ${optIn ? ' *' : ''}`}
                            validate={optIn ? [required] : []}
                            component={AutocompleteMinigrid}
                            fetchOptions={this.handleFetchPharmacyOptions}
                            hint="Search by Pharmacy Name or NPI"
                            simplistic
                            recentOptions={(recentPharmacies || []).map(icd10 => ({
                              ...icd10,
                              id: icd10.npi,
                              label: icd10.name,
                              description: icd10.address,
                            }))}
                            onChange={this.handleSaveColumnPharmacy}
                          />
                          <Button
                            className={classes.npiLookupButton}
                            onClick={() => this.setState({ npiLookup: true })}
                          >
                            <SmallSearch />
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <AutocompleteLabel
                            idValue={selectedDispensingPharmacy.npi}
                            defaultIdValue=""
                            label="Pharmacy NPI"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}

                {!selectedIsDocumentingOnly && (
                  <>
                    <Divider />
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={2} id="nbd">
                        <Field
                          name="needsby_date"
                          label={isSpecialty || optIn ? 'Needs By Date *' : 'Needs By Date'}
                          component={renderDatePicker}
                          onChange={value =>
                            onValueChange && onValueChange(selectedDrug, 'needsby_date', value)
                          }
                          validate={
                            isSpecialty || optIn ? [required, validateDate] : [validateDate]
                          }
                          data-qa-id="ar_therapies_form_needs_by_date"
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Field
                          label={isSpecialty || optIn ? 'Days Supply *' : 'Days Supply'}
                          name="days_supply"
                          validate={(isSpecialty || optIn) && [required]}
                          component={renderNumberField}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          name="is_needsby_per_protocol"
                          label="Per Protocol"
                          component={renderCheckbox}
                          onChange={e =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'is_needsby_per_protocol', e.target.checked)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          name="as_needed"
                          component={renderCheckbox}
                          label="As Needed"
                          onChange={e =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'as_needed', e.target.checked)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          name="clinic_administered"
                          component={renderCheckbox}
                          label="Clinic Administered"
                          onChange={e =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'clinic_administered', e.target.checked)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          name="drop_ship_med"
                          component={renderCheckbox}
                          label="Drop-ship Med"
                          onChange={e =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'drop_ship_med', e.target.checked)
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="service_group_name_and_id"
                          label="Service Group *"
                          validate={[required]}
                          component={ReactSelectForReduxSingle}
                          fields={serviceGroups.map(int => ({
                            label: int.display_name,
                            value: int.id,
                            isDisabled: !int.active,
                          }))}
                          state={serviceGroupDropdown}
                          onChange={value =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'service_group_name_and_id', value)
                          }
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}
                {(arDrug || medsEnrollment) && (
                  <Grid item xs="12">
                    <EditTherapyEnrollment
                      enrollmentStatuses={enrollmentStatuses}
                      isSpecialty={isSpecialty}
                      diagnosisCode={selectedDiagnosis.code}
                      clinicalSupportStatusId={clinicalSupportStatusId}
                      pharmacyValues={selectedDispensingPharmacy}
                      transferPharmacyValues={selectedTransferPharmacy}
                      handlePharmacyNpiChange={this.handlePharmacyNpiChange}
                      handleTransferPharmacyNpiChange={this.handleTransferPharmacyNpiChange}
                      handleSaveColumnPharmacy={this.handleSaveColumnPharmacy}
                      patientId={patientId}
                      ndc={selectedDrug.ndc}
                      disabled={medsEnrollment}
                      selectedDispensingStatus={selectedDispensingStatus}
                      selectedClinicalStatus={selectedClinicalStatus}
                      selectedDispensingReason={selectedDispensingReason}
                      selectedClinicalReason={selectedClinicalReason}
                      arTask={arTask}
                      onValueChange={(name, value) => {
                        if (onValueChange) {
                          onValueChange(selectedDrug, name, value);
                        }
                      }}
                    />
                  </Grid>
                )}
                {!handleNbdInDaysSupply() && (
                  <OverrideFieldSection
                    overrideField="needsby_dt"
                    overrideWarningText={`According to the needs by date you've entered, the patient needs ${selectedDaysSupply} days supply. Is this Needs By date correct?`}
                  />
                )}
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={3} md={2}>
                    <Field
                      name="therapy_duration"
                      label="Therapy Duration"
                      component={renderDaysSelector}
                    />
                  </Grid>
                </Grid>
                <Divider />
                {medSync && (
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={12}>
                      <Typography className={classes.medSyncLabel}>Med Sync</Typography>
                      <MedSynchronized className={classes.medSyncIconStyle} />
                      <Typography className={classes.medSyncInfo}>
                        The patient has a med sync ongoing.
                        <br />
                        This therapy can be synchronized from the patient demographics bar
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>

              <Grid item xs={12}>
                <RunBiFromAR
                  drug={selectedDrug}
                  therapy={selectedTherapy}
                  physician={this.state.prescriber}
                  ndc={selectedTherapy.ndc}
                  needsByDate={selectedNeedsByDate}
                  daysSuply={selectedDaysSupply}
                  arTaskId={selectedDrug.id}
                  disabled={this.state.prescriber === undefined || this.state.prescriber === null}
                />
              </Grid>
            </Box>
            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="add_therapy_cancel_button"
              submitButtonName="add_therapy_submit_button"
              handleCancel={this.handleCancelClick}
              isLoading={this.state.submittingAR}
              disableSubmit={submitting}
              {...(back && {
                leftAlignedButtons: [
                  {
                    button: <Button onClick={back}>Back</Button>,
                  },
                ],
              })}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  const { patient, therapyStatuses, medications, therapies } = state;

  const { pharmacies: recentPharmacies, providers: recentProviders } =
    state.userPreferences.recent_items;
  const {
    initialTherapyValues,
    selectedDrug,
    id,
    forceDocumentOnlyOption,
    failedTherapyForm,
    initialDocumentingOnly = false,
    arTask,
  } = props;

  const arTaskManagingClinic = state.lookups.customerClinics.find(
    item => item.id === arTask.managing_clinic_id,
  );

  const formId = selectedDrug
    ? `${ADD_PATIENT_THERAPY_FORM}-${selectedDrug.id || id}`
    : ADD_PATIENT_THERAPY_FORM;

  const selector = formValueSelector(formId);
  const managingClinicNameAndId = selector(state, 'managing_clinic_name_and_id');
  const prescribingMethodId = selector(state, 'prescribing_method_id');
  const selectedTherapy = selector(state, 'therapy') || {};
  const selectedDiagnosis = selector(state, 'diagnosis') || {};
  const selectedSecondaryDiagnosis = selector(state, 'secondary_diagnosis') || {};
  const selectedTertiaryDiagnosis = selector(state, 'tertiary_diagnosis') || {};
  const selectedPhysician = selector(state, 'physician') || {};
  const selectedDispensingPharmacy = selector(state, 'dispensing_pharmacy') || {};
  const selectedTransferPharmacy = selector(state, 'transfer_pharmacy') || {};
  const selectedReferringClinic = selector(state, 'referring_clinic') || {};
  const selectedDispensingStatus = selector(state, 'dispensing_status');
  const selectedClinicalStatus = selector(state, 'clinical_support_status');
  const selectedDispensingReason = selector(state, 'external_dispensing_reason');
  const selectedClinicalReason = selector(state, 'clinical_support_status_reason');
  const selectedPharmacyName = selector(state, 'dispensing_pharmacy_name');
  const selectedIsDocumentingOnly = selector(state, 'is_documenting_only');
  const selectedNeedsByDate = selector(state, 'needsby_date');
  const selectedDaysSupply = selector(state, 'days_supply');
  const selectedNeedsByDateOverride = selector(state, 'needsby_dt_override');
  const selectedNeedsByDateOverrideReason = selector(state, 'needsby_dt_override_reason');

  let drugSource = '';
  let startDt = null;
  let endDt = null;
  let statusCode = '';
  let initialRxTypeId = null;
  let initialLeadSourceId = null;
  let initialManagingClinicName = null;
  let initialReferringClinic = null;
  let initialManagingClinicNameAndId = null;
  let clinicalSupportStatusId = null;

  if (selectedDrug) {
    drugSource = selectedDrug.category_code;
    startDt = selectedDrug.start_dt;
    endDt = selectedDrug.end_dt;
    statusCode = selectedDrug.status_code;
    clinicalSupportStatusId = selectedDrug.clinical_support_status_id;
  }
  if (initialTherapyValues) {
    initialRxTypeId = initialTherapyValues.rxTypeId;
    initialLeadSourceId = initialTherapyValues.leadSourceId;
    initialManagingClinicName = initialTherapyValues.managingClinicName;
    initialReferringClinic = initialTherapyValues.referringClinic;
    initialManagingClinicNameAndId = initialTherapyValues.managingClinicNameAndId;
  }

  const medicationGpis = new Set();

  if (medications.medicationGroups && medications.medicationGroups.length > 0) {
    medications.medicationGroups.forEach(med => medicationGpis.add(med.gpi.substr(0, 10)));
  }

  const defaultManagingClinic = managingClinicNameAndId?.label
    ? managingClinicNameAndId
    : {
        label: arTaskManagingClinic?.name,
        value: arTaskManagingClinic?.id,
        npi: arTaskManagingClinic?.npi,
      };

  const clinicData =
    initialTherapyValues && initialTherapyValues.managingClinicNameAndId?.label
      ? initialManagingClinicNameAndId
      : defaultManagingClinic;
  return {
    patient,
    therapies: therapies.data,
    therapyStatuses,
    selectedReferringClinic,
    prescribingMethodId,
    recentPharmacies,
    medicationList: Array.from(medicationGpis),
    initialValues: {
      referral_dt: convertToArborDate(getTodayDateTime()).getCustomerDate(true),
      needsby_date: convertToArborDate(selectedNeedsByDate).getUtcDate(),
      is_needsby_per_protocol: null,
      lead_source_id:
        arTask.reason_id === AR_PROVIDER_REFERRAL
          ? THERAPY_LEAD_SOURCE_CLINICIAN_REFERRAL
          : initialLeadSourceId,
      rx_type_id: initialRxTypeId,
      drug_name: null,
      referring_clinic: initialReferringClinic,
      managing_clinic_name: initialManagingClinicName,
      days_supply: null,
      service_group_name_and_id:
        initialTherapyValues && initialTherapyValues.serviceGroupId
          ? getInitialServiceGroupForReactSelect(
              initialTherapyValues.serviceGroupId,
              state.lookups.serviceGroups,
            )
          : '',
      managing_clinic_name_and_id: clinicData,
      prescribing_method_id: initialManagingClinicNameAndId,
      diagnosis: null,
      physician: null,
      source: drugSource || 'patientspecified',
      administration_status: 'On Therapy',
      // eslint-disable-next-line camelcase
      dispensing_status: initialTherapyValues?.dispensing_status || DISPENSING_STATUSES.UNDECIDED,
      clinical_support_status: DISPENSING_STATUSES.UNDECIDED,
      is_documenting_only: !!forceDocumentOnlyOption || initialDocumentingOnly,
      start_date: startDt && convertToArborDate(startDt).getUtcDate(true),
      end_date: endDt && convertToArborDate(endDt).getUtcDate(true),
      emr_status: failedTherapyForm ? 'completed' : statusCode,
      dosis_regimen: null,
      as_needed: null,
      clinic_administered: null,
      drop_ship_med: null,
      dispensing_undecided_reason: DISPENSING_UNDECIDED_REASONS.NO_YET_OFFERED,
      clinical_support_undecided_reason: DISPENSING_UNDECIDED_REASONS.NO_YET_OFFERED,
    },
    serviceGroups: state.lookups.serviceGroups,
    managingClinics: state.lookups.customerClinics,
    managingClinicNameAndId: defaultManagingClinic,
    enrollmentStatuses: state.lookups.enrollmentStatuses,
    recentProviders,
    selectedTherapy,
    selectedDiagnosis,
    selectedSecondaryDiagnosis,
    selectedTertiaryDiagnosis,
    selectedPhysician,
    selectedDispensingPharmacy,
    selectedTransferPharmacy,
    selectedDispensingStatus,
    selectedClinicalStatus,
    selectedDispensingReason,
    selectedClinicalReason,
    selectedPharmacyName,
    selectedIsDocumentingOnly,
    selectedNeedsByDate,
    selectedDaysSupply,
    selectedNeedsByDateOverride,
    selectedNeedsByDateOverrideReason,
    medicationCategoryCodes: state.lookups.medicationCategoryCodes,
    form: formId,
    destroyOnUnmount: !selectedDrug,
    clinicalSupportStatusId,
    arTaskManagingClinic,
    medSync: windowFeatureIsEnabled('med_sync') && patient.med_sync === MED_SYNC_STATUS.OPT_IN,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    addTherapy,
    addTherapyLinkDrug,
    addMedication,
    fetchTaskCounts,
    getChecklistData,
    updateMedication,
    updateLocalUserPreferencesRecentClinics,
    updateLocalUserPreferencesRecentPrescribers,
    updateLocalUserPreferencesRecentPharmacies,
    addNote,
    change,
    dischargePatient,
    editTasks,
  }),
  createMemoReduxForm(),
)(AddPatientTherapy);
