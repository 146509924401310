import React, { memo, useCallback, useState } from 'react';
import { groupBy } from 'helpers/misc';
import { Grid } from '@mui/material';
import { ITask, IIntTask } from 'interfaces/redux/ITasks';
import {
  DC,
  RS,
  ALLERGIES_VERIFIED,
  FC,
  FDC,
  ADD_PATIENT_DOCUMENT2,
  ADD_NOTE,
  INT,
  EFFICACY_TRIGGER_TYPE,
  SAFETY_TRIGGER_TYPE,
} from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { generatorFactory } from 'components/document/generator/generator-factory';
import BundleTaskForm from 'components/dynamic-form/bundle-task-form';
import { TaskStatusFactory } from 'factories/task-status-factory/task-status-factory';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import { minBy, cloneDeep } from 'lodash';
import { isDcTransitioningToIntervening } from 'services/utils/data-collect';
import { DocumentGeneratorType } from 'components/document/generator/types';
import TaskFormFactory from 'factories/task-form-factory/task-form-factory';
import { getNotesHigestId, parseNoteToDb } from 'services/utils/note-service';
import { addTasksOldValues } from 'services/utils/task-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { noteTagTypes } from 'constants/lists';
import { getTodayDateTime } from 'services/utils/date-service';
import { getDocuments } from 'services/utils/upload-document-service';

import { fetchNotes, fetchNewNotes } from 'actions/action-notes';
import { refreshPatientTherapiesAndTasks, workListChanged } from 'actions/action-patient';
import { editTasks } from 'actions/action-tasks';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { verifyAllergies } from 'actions/action-allergies';
import useStore from 'hooks/useStore';
import { windowFeatureIsEnabled } from 'config/window-features';
import moment from 'moment';
import intJson from 'config/task-types/int.json';
import { CombinedTasks, TaskType } from './grouped-task-tabs';
import { IIntPairs } from './types';
import {
  GroupedTaskTabUtil,
  isValidJson,
  getClinicalItemsBasedOnIntMapping,
  getResourceLinks,
} from './util';
import {
  InterventionStatuses,
  InterventionStatusesIds,
} from '../../../constants/intervention-statuses';

export interface GroupedTaskTabsFormProps {
  selectedTasks: ITask[];
  handleCancel: (unSelectedTasks: ITask[]) => void;
  onSelectTaskIds: (
    newSelectedTasks: { newSelectedTaskId: number; newSelectedTaskType: string }[],
  ) => void;
  classes: any;
  taskType: string;
  intPairs: IIntPairs[];
  handleOnSelectedRow: Function;
}

export const GroupedTaskTabsForm: React.FC<GroupedTaskTabsFormProps> = props => {
  interface ItaskState {
    followUpTherapyId: number | null;
    followUpDataCollectedDate: Date | null;
    followUpMinAssessmentDate: string | undefined | null;
    followUpTherapyDuration: number | null;
    value: any;
    newDocuments: any;
    existingNotes: any;
  }

  const dispatch = useDispatch();

  const {
    selectedTasks,
    handleCancel,
    classes,
    onSelectTaskIds,
    taskType,
    intPairs,
    handleOnSelectedRow,
  } = props;

  const existingNotes = useSelector((state: IState) => state.notes);
  const existingDocuments = useSelector((state: IState) => state.uploadedDocuments.documents);
  const patient = useSelector((state: IState) => state.patient);
  const selectedCustomerId = useSelector((state: IState) => state.filters.selectedCustomerId);
  const selectedPatientId = useSelector((state: IState) => state.selectedPatientId);
  const therapies = useSelector((state: IState) => state.therapies);
  const clinicalDataItems = useSelector((state: IState) => state.clinicalData);
  const tasksList = useSelector((state: IState) => state.tasks.data);
  const [mergeInterventionMapping, selectedTaskKeys, dcProtocols, links] = useStore(
    'tasks.mergeInterventionMapping',
    'tasks.selectedTaskKeys',
    'dcProtocols.data',
    'links.data',
  );
  const userRole = useSelector((state: IState) => state.auth.currentUser.role);
  const [taskState, setTaskState] = useState<ItaskState>();

  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  const combinedTask: CombinedTasks = groupBy(selectedTasks, 'taskType');

  const getLastClinicalItemId = (itemId: number, taskType: string, taskId: number) => {
    const listOfIds = clinicalDataItems
      ?.reduce((acc, i) => {
        if (taskType === DC) {
          if (i.clinicalDataTypeId === itemId && i.taskDataCollectId === taskId) {
            acc.push(i.id);
          }
        }
        if (taskType === RS) {
          if (i.clinicalDataTypeId === itemId && i.taskRiskStratId === taskId) {
            acc.push(i.id);
          }
        }
        return acc;
      }, [] as number[])
      .sort((a, b) => b - a);
    return listOfIds;
  };

  const cleanUpWorkAsGroupPayload = (payloadTasks: any) => {
    const pivot = payloadTasks.find(
      (t: any) => t.taskType === DC && t.work_as_group_tasks && t.work_as_group_tasks.length,
    );
    pivot.work_as_group_tasks = pivot.work_as_group_tasks.reduce(
      (
        acc: { task_id: number; therapy_id: number; service_group_id: number }[],
        it: { task_id: number; therapy_id: number; service_group_id: number },
      ) => {
        if (
          selectedTasks.some(
            t => t.taskType === DC && t.id === it.task_id && t.therapy_id === it.therapy_id,
          )
        ) {
          acc.push(it);
        }
        return acc;
      },
      [],
    );
    pivot.work_as_group = pivot.work_as_group_tasks.map(
      (it: { task_id: number; therapy_id: number; service_group_id: number }) => it.therapy_id,
    );
    const wagIds =
      pivot.work_as_group_tasks && pivot.work_as_group_tasks.length
        ? pivot.work_as_group_tasks.map((t: any) => t.task_id)
        : [];
    if (Object.keys(pivot).length) {
      return payloadTasks.reduce((acc: any, task: any) => {
        if (
          task.taskType === pivot.taskType &&
          task.id === pivot.id &&
          task.therapy_id === pivot.therapy_id
        ) {
          acc.push(task);
        } else if (
          !wagIds.includes(task.id) &&
          pivot.work_as_group &&
          pivot.work_as_group.length &&
          !pivot.work_as_group.includes(task.therapy_id)
        ) {
          acc.push(task);
        }
        return acc;
      }, []);
    }
    return payloadTasks;
  };

  const wagChecker = (payloadTasks: any) => {
    const pivots = payloadTasks.filter(
      (t: any) =>
        t.taskType === DC &&
        t.work_as_group &&
        t.work_as_group_tasks &&
        (t.work_as_group.length !== t.work_as_group_tasks.length ||
          (t.work_as_group.length === 0 && t.work_as_group_tasks.length === 0)),
    );
    if (Object.keys(pivots).length) {
      return payloadTasks.reduce((acc: any, task: any) => {
        for (const pivot of pivots) {
          if (
            task.taskType === pivot.taskType &&
            task.id === pivot.id &&
            task.therapy_id === pivot.therapy_id
          ) {
            acc.push({
              ...task,
              work_as_group: undefined,
              work_as_group_tasks: undefined,
            });
          } else {
            acc.push(task);
          }
        }
        return acc;
      }, []);
    }
    return payloadTasks;
  };

  const restrictedRolesToCompleteInt = (payloadTasks: any) => {
    const payloadChecked = payloadTasks.map((task: any) => {
      if (
        task.taskType &&
        task.taskType === INT &&
        task.status_id &&
        task.status_id === InterventionStatuses.Completed
      ) {
        const tempStatusToCheck = intJson.statuses.find(
          st => st.id === InterventionStatusesIds.Completed,
        );
        if (
          tempStatusToCheck &&
          tempStatusToCheck.restrictedByrole &&
          tempStatusToCheck.restrictedRoles &&
          tempStatusToCheck.restrictedRoles.length &&
          tempStatusToCheck.restrictedRoles.includes(userRole)
        ) {
          return {
            ...task,
            status_id: task.old_status_id,
          };
        }
      }
      return task;
    });
    return payloadChecked;
  };

  const updateTaskState = (obj: any) => {
    setTaskState(obj);
  };

  const onCancel = () => {
    const res = selectedTasks.reduce((acc, task) => {
      if (task.taskType === taskType) {
        acc.push(task);
        const tempTask = GroupedTaskTabUtil.getAssociatedIntTask(task, intPairs, tasksList);
        if (tempTask && Object.keys(tempTask).length) {
          acc.push(tempTask);
        }
      }
      return acc;
    }, [] as ITask[]);
    handleCancel(res);
  };

  const getMissingDataCollectItems = (dataCollectList: any[], taskId: number) => {
    const missingItems: any[] = [];
    const taskClinicalItems = clinicalDataItems?.filter(t => t.taskDataCollectId === taskId);
    taskClinicalItems?.map(it => {
      const existingItem = dataCollectList.find(
        t => parseInt(t.clinicalDataTypeId, 10) === it.clinicalDataTypeId,
      );
      if (!existingItem) {
        missingItems.push({
          assessmentDate: moment(it.assessmentDate).utc().format('yyyy-MM-DD'),
          cannotCompleteReason: it.cannotCompleteReason,
          clinicalDataTypeId: String(it.clinicalDataTypeId),
          id: it.id,
          required: !!it.required,
          value: it.value,
        });
      }
    });
    return missingItems;
  };

  const getProtocalData = (
    protocolList: { efficacyProtocols: any[]; safetyProtocols: any[] },
    clinicalDataTypeId: number,
  ) => {
    let protocolData = {};
    const efficacyPro = protocolList.efficacyProtocols.find(
      t => t.clinicalDataTypeId === clinicalDataTypeId,
    );
    const safetyPro = protocolList.safetyProtocols.find(
      t => t.clinicalDataTypeId === clinicalDataTypeId,
    );
    if (efficacyPro && Object.keys(efficacyPro).length) {
      protocolData = {
        trigger: efficacyPro.trigger,
        trigger_condition: efficacyPro.triggerCondition,
        trigger_threshold: efficacyPro.triggerThreshold,
        trigger_type: EFFICACY_TRIGGER_TYPE,
      };
    } else if (safetyPro && Object.keys(safetyPro).length) {
      protocolData = {
        trigger: safetyPro.trigger,
        trigger_condition: safetyPro.triggerCondition,
        trigger_threshold: safetyPro.triggerThreshold,
        trigger_type: SAFETY_TRIGGER_TYPE,
      };
    }
    return protocolData;
  };

  const getMissingIntObjects = (clonedJson: any[], taskId: number) => {
    const missingInts: any[] = [];
    const resourceLinks = getResourceLinks(taskId, links);
    resourceLinks.map(it => {
      const metaInfo = JSON.parse(it.meta);
      const existingInt = clonedJson.find(
        t =>
          t.id === it.resource_two_id && t.clinical_data_type_id === metaInfo.clinical_data_type_id,
      );
      if (!existingInt) {
        const protocolData = getProtocalData(dcProtocols[taskId], metaInfo.clinical_data_type_id);
        const intTaskData: IIntTask = tasksList[`${INT}${it.resource_two_id}`] as IIntTask;
        if (intTaskData && Object.keys(intTaskData).length) {
          missingInts.push({
            category_id: intTaskData.category_id,
            clinical_data_type_id: metaInfo.clinical_data_type_id,
            details: intTaskData.details,
            followup_dt: moment(intTaskData.followup_dt).utc().format('yyyy-MM-DD hh:mm:ss'),
            id: intTaskData.id,
            outcome: intTaskData.outcome,
            reason: intTaskData.reason,
            recommendation: intTaskData.recommendation,
            service_group_id: intTaskData.service_group_id,
            severity_id: intTaskData.severity_id,
            therapy_ids_inactive: intTaskData.therapy_ids_inactive ?? null,
            type_id: intTaskData.type_id,
            ...protocolData,
          });
        }
      }
    });
    return missingInts;
  };

  const handleOnSubmit = useCallback(
    async (taskList: any, orders: any, type: any, existingNotes: any, attachedDocuments: any) => {
      const notes = taskList
        .filter((t: any) => t.note)
        .map((t: any) => {
          const parsedNote = parseNoteToDb({ note_text: t.note });
          const noteTagType = noteTagTypes?.find(tag => tag.label === t.taskType);

          const typeId = noteTagType ? noteTagType.value : null;
          return {
            patient_id: selectedPatientId,
            note_type_id: 1,
            note_text: parsedNote.note_text,
            is_pinned: 0,
            mentions: parsedNote.mentions,
            tags: [
              {
                tag_type_id: typeId,
                resource_id: t.id,
              },
            ],
          };
        });

      if (taskList && taskList.length) {
        for (const taskListElem of taskList) {
          if (taskListElem.data_collect && taskListElem.data_collect.length) {
            for (const elem of taskListElem.data_collect) {
              if (elem.id === undefined || elem.id === null) {
                const listOfIds = getLastClinicalItemId(
                  parseInt(elem.clinicalDataTypeId, 10),
                  taskListElem.taskType,
                  taskListElem.id,
                );
                elem.id = listOfIds && listOfIds.length ? listOfIds[0] : elem.id;
              }
            }
          }
        }
      }

      const combinedTaskOld = groupBy(selectedTasks, 'taskType');

      let withOldTasks = combinedTaskOld[type]
        ? addTasksOldValues(taskList, combinedTaskOld[type])
        : taskList;

      const clonedTasksToBeAdded = [];
      if (windowFeatureIsEnabled('merge_interventions')) {
        for (const withOldTask of withOldTasks) {
          if (withOldTask.taskType === DC) {
            const clinicalItemsToCheck = getClinicalItemsBasedOnIntMapping(
              withOldTask.id,
              mergeInterventionMapping,
              selectedTaskKeys,
            );
            if (
              clinicalItemsToCheck.needToCheck &&
              clinicalItemsToCheck.clinicalItemTaskPairs.length &&
              clinicalItemsToCheck.clinicalItemTaskPairs.length > withOldTask.data_collect.length
            ) {
              // reduce the list of clinical items just to have the tasks we need to clone
              const tasksToClone = clinicalItemsToCheck.clinicalItemTaskPairs
                .reduce((acc, it) => {
                  if (it.taskId !== withOldTask.id) {
                    acc.push(it);
                  }
                  return acc;
                }, [] as { clinicalDataTypeId: number; taskId: number; therapyId: number; intId: number }[])
                .reduce((acc, it) => {
                  const existingObj = acc.find(t => t.taskId === it.taskId);
                  const currentIntTask: IIntTask = tasksList[`${INT}${it.intId}`] as IIntTask;
                  if (
                    existingObj &&
                    Object.keys(existingObj).length &&
                    !existingObj.clinicalDataTypeIds.includes(it.clinicalDataTypeId)
                  ) {
                    existingObj.clinicalDataTypeIds.push(it.clinicalDataTypeId);
                  } else if (!currentIntTask.outcome) {
                    acc.push({
                      taskId: it.taskId,
                      therapyId: it.therapyId,
                      intId: it.intId,
                      clinicalDataTypeIds: [it.clinicalDataTypeId],
                    });
                  }
                  return acc;
                }, [] as { taskId: number; therapyId: number; intId: number; clinicalDataTypeIds: number[] }[]);
              // here we need to clone the task and update the corresponding values
              for (const taskToClone of tasksToClone) {
                const tempClonedTask = cloneDeep(withOldTask);
                let clonedJson = [];
                if (
                  tempClonedTask.intervention_efficacy_safety_json &&
                  isValidJson(tempClonedTask.intervention_efficacy_safety_json)
                ) {
                  const tempIntJsons = JSON.parse(tempClonedTask.intervention_efficacy_safety_json);
                  for (const tempIntJson of tempIntJsons) {
                    const intData = clinicalItemsToCheck.clinicalItemTaskPairs.find(
                      t =>
                        t.taskId === taskToClone.taskId &&
                        t.clinicalDataTypeId === tempIntJson.clinical_data_type_id,
                    );
                    if (intData && Object.keys(intData).length) {
                      tempIntJson.id = intData.intId;
                      clonedJson.push(tempIntJson);
                    }
                  }
                  const missingIntObjects = getMissingIntObjects(clonedJson, taskToClone.taskId);
                  if (missingIntObjects.length) {
                    clonedJson = clonedJson.concat(missingIntObjects);
                  }
                }
                tempClonedTask.delivery.id = taskToClone.taskId;
                tempClonedTask.id = taskToClone.taskId;
                tempClonedTask.old_id = taskToClone.taskId;
                tempClonedTask.old_therapy_id = taskToClone.therapyId;
                tempClonedTask.therapy_id = taskToClone.therapyId;
                tempClonedTask.data_collect.map((it: any) => {
                  const tempClinicalItem = clinicalDataItems?.find(
                    t =>
                      t.clinicalDataTypeId === parseInt(it.clinicalDataTypeId, 10) &&
                      t.taskDataCollectId === taskToClone.taskId,
                  );
                  if (tempClinicalItem && Object.keys(tempClinicalItem).length) {
                    it.id = tempClinicalItem.id;
                  }
                });
                const missingDataCollectItems = getMissingDataCollectItems(
                  tempClonedTask.data_collect,
                  taskToClone.taskId,
                );
                if (missingDataCollectItems.length) {
                  tempClonedTask.data_collect =
                    tempClonedTask.data_collect.concat(missingDataCollectItems);
                }

                tempClonedTask.intervention_efficacy_safety_json = JSON.stringify(clonedJson);
                clonedTasksToBeAdded.push(tempClonedTask);
              }
            }
          }
        }
      }
      if (clonedTasksToBeAdded && clonedTasksToBeAdded.length) {
        withOldTasks = withOldTasks.concat(clonedTasksToBeAdded);
      }
      const newDocuments = attachedDocuments.map((doc: any) => ({
        uuid: doc.uuid,
        file_name: doc.filename,
        labels: doc.labels,
        tags: doc.taskIds.map((id: any) => ({
          tag_type_id: doc.taskTypeId,
          resource_id: id,
        })),
        noteText: doc.note,
      }));
      if (withOldTasks.some((task: any) => task.taskType === FC && !task.newAllergies)) {
        const allergyVerificationPayload = {
          patient_id: patient.id,
          verification_status_id: ALLERGIES_VERIFIED,
          verified_dt: convertToArborDate(getTodayDateTime()).getUtcDatetime(),
        };
        verifyAllergies(allergyVerificationPayload);
      }
      if (
        withOldTasks.some(
          (task: any) =>
            task.taskType === DC && task.work_as_group_tasks && task.work_as_group_tasks.length,
        )
      ) {
        withOldTasks = cleanUpWorkAsGroupPayload(withOldTasks);
      }
      withOldTasks = wagChecker(withOldTasks);
      withOldTasks = restrictedRolesToCompleteInt(withOldTasks);

      const ediTaskRes = editTasks(selectedPatientId, withOldTasks, orders, notes, newDocuments);
      await dispatch(ediTaskRes);
      await dispatch({ type: ADD_PATIENT_DOCUMENT2, payload: ediTaskRes?.payload });
      await dispatch({ type: ADD_NOTE, payload: ediTaskRes?.payload });
      dispatch(workListChanged());

      // Ensure status is updated on therapies where FDC tasks are completed
      if (withOldTasks.some((task: any) => task.taskType === FDC)) {
        dispatch(refreshPatientTherapiesAndTasks(selectedPatientId));
      }
      if (notes.length) {
        const { pinnedNoteHighestId, nonPinnedNoteHighestId } = getNotesHigestId(existingNotes);
        if (pinnedNoteHighestId === -1 && nonPinnedNoteHighestId === -1) {
          fetchNotes({
            patient_id: selectedPatientId,
            group_by_tag: true,
          });
        } else if (pinnedNoteHighestId > nonPinnedNoteHighestId) {
          fetchNewNotes({
            patient_id: selectedPatientId,
            group_by_tag: true,
            fetchFromId: pinnedNoteHighestId,
          });
        } else {
          fetchNewNotes({
            patient_id: selectedPatientId,
            group_by_tag: true,
            fetchFromId: nonPinnedNoteHighestId,
          });
        }
      }
      getDocuments({
        patient_id: selectedPatientId,
        fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
      }).then(result => {
        addNewDocument(result.data.documents);
      });
      onCancel();
    },
    [
      cleanUpWorkAsGroupPayload,
      dispatch,
      existingDocuments,
      getLastClinicalItemId,
      onCancel,
      patient.id,
      selectedPatientId,
      selectedTasks,
    ],
  );

  let taskPropArr: any[] = [];
  Object.keys(combinedTask).map(item => {
    const type = item as TaskType;
    if (taskType === item) {
      const taskTypeProcessor = TaskStatusFactory.getProcessor(type);
      const documentGeneratorType = item as DocumentGeneratorType;
      const generator = generatorFactory(
        documentGeneratorType,
        selectedCustomerId,
        selectedPatientId,
      );
      const submitButtonTextFn = generator && generator.saveButtonText;
      const taskType = type;
      const patientId = selectedPatientId;
      const taskIds = combinedTask[type].map(task => task.id);
      const shouldShowPreviewButtonFn = generator && generator.showPreviewButton;

      const onSubmit = (
        taskList: any,
        orders: any,
        attachedDocuments: any,
        generateDocumentAfterSave: any,
      ) => {
        const tasks = taskList.filter(
          (task: any) =>
            task.taskType === DC &&
            task.data_collect_followup &&
            task.status_id === DataCollectStatus.Reviewed,
        );
        const isDCFollowUp = !!(tasks && tasks.length);
        if (
          isDCFollowUp &&
          tasks &&
          tasks.length &&
          tasks.some((task: any) => !isDcTransitioningToIntervening(task))
        ) {
          const task = tasks.find((t: any) => !isDcTransitioningToIntervening(t));
          if (task && Object.keys(task).length) {
            const assessmentDates = task.data_collect
              .map((dcItem: any) => dcItem.assessmentDate)
              .filter((assessDate: any) => !!assessDate);
            const minAssessmentDate = assessmentDates.length
              ? minBy<string>(assessmentDates, assessDateArborDate =>
                  Date.parse(assessDateArborDate),
                )
              : undefined;
            setTaskState({
              followUpTherapyId: task.therapy_id,
              followUpDataCollectedDate: task.data_collected_date,
              followUpMinAssessmentDate: minAssessmentDate,
              followUpTherapyDuration: task.therapy_duration,
              value: { taskList, orders, type },
              newDocuments: attachedDocuments,
              existingNotes: existingNotes,
            });
          }
          return;
        }
        handleOnSubmit(taskList, orders, type, existingNotes, attachedDocuments);
      };

      // This is Counseling tasks only
      const combinedTasksOfSameType = combinedTask[type];

      //      // for now this par ams are used only for CSL tasks (form factory as well)
      const taskFormFactoryProps = {
        tasks: combinedTasksOfSameType,
        legacyFormProps: {
          submitButtonTextFn,
          combinedTask,
          taskType,
          patientId,
          taskIds,
          shouldShowPreviewButtonFn,
          onSubmit,
          onCancel,
        },
        cancelClick: onCancel,
        onSelectTaskIds: onSelectTaskIds,
        taskStatusProcessor: taskTypeProcessor,
        taskWrapperClass: '',
        therapies: therapies.data,
        handleOnSelectedRow: handleOnSelectedRow,
      };

      // Render multiple forms (only applies to FC and CSL tasks)
      if (taskTypeProcessor.taskShouldRenderCustomMultipleForms(combinedTask[taskType])) {
        taskPropArr = [
          ...taskPropArr,
          <TaskFormFactory.MultipleForms {...taskFormFactoryProps} key={type} />,
        ];
      }

      // Render single custom form
      else if (taskTypeProcessor.taskShouldRenderCustomForm(combinedTask[taskType][0])) {
        taskPropArr = [
          ...taskPropArr,
          <TaskFormFactory.SingleForm {...taskFormFactoryProps} key={type} />,
        ];
      } else {
        taskPropArr = [
          ...taskPropArr,
          <BundleTaskForm
            key={type}
            submitButtonTextFn={submitButtonTextFn}
            combinedTask={combinedTask}
            taskType={taskType}
            patientId={patientId}
            taskIds={taskIds}
            isLoadingPreview={isLoadingPreview}
            shouldShowPreviewButtonFn={shouldShowPreviewButtonFn}
            onSubmit={onSubmit}
            onCancel={onCancel}
            taskState={taskState}
            updateTaskState={updateTaskState}
            handleOnSubmit={handleOnSubmit}
          />,
        ];
      }
    }
  });
  return (
    <Grid item xs={12}>
      {taskPropArr}
    </Grid>
  );
};
