import React, { useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Theme,
  Button,
} from '@mui/material';
import { makeStyles, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { reduxForm } from 'redux-form';
import { buildQaId } from 'utils/build-qa-id';
import { compose } from 'recompose';
import SearchIcon from '@mui/icons-material/Search';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { ShippingClient } from 'clients/shipping-client';
import { buildHandleEnterKeyPress } from 'services/utils/accessibility';
import { PharmacyService } from 'services/utils/pharmacy-service';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { windowFeatureIsEnabled } from 'config/window-features';
import { useTimeoutFn } from 'hooks/useTimeoutFn';
import { isEmpty } from 'lodash';
import DeliveryInformation from './delivery-information';
import ContentsOfPackage from './contents-of-package';
import ShippingVerification, {
  DebugScanButton,
  PulsingScannerIcon,
  ShippingLabelScan,
} from './shipping-verification';
import { DeliveryMethodValue } from '../../constants/enums';
import PrintShippingButtons from './print-shipping-buttons';
import PaymentInformation from './payment-information';
import { getShippingScanStatus, isShippingPage, printLabelDetails } from './utils';
import { ComponentState, PackageLabelScanStatus, ShippingLabelScanStatus } from './types';
import { ShippingStyles } from './shipping.styles';
import PickupShippingButtons from './pickup-shipping-buttons';
import { useLabelScan } from './use-shipping-scan';
import { FDC, TASK_LIFECYCLE_ACTIONS } from '../../constants';
import { TypedHttp as HTTP } from '../../services/typed-http';
import { defaultTheme } from '../../lib/themes';

type IShippingProps = WithStyles<typeof ShippingStyles>;
interface IStateProps {
  initialValues: '';
}
const initialSearchTerm = '';
type Props = IStateProps & IShippingProps;

const qaIdBuilder = buildQaId('shipping');

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    backgroundColor: defaultTheme.palette.primary.bluesky,
    marginBottom: 20,
    minWidth: 600,
  },
  btnContainer: {
    marginTop: 30,
  },
  btn: {
    marginRight: 10,
  },
}));

const ModalForm = (props: any): JSX.Element => {
  const { close, title, instructions, clear } = props;
  const classes = useStyles();
  return (
    <Dialog open onClose={close} maxWidth="md">
      <DialogTitle id="simple-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{instructions}</Typography>
        <Grid container className={classes.btnContainer}>
          <Grid item xs={6}>
            <Button className={classes.btn} onClick={close} variant="outlined">
              Yes
            </Button>
            <Button variant="contained" onClick={clear}>
              No
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export function Shipping(props: IShippingProps): JSX.Element {
  const { classes } = props;
  const dispatch = useDispatch();
  const [pharmacyService] = React.useState<PharmacyService>(new PharmacyService());
  const [orderIdSearchValue, setOrderIdSearchValue] = React.useState<string>(initialSearchTerm);
  const [currentOrderIdValue, setCurrentOrderIdValue] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataState, setDataState] = React.useState<ComponentState>();
  const [isLabLogistics, setIsLabLogistics] = React.useState<boolean>(false);
  const [isSpokeLogistics, setIsSpokeLogistics] = React.useState<boolean>(false);
  const [isAssociatedCourier, setIsAssociatedCourier] = React.useState<boolean>(false);
  const [isNuVizzCourier, setIsNuVizzCourier] = React.useState<boolean>(false);
  const orderIdInputRef = React.useRef<HTMLInputElement>(null);
  const packageButtonRef = React.useRef<HTMLDivElement>(null);
  const [showDuplicateOrderNotification, setShowDuplicateOrderNotification] =
    React.useState<boolean>(false);

  const currentMedicationInformation = dataState?.therapiesNames;
  const currentAncillarySupply = dataState?.ancillarySupplies;
  const currentDeliveryInformation = dataState?.deliveryInformation;
  const currentPrintInformation = dataState?.printInformation;
  const currentPaymentInformation = dataState?.paymentInformation;
  const currentFdcInformation = dataState?.fdcInformation;

  const isCurrentMethodOfDeliveryUps =
    currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.Ups;
  const isCurrentMethodOfDeliveryCourier =
    currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.Courier;
  const isDelivery =
    isCurrentMethodOfDeliveryUps ||
    (isCurrentMethodOfDeliveryCourier && isLabLogistics) ||
    (isCurrentMethodOfDeliveryCourier && isSpokeLogistics) ||
    (isCurrentMethodOfDeliveryCourier && isAssociatedCourier) ||
    (isCurrentMethodOfDeliveryCourier && isNuVizzCourier);

  const isDispense = currentFdcInformation?.statusId === FillDeliveryConfirmationStatus.Dispense;
  const isPickup = currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.PickUp;
  const willPickUp = currentFdcInformation?.statusId === FillDeliveryConfirmationStatus.WillPickUp;
  const isPacked = currentFdcInformation?.statusId !== FillDeliveryConfirmationStatus.Packed;

  const isShippingLabelPrinted =
    (!isDelivery && !isPickup) || (isDelivery && isPacked) || (isPickup && willPickUp);

  const users = useTypedSelector(state => state.lookups.users);
  const printedDate = currentPrintInformation?.created_dt;
  const createUserDisplayName = users.find(
    x => x.id === currentPrintInformation?.created_by,
  )?.display_name;
  const printDetails = printLabelDetails(createUserDisplayName, printedDate);
  const shippingBarcodeScanEnabled = windowFeatureIsEnabled('shipping_verification');

  const resetPageAfterScanComplete = useTimeoutFn(() => {
    if (!isPickup) setOrderIdSearchValue('');
    setCurrentOrderIdValue('');
    scanProps.setOrderId('');
    orderIdInputRef.current?.focus?.();
  });

  const highlightOrderIdInput = useTimeoutFn(() => {
    orderIdInputRef.current?.select?.();
  });

  const clear = () => {
    setOrderIdSearchValue(initialSearchTerm);
    setCurrentOrderIdValue(null);
  };

  useEffect(() => {
    if (!isEmpty(currentOrderIdValue) || currentOrderIdValue !== null) {
      ShippingClient.fetchScanHistory(currentOrderIdValue || '').then(result => {
        const shippingScanStatus = getShippingScanStatus(result.data.history);
        setShowDuplicateOrderNotification(shippingScanStatus === ShippingLabelScanStatus.Complete);
      });
    }
  }, [currentOrderIdValue]);

  const getFCData = useCallback(
    async (orderId: string) => {
      if (orderId == null || orderId === '') {
        dispatch(notifyError('Please enter an Order ID.'));
        return;
      }
      try {
        setCurrentOrderIdValue(null);
        setLoading(true);
        const response = await ShippingClient.fetch(orderId, 'shipping');
        if (response.data.error) {
          setCurrentOrderIdValue(null);
          dispatch(notifyError(response.data.error));
          highlightOrderIdInput.start(200);
        } else {
          setDataState(response.data);
          const currentDispensingPharmacyNpi =
            response.data?.deliveryInformation?.dispensingPharmacyNpi;
          if (currentDispensingPharmacyNpi) {
            const pharmacyResponse = await pharmacyService.getPharmacyByNpi(
              currentDispensingPharmacyNpi,
            );
            setIsLabLogistics(pharmacyResponse?.lab_logistics_customer_id !== null);
            setIsSpokeLogistics(pharmacyResponse?.spoke_logistics_customer_id !== null);
            setIsAssociatedCourier(pharmacyResponse?.associated_courier_customer_id !== null);
            setIsNuVizzCourier(pharmacyResponse?.nuvizz_courier_customer_id !== null);
          }
          setCurrentOrderIdValue(orderId);
        }
      } catch (_error) {
        setCurrentOrderIdValue(null);
        dispatch(notifyError('Order ID does not exist. Please try again.'));
        highlightOrderIdInput.start(200);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, highlightOrderIdInput, pharmacyService],
  );

  const handleChange = React.useCallback(
    (inputValue: string) => {
      setOrderIdSearchValue(inputValue);
    },
    [setOrderIdSearchValue],
  );

  const handleEnter = React.useCallback(
    (value?: string) => {
      const inputValue = value ?? orderIdSearchValue;
      const orderId =
        shippingBarcodeScanEnabled && inputValue.endsWith('-FC')
          ? inputValue.split('-FC')[0]
          : inputValue;
      if (!loading) {
        getFCData(orderId);
      }
    },
    [getFCData, loading, orderIdSearchValue, shippingBarcodeScanEnabled],
  );

  const scanProps = useLabelScan({
    async onOrderScan(orderId, labelValue, historyItem) {
      // When an order id is scanned, request the order data
      setOrderIdSearchValue(labelValue);
      handleEnter(labelValue);
    },
    async onShippingScan(orderId, labelValue, historyItem) {
      // When the shipping scan completes...
      if (historyItem.status_message === 'ok') {
        resetPageAfterScanComplete.start(400);
      }
    },
  });

  React.useEffect(() => {
    // After the order data is requested and has a valid response,
    // the `currentOrderIdValue` is set. This also sets the order id reference
    // in the label scan hook.
    if (!shippingBarcodeScanEnabled) return;
    if (currentOrderIdValue && currentOrderIdValue !== scanProps.orderId) {
      scanProps.setOrderId(currentOrderIdValue);
    }
    if (!currentOrderIdValue) {
      scanProps.setOrderId(null);
    }
  }, [shippingBarcodeScanEnabled, currentOrderIdValue, scanProps]);

  // If the order that was scanned is already complete, highlight the order number id so they can
  // keep scanning
  const [updatingFdc, setUpdatingFdc] = React.useState(false);
  React.useEffect(() => {
    if (!shippingBarcodeScanEnabled) return;
    if (
      !!currentOrderIdValue &&
      currentOrderIdValue === scanProps.orderId &&
      scanProps.packageScanStatus === PackageLabelScanStatus.Complete &&
      scanProps.shippingScanStatus === ShippingLabelScanStatus.Complete
    ) {
      highlightOrderIdInput.start(200);
      return;
    }
    if (
      !!currentOrderIdValue &&
      currentOrderIdValue === scanProps.orderId && // order is loaded
      isPickup && // its a pickup
      !willPickUp && // it hasnt beebn marked as "Will Pick Up" yet
      scanProps.orderScanCompleted && // The order scan was saved
      currentFdcInformation && // We have the info we need to update FDC
      !updatingFdc // It's not already updating
    ) {
      (async () => {
        try {
          setUpdatingFdc(false);
          const updatedTask = {
            id: currentFdcInformation?.id,
            taskType: FDC,
            therapy_id: currentFdcInformation?.therapyId,
            status_id: FillDeliveryConfirmationStatus.WillPickUp,
          };
          const patchUrl = `/tasks/fdc/${updatedTask.id}`;
          const res = await HTTP.PatchPromise(patchUrl, updatedTask);
          dispatch({
            type: (TASK_LIFECYCLE_ACTIONS.EDIT as any).FILL_DELIVERY_CONFIRMATION,
            payload: res,
          });
          dispatch(notifySuccess('FDC updated successfully.'));
          resetPageAfterScanComplete.start(400);
        } catch (error) {
          dispatch(notifyError('Unable to pick up.'));
        } finally {
          setUpdatingFdc(false);
        }
      })();
    }
    if (isPickup) {
      highlightOrderIdInput.start(400);
    }
  }, [
    shippingBarcodeScanEnabled,
    currentOrderIdValue,
    scanProps.orderId,
    scanProps.packageScanStatus,
    scanProps.shippingScanStatus,
    scanProps.orderScanCompleted,
    currentFdcInformation,
    highlightOrderIdInput,
    isPickup,
    willPickUp,
    setUpdatingFdc,
    updatingFdc,
    dispatch,
    resetPageAfterScanComplete,
  ]);

  React.useEffect(() => {
    scanProps.setPackageScanRequirements(currentDeliveryInformation);
  }, [currentDeliveryInformation, scanProps.setPackageScanRequirements]);

  const showOrderIdScanPulse = React.useMemo(() => {
    if (!shippingBarcodeScanEnabled) return false;
    if (isPickup && scanProps.orderScanCompleted) return true;
    return (
      !currentOrderIdValue || scanProps.shippingScanStatus === ShippingLabelScanStatus.Complete
    );
  }, [shippingBarcodeScanEnabled, currentOrderIdValue, scanProps.shippingScanStatus]);

  const disableOrderIdInput = React.useMemo(() => {
    if (!shippingBarcodeScanEnabled) return false;
    if (isPickup) return false;
    return (
      !!currentOrderIdValue &&
      ((isPickup &&
        !(willPickUp && scanProps.packageScanStatus === PackageLabelScanStatus.Complete)) ||
        (isDelivery &&
          !(
            isPacked &&
            scanProps.packageScanStatus === PackageLabelScanStatus.Complete &&
            scanProps.shippingScanStatus === ShippingLabelScanStatus.Complete
          )))
    );
  }, [
    shippingBarcodeScanEnabled,
    currentOrderIdValue,
    scanProps.shippingScanStatus,
    scanProps.packageScanStatus,
    scanProps.orderScanCompleted,
    isPickup,
    willPickUp,
    isDelivery,
    isPacked,
  ]);

  React.useLayoutEffect(() => {
    if (!shippingBarcodeScanEnabled) return;
    if (disableOrderIdInput && scanProps.packageScanStatus === PackageLabelScanStatus.Complete) {
      const buttonEl = packageButtonRef.current?.querySelector('button');
      if (buttonEl) {
        buttonEl.focus?.();
      }
    }
  }, [shippingBarcodeScanEnabled, disableOrderIdInput, scanProps.packageScanStatus]);

  const renderOrderIdField = (): JSX.Element => {
    return (
      <div className={classes.container}>
        <Grid item xs={3}>
          <Typography className={classes.title}>Shipping Order Info</Typography>
        </Grid>
        <Grid item xs={3}>
          <LoadingOverlay open={loading} />
          <TextField
            variant="standard"
            name="shipping_order_info"
            label="Order ID"
            focused
            disabled={loading || disableOrderIdInput}
            fullWidth
            value={orderIdSearchValue}
            onKeyPress={buildHandleEnterKeyPress(handleEnter)}
            onChange={event => handleChange(event.target.value)}
            onClick={() => {
              orderIdInputRef.current?.select?.();
            }}
            placeholder={shippingBarcodeScanEnabled ? 'XXXXXXX-FC' : 'XXXXXXXXXXXXXXXX'}
            autoFocus={showOrderIdScanPulse}
            autoComplete="off"
            inputRef={orderIdInputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.searchIcon}
                  disablePointerEvents={loading}
                  onClick={() => {
                    getFCData(orderIdSearchValue);
                  }}
                >
                  {!shippingBarcodeScanEnabled ? <SearchIcon /> : null}
                  {showOrderIdScanPulse ? <PulsingScannerIcon /> : null}
                </InputAdornment>
              ),
            }}
            data-qa-id={qaIdBuilder('order-id-field')}
          />
        </Grid>
      </div>
    );
  };

  const disableShippingButton =
    isShippingPage() && shippingBarcodeScanEnabled
      ? scanProps.packageScanStatus !== PackageLabelScanStatus.Complete
      : undefined;

  const displayButtons = (orderId: string): JSX.Element => {
    return (
      <div ref={packageButtonRef}>
        {true && (
          <PrintShippingButtons
            currentDeliveryInformation={currentDeliveryInformation}
            currentPrintInformation={currentPrintInformation}
            orderId={orderId}
            printDetails={printDetails}
            currentFdcInformation={currentFdcInformation}
            disabled={disableShippingButton}
            statusId={currentFdcInformation?.statusId}
          />
        )}
        {isPickup && (
          <PickupShippingButtons
            currentDeliveryInformation={currentDeliveryInformation}
            currentPrintInformation={currentPrintInformation}
            orderId={orderId}
            printDetails={printDetails}
            currentFdcInformation={currentFdcInformation}
            disabled={false}
          />
        )}
      </div>
    );
  };

  const orderCcCount = React.useMemo(() => {
    if (scanProps.orderId === currentOrderIdValue) {
      return currentDeliveryInformation?.ccPackageCount;
    }
    return undefined;
  }, [currentOrderIdValue, currentDeliveryInformation, scanProps.orderId]);

  const orderNrCount = React.useMemo(() => {
    if (scanProps.orderId === currentOrderIdValue) {
      return currentDeliveryInformation?.nrPackageCount;
    }
    return undefined;
  }, [currentOrderIdValue, currentDeliveryInformation, scanProps.orderId]);

  const renderShippingInformation = (orderId: string): JSX.Element => {
    return (
      <div className={classes.container}>
        {showDuplicateOrderNotification && (
          <ModalForm
            title="Caution"
            instructions="This order has already been completed, are you sure you want to continue ?"
            close={() => setShowDuplicateOrderNotification(false)}
            clear={() => clear()}
          />
        )}
        <ContentsOfPackage
          currentMedicationInformation={currentMedicationInformation}
          currentAncillarySupply={currentAncillarySupply}
          isPickup={isPickup}
          scanProps={shippingBarcodeScanEnabled ? scanProps : undefined}
        />
        <PaymentInformation currentPaymentInformation={currentPaymentInformation} />
        {!showDuplicateOrderNotification && shippingBarcodeScanEnabled && !isPickup ? (
          <ShippingVerification
            {...scanProps}
            currentAncillarySupply={currentAncillarySupply}
            orderCcCount={orderCcCount}
            orderNrCount={orderNrCount}
          />
        ) : null}
        <Grid className={classes.deliveryInformation}>
          <DeliveryInformation
            currentDeliveryInformation={currentDeliveryInformation}
            currentPrintInformation={currentPrintInformation}
            currentFdcInformation={currentFdcInformation}
            scanProps={shippingBarcodeScanEnabled ? scanProps : undefined}
          />
        </Grid>
        {isDispense ? null : displayButtons(orderId)}
        {shippingBarcodeScanEnabled && !isPickup ? (
          <Grid
            component={ShippingLabelScan}
            {...scanProps}
            sx={{ padding: '48px 0' }}
            isShippingLabelPrinted={isShippingLabelPrinted}
          />
        ) : null}
      </div>
    );
  };

  return (
    <>
      {renderOrderIdField()}
      {currentOrderIdValue && renderShippingInformation(currentOrderIdValue)}
      {shippingBarcodeScanEnabled && <DebugScanButton />}
    </>
  );
}

export default compose<Props, IShippingProps>(
  withStyles(ShippingStyles),
  reduxForm({ form: 'shipping' }),
)(Shipping);
