import React from 'react';
import { getStatusByStatusId, getReasonsByStatus } from 'services/utils/task-service';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import { PA } from 'constants/index';

export default ({ providers, label, input, meta, disabled, labelSize, qaId }) => {
  const { statuses, formValues } = providers;
  const reasons = getReasonsByStatus(
    statuses,
    getStatusByStatusId(formValues.status_id, statuses),
  ).filter(it => it.label);

  if (
    !meta.visited &&
    providers.task.taskType === PA &&
    !reasons.find(r => r.value === providers.task.status_id) &&
    [1, 2].includes(providers.task.status_category_id)
  ) {
    input.value = '';
  } else if (
    !meta.visited &&
    providers.task.taskType === PA &&
    reasons.find(r => r.value === providers.task.status_id) &&
    [3, 4].includes(providers.task.status_category_id)
  ) {
    input.value = providers.task.status_id;
  }

  // Disable 'Denied - Criteria Not Met' for PA
  if (providers.task.taskType === PA) {
    const reasonsIds = [2004, 2022];
    reasonsIds.forEach(id => {
      const reason = reasons.find(r => r.value === id);
      if (reason) {
        reason.disabled = true;
      }
    });
  }

  return (
    <RenderDropdown
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      id={qaId}
      fields={reasons}
    />
  );
};
