/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import classNames from 'classnames';
import { Grid, Typography, Checkbox, FormControl, FormGroup, FormHelperText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './single-field-checkbox-group.styles';
import { IProps, IOption } from './interfaces/IProps';

function SingleValueCheckboxGroup(props: IProps) {
  const {
    classes,
    label,
    meta: { touched, error },
    input: { onChange, value },
    options,
    formGroupProps,
    layoutProps,
    itemProps,
    additionalProps,
  } = props;

  const { smallFieldLabel } = additionalProps || {};
  const [values, setValues] = React.useState(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (checked) {
      const newValues = [values, name].filter(item => !!item).join(',');
      onChange(newValues);
      setValues(newValues);
      return;
    }

    const newValues = values
      .split(',')
      .filter((item: typeof value) => item !== name)
      .join(',');

    onChange(newValues);
    setValues(newValues);
  };
  return (
    <FormControl error={!!error}>
      {label && (
        <Typography
          className={classNames(classes.fieldLabel, { [classes.smallFieldLabel]: smallFieldLabel })}
        >
          {label}{' '}
          {touched && error && (
            <FormHelperText component="span" className={classes.helperText}>
              {error}
            </FormHelperText>
          )}
        </Typography>
      )}
      <FormGroup row className={classes.fieldWrapper} {...formGroupProps}>
        {options.map((option: IOption) => {
          const { endAdornment, ...checkbox_props } = itemProps[option.value];
          return (
            <Grid {...layoutProps} key={option.value}>
              <div className={classes.checkboxWrapper}>
                <Checkbox onChange={handleChange} name={option.value} {...checkbox_props} />
                <Typography variant="caption" className={classes.checkBoxLabel}>
                  {option.label}
                </Typography>
                {endAdornment}
              </div>
            </Grid>
          );
        })}
      </FormGroup>
    </FormControl>
  );
}

export default withStyles(styles)(SingleValueCheckboxGroup);
