import React from 'react';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { CheckCircle, Cancel, Help, Info, RemoveCircle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { PatientStyles } from './patient.styles';

const optInStates = {
  noPhoneListed: 'SMS Opt-in, no mobile phones listed',
  phoneStatusNotSpecified: 'SMS Opt-in status has not been chosen',
  optInNotSent: 'SMS Opt-in, opt-in message has not been sent',
  optInSentWaiting: 'SMS Opt-in - Waiting for patient confirmation',
  optIn: 'SMS Opt-in',
  optOut: 'SMS Opt-out',
} as const;

const Ok = React.forwardRef((props: any, ref: any) => (
  <svg
    {...props}
    ref={ref}
    style={{ paddingBottom: '2px' }}
    width="25"
    height="25"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0001 18.3333C15.0502 18.3333 18.3334 15.0501 18.3334 11C18.3334 6.94991 15.0502 3.66666 11.0001 3.66666C6.94999 3.66666 3.66675 6.94991 3.66675 11C3.66675 15.0501 6.94999 18.3333 11.0001 18.3333ZM6 10.8077L9.49206 14.3334L15.7778 7.98722L14.8 7.00004L9.49206 12.359L6.97778 9.82055L6 10.8077Z"
      fill="#5BA389"
    />
    <path
      d="M14.9025 16.7275L16.4775 15.1525L18.0525 16.7275L18.7275 16.0525L17.175 14.5L18.75 12.925L18.075 12.25L16.5 13.825L14.925 12.25L14.25 12.925L15.825 14.5L14.25 16.075L14.9025 16.7275ZM16.5 19C15.255 19 14.1938 18.5612 13.3163 17.6838C12.4388 16.8063 12 15.745 12 14.5C12 13.255 12.4388 12.1938 13.3163 11.3163C14.1938 10.4388 15.255 10 16.5 10C17.745 10 18.8063 10.4388 19.6838 11.3163C20.5612 12.1938 21 13.255 21 14.5C21 15.745 20.5612 16.8063 19.6838 17.6838C18.8063 18.5612 17.745 19 16.5 19Z"
      fill="#B5302C"
    />
    <path
      d="M16.475 15.2412L14.725 17L14 16.2714L15.75 14.5126L14 12.7538L14.75 12L16.5 13.7588L18.25 12L19 12.7538L17.25 14.5126L18.975 16.2462L18.225 17L16.475 15.2412Z"
      fill="white"
    />
  </svg>
));

const Cycle = React.forwardRef((props: any, ref: any) => {
  return (
    <div
      {...props}
      ref={ref}
      style={{ display: 'inline-block', width: '22px', verticalAlign: 'super' }}
    >
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.8875 15.8438C4.5875 15.2437 3.53125 14.3375 2.71875 13.125C1.90625 11.9125 1.5 10.5438 1.5 9.01875C1.5 8.69375 1.51562 8.375 1.54688 8.0625C1.57812 7.75 1.63125 7.44375 1.70625 7.14375L0.84375 7.65L0.09375 6.35625L3.675 4.29375L5.7375 7.85625L4.425 8.60625L3.4125 6.84375C3.275 7.18125 3.17188 7.53125 3.10313 7.89375C3.03438 8.25625 3 8.63125 3 9.01875C3 10.2313 3.33125 11.3344 3.99375 12.3281C4.65625 13.3219 5.5375 14.0562 6.6375 14.5312L5.8875 15.8438ZM11.625 6.75V5.25H13.6688C13.0938 4.5375 12.4 3.98438 11.5875 3.59063C10.775 3.19688 9.9125 3 9 3C8.3125 3 7.6625 3.10625 7.05 3.31875C6.4375 3.53125 5.875 3.83125 5.3625 4.21875L4.6125 2.90625C5.2375 2.46875 5.91875 2.125 6.65625 1.875C7.39375 1.625 8.175 1.5 9 1.5C9.9875 1.5 10.9313 1.68438 11.8313 2.05313C12.7313 2.42188 13.5375 2.95625 14.25 3.65625V2.625H15.75V6.75H11.625ZM11.1375 18L7.55625 15.9375L9.61875 12.375L10.9125 13.125L9.84375 14.9625C11.3188 14.75 12.5469 14.0813 13.5281 12.9563C14.5094 11.8313 15 10.5125 15 9C15 8.8625 14.9969 8.73438 14.9906 8.61563C14.9844 8.49688 14.9688 8.375 14.9438 8.25H16.4625C16.475 8.375 16.4844 8.49688 16.4906 8.61563C16.4969 8.73438 16.5 8.8625 16.5 9C16.5 10.6875 15.9969 12.1969 14.9906 13.5281C13.9844 14.8594 12.675 15.7563 11.0625 16.2188L11.8875 16.7062L11.1375 18Z"
          fill="#5BA389"
        />
      </svg>
    </div>
  );
});

type OptInStates = (typeof optInStates)[keyof typeof optInStates];

interface IconWithTooltipProps extends WithStyles<typeof PatientStyles> {
  state:
    | 'no_payment_on_file'
    | 'active_payment_on_file'
    | 'all_payment_methods_expired'
    | 'payment_methods_expiring_next_month'
    | 'no_mobile_numbers'
    | 'sms_not_specified'
    | 'sms_opt_out'
    | 'patient_sent_stop'
    | 'opt_in_message_not_sent'
    | 'opt_in_sent_waiting_patient_response'
    | 'patient_replied_to_opt_in_message'
    | 'sms_only'
    | 'manual_secure_link'
    | 'auto_secure_link'
    | 'provided'
    | 'notProvided'
    | 'iconInactive'
    | 'yellow'
    | 'red';
  tooltip: string | any;
}
function iconWithTooltip({
  state = 'provided',
  tooltip = '',
  classes,
}: IconWithTooltipProps): JSX.Element {
  const renderIcon = () => {
    switch (state) {
      case 'no_payment_on_file':
        return <Cancel className={classes.iconInactive} />;
      case 'active_payment_on_file':
        return <CheckCircle className={classes.provided} />;
      case 'all_payment_methods_expired':
        return <Cancel className={classes.notProvided} />;
      case 'payment_methods_expiring_next_month':
        return <Cancel className={classes.yellow} />;
      case 'no_mobile_numbers':
        return <RemoveCircle className={classes.noMobileNumbers} />;
      case 'sms_not_specified':
        return <Help className={classes.iconInactive} />;
      case 'patient_sent_stop':
      case 'sms_opt_out':
        return <Cancel className={classes.red} />;
      case 'opt_in_message_not_sent':
      case 'opt_in_sent_waiting_patient_response':
        return <CheckCircle className={classes.yellow} />;
      case 'sms_only':
        return <Ok />;
      case 'manual_secure_link':
        return <CheckCircle className={classes.green} />;
      case 'auto_secure_link':
        return <Cycle />;
      default:
        return <></>;
    }
  };
  return <Tooltip title={tooltip}>{renderIcon()}</Tooltip>;
}

export default withStyles(PatientStyles)(iconWithTooltip);
