import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Field, unregisterField, change } from 'redux-form';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { UPLOAD_DOC_FILENAME_FIELD, DUR } from 'constants/index';

import { uploadFile } from 'services/utils/upload-document-service';
import {
  getDocumentsForResource,
  getFileExtension,
  acceptedFileExtensions,
} from 'services/utils/document-service';

import { ConditionalWrapper } from 'utils/conditional-wrapper';
import { getRenderer } from './render';
import { getFormLabel, getValidators, generateQAID, getFieldDisabled } from './helpers';

const dummyOnChangeHandler = () => {};

const useStyles = makeStyles(theme => {
  const wrapperFieldNoSpacingBase = {
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  };

  return {
    wrapperField: {
      padding: theme.spacing(1),
    },
    wrapperFieldNoSpacing: {
      ...wrapperFieldNoSpacingBase,
      textAlign: 'center',
    },
    wrapperFieldNoSpacingLeftAlignment: {
      ...wrapperFieldNoSpacingBase,
      textAlign: 'left',
    },
    wrapperFieldNoPadding: {
      padding: 0,
    },
    detailHeader: {
      color: theme.palette.primary.formLabel,
    },
    componentWrapper: {
      flex: 1,
    },
    helpIcon: {
      verticalAlign: 'middle',
      margin: '5px 10px',
    },
  };
});

const NewLineWrapper = ({ children, xs }) => (
  <Grid item xs={xs || 12}>
    {children}
  </Grid>
);

const InnerContainer = ({ children, justify }) => (
  <Grid container justifyContent={justify}>
    {children}
  </Grid>
);

const FieldWrapper = ({ field, children }) => {
  const classes = useStyles();
  const gridSize = field.gridSize || 12;
  const { justify } = field;

  const Wrapper = field.newLine ? NewLineWrapper : Fragment;

  return (
    <Wrapper>
      <Grid
        item
        xs={gridSize}
        className={{
          [field.noSpace]:
            field.noSpace && field.leftAlignment && classes.wrapperFieldNoSpacingLeftAlignment,
          [classes.wrapperField]: !field.noSpace || !field.leftAlignment,
          [classes.wrapperFieldNoSpacing]:
            !field.noSpace && !field.leftAlignment && !classes.wrapperFieldNoSpacingLeftAlignment,
          [classes.wrapperFieldNoPadding]: field.noPadding,
        }}
      >
        <ConditionalWrapper
          condition={justify && field.innerGridSize}
          wrapper={innerChildren => (
            <InnerContainer justify={justify}>{innerChildren}</InnerContainer>
          )}
        >
          <ConditionalWrapper
            condition={justify && field.innerGridSize}
            wrapper={innerChildren => (
              <NewLineWrapper xs={field.innerGridSize}>{innerChildren}</NewLineWrapper>
            )}
          >
            {children}
            {field.tooltip && (
              <Tooltip title={field.tooltip} placement="top-end" className={classes.helpIcon}>
                <InfoIcon />
              </Tooltip>
            )}
          </ConditionalWrapper>
        </ConditionalWrapper>
      </Grid>
    </Wrapper>
  );
};

export default props => {
  const {
    providers,
    field,
    initialValues,
    formId,
    tagResourceId,
    tagTypeId,
    tagName,
    fileUploadFormId,
    taskId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    notes,
    drawBorder,
    additionalDocumentProps,
    taskIsSubmitting,
  } = props;

  const dispatch = useDispatch();

  // unregister field on unmount
  useEffect(
    () => () => {
      dispatch(unregisterField(formId, field.property));
    },
    [true],
  );

  const events = {
    onChange: dummyOnChangeHandler,
  };

  if (providers.events && providers.events[field.id]) {
    const customEvents = providers.events[field.id];
    Object.keys(customEvents).forEach(event => {
      if (event === 'onChange') {
        events[event] = (...args) => {
          customEvents[event](...args, providers.formValues, formId);
        };
      } else {
        events[event] = customEvents[event];
      }
    });
  }

  const handleChange = useCallback(
    value => {
      if (field.onChange) {
        field.onChange(value);
      }
      return events.onChange(field, value);
    },
    [events.onChange, field],
  );

  const isDurFormField = formId.includes(DUR);

  let uploadProps = {};
  let documentBarProps = {};

  if (isDurFormField) {
    const [pendingDocument, setPendingDocument] = useState(undefined);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [uploadProcessing, setUploadProcessing] = useState(false);
    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
    const defaultDocumentLabels = useSelector(state => state.lookups.defaultDocumentLabels);

    const handleFileUploadCancel = () => setPendingDocument(undefined);
    const selectedPatientId = useSelector(state => state.selectedPatientId);
    const selectedCustomerId = useSelector(state => state.filters.selectedCustomerId);
    const documents = useSelector(state => state.uploadedDocuments.documents);

    const handleFileUploadSubmit = () => {
      setUploadProcessing(true);

      if (pendingDocument) {
        uploadFile(
          pendingDocument.file,
          `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
          selectedCustomerId,
          selectedPatientId,
        )
          .then(result => {
            setPendingDocument(undefined);
            setUploadProcessing(false);
            setUploadedDocuments([
              ...uploadedDocuments,
              {
                uuid: result.uuid,
                taskTypeId: tagTypeId,
                taskIds: [taskId],
                filename: `${fileFormName}.${pendingDocument.fileExtension}`,
                labels: fileFormTypes.split(','),
                note: fileFormNote,
              },
            ]);
          })
          .catch(() => {
            setFileUploadErrorMessage('File could not be uploaded. Try again.');
          });
      }
    };

    const changeField = (formName, fieldName, value) => {
      dispatch(change(formName, fieldName, value));
    };

    const fileSelected = event => {
      if (event && event.length > 0) {
        const selectedFile = event[0];
        const { filePrefix, fileExtension } = getFileExtension(selectedFile.name);
        if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
          changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);

          setPendingDocument({
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          });
        }
      }
    };

    uploadProps = {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      defaultDocumentLabels,
      handleFileUploadSubmit,
      handleFileUploadCancel,
      fileSelected,
      fileUploadFormId,
      resourceId: 1,
      taskIsSubmitting,
    };

    const filteredDocuments =
      (documents &&
        getDocumentsForResource(
          documents,
          [{ tagTypeId, resourceId: providers && providers.task ? providers.task.id : null }],
          1,
        )) ||
      [];

    documentBarProps = {
      documents: filteredDocuments,
      taskIdResourceIds: [
        { tagTypeId, resourceId: providers && providers.task ? providers.task.id : null },
      ],
      tagTypeLabel: tagName,
      drugName: '',
    };
  }

  const Component = getRenderer(field);
  const disabled = getFieldDisabled(providers, field);
  return (
    <FieldWrapper field={field}>
      {field.property && (
        <Field
          name={field.property}
          label={getFormLabel(providers, field)}
          component={Component}
          validate={getValidators(providers, field, disabled)}
          onChange={handleChange}
          qaId={generateQAID(field, providers)}
          providers={providers}
          field={field}
          disabled={disabled}
          initialValues={initialValues}
          formId={formId}
          tagResourceId={tagResourceId}
          tagTypeId={tagTypeId}
          tagName={tagName}
          notes={notes}
          notesForm={notes}
          drawBorder={drawBorder}
          displayOnly={field.displayOnly}
          {...uploadProps}
          {...additionalDocumentProps}
          {...documentBarProps}
        />
      )}
      {!field.property && (
        <Component
          label={getFormLabel(providers, field)}
          validate={getValidators(providers, field, disabled)}
          onChange={handleChange}
          qaId={generateQAID(field, providers)}
          providers={providers}
          field={field}
          disabled={disabled}
          initialValues={initialValues}
          formId={formId}
        />
      )}
    </FieldWrapper>
  );
};
