import { Typography } from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import { PreferredContactFactory } from './preferred-contact-factory';
import { useStyles } from './preferred-contact.styles';
import { getPatientFullName, getPreferredContactName } from './utils';

export const PreferredContact = (): JSX.Element => {
  const classes = useStyles();
  const patient = useTypedSelector(state => state.patient);

  const preferredContact = useTypedSelector(state => state.preferredContact);

  const displayFullName = preferredContact
    ? getPreferredContactName(preferredContact)
    : getPatientFullName(patient);

  return (
    <>
      <Typography className={classes.summarySectionHeader}>Preferred Contact</Typography>
      {patient.preferred_contact_method !== null ? (
        <>
          <PreferredContactFactory />
        </>
      ) : (
        <Typography>{displayFullName}</Typography>
      )}
    </>
  );
};
