import React from 'react';
import { connect, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { Grid, Typography, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  excludeScheduleDrugOutcome,
  editScheduleOutreach,
  fetchTaskCounts,
} from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderDropdownNonSimple,
  renderTextField,
  renderMultipleDropdown,
} from 'components/form/field/redux-field';
import { SmallSpacer } from 'components/spacer/spacer';
import { required } from 'components/form/validation/validation';
import {
  DISPENSE_OPT_OUT_THERAPY_FORM,
  EXCLUDE_AR_THERAPY_CDM_DISPENSING_OPT_OUT,
} from 'constants/index';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import { excludedReasons } from 'constants/lists';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { workListChanged } from 'actions/action-patient';
import { areAllDrugsExcluded } from 'services/utils/task-service';
import { styles } from './schedule-outreach-details-styles';

const inputLabelStyling = {
  marginBottom: -30,
  minHeight: 28,
};

const ScheduleOutreachTherapyDispenseOptOut = props => {
  const {
    classes,
    selectedDrugs,
    scheduleOutreachId,
    drugs,
    editScheduleOutreach, // eslint-disable-line
    workListChanged, // eslint-disable-line
    fetchTaskCounts, // eslint-disable-line
    excludeScheduleDrugOutcome, // eslint-disable-line
    setTaskStatus,
    handleClose,
    handleSubmit,
    pristine,
    submitting,
    formValues,
  } = props;

  const therapy = selectedDrugs[0];

  const listOfPharmacies = useSelector(state => state.pharmacies?.pharmacies || []);

  const therapyDispensingPharmacyReasons = useSelector(
    state => state.lookups.therapyDispensingPharmacyReasons,
  );

  const therapyDispensingPharmacyReasonsFiltered = therapyDispensingPharmacyReasons.filter(
    r => r.value !== 3 && r.value !== 4,
  );

  const getExcludedReasonFromFormValues = (formValues, drugId) => {
    const reason = formValues[`${drugId}_reason`];
    if (reason === 'Other') {
      const otherReason = formValues[`${drugId}_other_reason`];
      return `${reason}: ${otherReason}`;
    }
    return reason;
  };

  const handleFetchInternalPharmacyOptions = React.useMemo(() => {
    const internalPharmaciesFromLookup = listOfPharmacies.length ? listOfPharmacies : [];
    return internalPharmaciesFromLookup.map(pharmacy => ({
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    }));
  }, [listOfPharmacies]);

  const handleDispensingReason = (_e, val) => {
    if (props.onValueChange) {
      props.onValueChange('external_dispensing_reason', val);
    }
  };

  const onSubmit = values => {
    // If all drugs are excluded, set status to 'No Valid Therapies

    const selectedDrugIds = selectedDrugs.map(d => d?.id);
    const notSelectedDrugs = drugs.filter(d => !selectedDrugIds.includes(d?.id));
    const allDrugsExcluded = areAllDrugsExcluded(notSelectedDrugs);
    if (allDrugsExcluded) {
      setTaskStatus('Spoke with Patient'); // set status to ScheduleOutreachDetails local state
      workListChanged();
      fetchTaskCounts();
    }

    // Construct the payload from the form values

    const payload = {
      drugs: selectedDrugs.map(selectedDrug => {
        const drugId = selectedDrug.id;
        const excludedReason = values.external_dispensing_reason;
        return {
          id: drugId,
          excluded_reason: excludedReason,
          exclude_id: EXCLUDE_AR_THERAPY_CDM_DISPENSING_OPT_OUT,
        };
      }),
    };

    handleClose();

    return excludeScheduleDrugOutcome(payload).then(() => {
      handleClose();
    });
  };

  const renderDrug = drug => {
    const drugId = drug.id;
    const reasonField = `${drugId}_reason`;
    const otherReasonField = `${drugId}_other_reason`;
    return (
      <Grid container>
        <Grid item>
          <SmallSpacer />
          <Typography
            variant="subtitle1"
            id={`${drugId}_drug_name`}
            className={classes.excludedDrugLabel}
          >
            {`${drug.drug_name} ${drug.strength || ''}`}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <Field
                name="external_dispensing_reason"
                label="Outside Dispensing Pharmacy Reason *"
                onChange={handleDispensingReason}
                multiple
                width="90"
                component={renderMultipleDropdown}
                fields={therapyDispensingPharmacyReasonsFiltered}
                validate={[required]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.excludedFormDispensingOptOut}
      autoComplete="off"
    >
      <Grid item>
        <Typography variant="h6" id="decline_header">
          Decline Therapies
        </Typography>
        <Divider />
      </Grid>
      {selectedDrugs.map(selectedDrug => renderDrug(selectedDrug))}
      <Grid item xs={12}>
        <ConfirmationPanel
          handleCancel={handleClose}
          disableSubmit={pristine || submitting}
          buttonIdPrefix="submit_exclude_drug"
        />
      </Grid>
    </form>
  );
};

const mapStateToProps = state => {
  const { patient } = state;
  const initialValues = { external_dispensing_reason: '7' };
  const formName = DISPENSE_OPT_OUT_THERAPY_FORM;
  const formValues = getFormValues(formName)(state) || initialValues;
  return {
    patientId: patient.id,
    formValues,
    form: formName,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    excludeScheduleDrugOutcome,
    editScheduleOutreach,
    fetchTaskCounts,
    workListChanged,
  }),
)(reduxForm({})(ScheduleOutreachTherapyDispenseOptOut));
