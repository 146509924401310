import React, { FC, useState } from 'react';
import { Edit as EditIcon } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SecondaryTitleBar from 'components/secondary-title-bar/secondary-title-bar';
import RichTextEditor, { StandardFeatures, EditorValueFormat } from '../rich-text-editor';
import { IndexedDrugSections, IProps, EditableSection } from './interfaces';
import { styles } from './styles';
import TherapySubTitle from '../therapy-subtitle/therapy-subtitle';

const EditableTextSection: FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  // Holding the editing status of the main content and sections
  const [editingContent, setEditingContent] = useState<boolean>(false);
  const [editingSecondaryContent, setEditingSecondaryContent] = useState<boolean>(false);
  const [editingSections, setEditingSections] = useState<IndexedDrugSections<boolean>>({});

  // Holding the content of the main and secondary content
  const [content, setContent] = useState<string | undefined>(props.initialContent);
  const [secondaryContent, setSecondaryContent] = useState<string | undefined>(
    props.initialSecondaryContent,
  );

  const [contentNeedsUpdate, setContentNeedsUpdate] = useState<boolean>(false);
  const [secondaryContentNeedsUpdate, setSecondaryContentNeedsUpdate] = useState<boolean>(false);
  const [sectionContentNeedsUpdate, setSectionContentNeedsUpdate] = useState<
    IndexedDrugSections<boolean>
  >({});

  React.useEffect(() => {
    setContent(props.initialContent);
  }, [props.initialContent]);

  React.useEffect(() => {
    setSecondaryContent(props.initialSecondaryContent);
  }, [props.initialSecondaryContent]);

  React.useEffect(() => {
    if (props.saveContentUpdatesTrigger) {
      setContentNeedsUpdate(true);
      setSecondaryContentNeedsUpdate(true);
      // setSectionContentNeedsUpdate with all drugs, all sections
      const drugSubSectionKeys = Object.keys(props.drugSubSections ?? {});
      const allSectionsNeedingUpdate: IndexedDrugSections<boolean> = {};
      if (drugSubSectionKeys.length > 0) {
        drugSubSectionKeys.forEach((drugKey, drugIndex) => {
          Object.keys(props.drugSubSections?.[drugKey] ?? {}).forEach(
            (_subSectionKey, subSectionIndex) => {
              if (allSectionsNeedingUpdate[drugIndex] === undefined) {
                allSectionsNeedingUpdate[drugIndex] = {};
              }
              allSectionsNeedingUpdate[drugIndex][subSectionIndex] = true;
            },
          );
        });
        setSectionContentNeedsUpdate(allSectionsNeedingUpdate);
      }
      props.onFinishedSaving();
    }
  }, [props.saveContentUpdatesTrigger]);

  const toggleEdit = () => {
    switch (props.editSection) {
      case EditableSection.None:
        break;
      case EditableSection.Primary:
        toggleEditingContent();
        break;
      case EditableSection.Secondary:
        toggleEditingSecondaryContent();
        break;
      case EditableSection.Both:
        toggleEditingContent();
        toggleEditingSecondaryContent();
        break;
      default:
        break;
    }
  };

  const toggleEditingContent = () => {
    setEditingContent(!editingContent);
  };

  const toggleEditingSecondaryContent = () => {
    setEditingSecondaryContent(!editingSecondaryContent);
  };

  const toggleEditingSection = (drugIndex: number, subSectionIndex: number) => {
    const currentValue = editingSections?.[drugIndex]?.[subSectionIndex];
    setEditingSections(prevValue => {
      return {
        ...prevValue,
        [drugIndex]: {
          [subSectionIndex]: !currentValue,
        },
      };
    });
  };

  const updateContent = (content: string) => {
    if (props.onChange) {
      props.onChange(props.title, content);
    }
    setContentNeedsUpdate(false);
  };

  const updateSecondaryContent = (content: string) => {
    if (props.onChange) {
      props.onChange(props.title, undefined, content);
    }
    setSecondaryContentNeedsUpdate(false);
  };

  const updateDrugSubsectionContent = (
    drugIndex: number,
    subSectionIndex: number,
    content: string,
  ) => {
    props.onChange(props.title, content, undefined, {
      drugIndex,
      subSectionIndex,
    });

    setSectionContentNeedsUpdate(prevValue => {
      return {
        ...prevValue,
        [drugIndex]: {
          [subSectionIndex]: false,
        },
      };
    });
  };

  const renderDrugSubsection = (
    subSection: { name: string; content: string | undefined },
    drugIndex: number,
    subSectionIndex: number,
  ): JSX.Element => {
    const docReadOnly =
      !props.editing &&
      (!editingSections ||
        !editingSections[drugIndex] ||
        editingSections[drugIndex][subSectionIndex] !== true);

    return (
      <Grid container className={classes.subSectionWrapper} justifyContent="space-between">
        <Typography className={classes.subSectionTitle}>{subSection.name}</Typography>
        {docReadOnly && !props.viewOnly && props.showEditButton && (
          <IconButton
            data-qa-id={`${props.qaId}-subsection-${
              subSection?.name?.replace(/\s/g, '') || ''
            }-edit-button`}
            onClick={() => toggleEditingSection(drugIndex, subSectionIndex)}
            size="large"
          >
            <EditIcon className={classes.editIcon} fontSize="small" />
          </IconButton>
        )}
        <Grid item xs={12}>
          <RichTextEditor
            qaId={`${props.qaId}-subsection-${subSection?.name?.replace(/\s/g, '') || ''}-editor`}
            autoFocus={false}
            features={StandardFeatures}
            featuresBar
            hideWrapper
            initialValue={subSection.content}
            initialValueFormat={EditorValueFormat.Html}
            outputFormat={EditorValueFormat.Html}
            placeHolder=""
            readOnly={docReadOnly}
            onSaveContentUpdates={content =>
              updateDrugSubsectionContent(drugIndex, subSectionIndex, content)
            }
            saveContentUpdatesTrigger={sectionContentNeedsUpdate?.[drugIndex]?.[subSectionIndex]}
            cancelContentUpdatesTrigger={props.cancelContentUpdatesTrigger}
          />
        </Grid>
      </Grid>
    );
  };

  const renderPrimaryAndSecondarySections = () => {
    const renderPrimaryContent =
      ((props.viewOnly || props.readOnly) && content) ||
      (!(props.viewOnly || props.readOnly) && content !== undefined);

    const renderSecondaryContent =
      ((props.viewOnly || props.readOnly) && secondaryContent) ||
      (!(props.viewOnly || props.readOnly) && secondaryContent !== undefined);

    const hasRightSideComponent = Boolean(props.rightSideComponent);
    const primaryEditable =
      props.editSection != null &&
      [EditableSection.Both, EditableSection.Primary].includes(props.editSection);
    const secondaryEditable =
      props.editSection != null &&
      [EditableSection.Both, EditableSection.Secondary].includes(props.editSection);

    return (
      <Grid container>
        {/* Primary */}
        <Grid item xs={hasRightSideComponent ? 6 : 12}>
          {renderPrimaryContent && (
            <RichTextEditor
              qaId={`${props.qaId}-editor`}
              autoFocus
              features={StandardFeatures}
              featuresBar
              initialValue={content}
              initialValueFormat={EditorValueFormat.Html}
              outputFormat={EditorValueFormat.Html}
              placeHolder=""
              readOnly={(!editingContent && !props.editing) || !primaryEditable}
              onSaveContentUpdates={content => updateContent(content)}
              saveContentUpdatesTrigger={contentNeedsUpdate}
              cancelContentUpdatesTrigger={props.cancelContentUpdatesTrigger}
            />
          )}
        </Grid>
        {/* Right Side */}
        {hasRightSideComponent && (
          <Grid item xs={6} className={classes.rightSideComponentWrapper}>
            {props.rightSideComponent}
          </Grid>
        )}
        {/* Secondary */}
        <Grid item xs={12}>
          {renderSecondaryContent && props.editSection !== EditableSection.None && (
            <RichTextEditor
              qaId={`${props.qaId}-editor`}
              autoFocus
              features={StandardFeatures}
              featuresBar
              initialValue={secondaryContent}
              initialValueFormat={EditorValueFormat.Html}
              onSaveContentUpdates={content => updateSecondaryContent(content)}
              saveContentUpdatesTrigger={secondaryContentNeedsUpdate}
              outputFormat={EditorValueFormat.Html}
              placeHolder=""
              readOnly={(!editingSecondaryContent && !props.editing) || !secondaryEditable}
              cancelContentUpdatesTrigger={props.cancelContentUpdatesTrigger}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <SecondaryTitleBar
        title={props.title}
        button={
          !editingContent &&
          content &&
          !props.viewOnly &&
          !props.readOnly &&
          props.showEditButton ? (
            <IconButton data-qa-id={`${props.qaId}-edit-button`} onClick={toggleEdit} size="large">
              <EditIcon className={classes.editIcon} fontSize="small" />
            </IconButton>
          ) : null
        }
      />

      <Grid item xs={12}>
        {renderPrimaryAndSecondarySections()}
        {props.drugSubSections &&
          Object.keys(props.drugSubSections).map((key, drugIndex) => {
            return (
              <>
                {/* drug label */}
                <TherapySubTitle label={key} />
                {/* editable sub sections */}
                {props.drugSubSections &&
                  props.drugSubSections[key] &&
                  Object.keys(props.drugSubSections[key]).map((subSectionKey, subSectionIndex) => {
                    return renderDrugSubsection(
                      { name: subSectionKey, content: props.drugSubSections?.[key][subSectionKey] },
                      drugIndex,
                      subSectionIndex,
                    );
                  })}
              </>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(EditableTextSection);
