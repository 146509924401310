import { Grid, Typography, Checkbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderTextField,
  renderDropdown,
  renderMultipleDropdown,
} from 'components/form/field/redux-field';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { required, validateDate } from 'components/form/validation/validation';
import { getTodayDateTime } from 'services/utils/date-service';
import {
  ALLERGY_FORM,
  EDIT_ALLERGY_FORM,
  NO_ALLERGY_INFORMATION_AVAILABLE_ID,
  ALLERGIES_NEED_REVERIFY,
  PATIENT_ALLERGY_STATUS_ACTIVE,
} from 'constants/index';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import {
  fetchAllergens,
  getAllergenCategories,
  addedNoKnownAllergy,
} from 'services/utils/allergy-service';
import { allergenTypes } from 'constants/lists';
import { addAllergy, editAllergy, verifyAllergies } from 'actions/action-allergies';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { CustomAllergyReactSelectForMiniGrid } from 'components/form/field/react-select';
import { styles } from '../patient-clinical-styles';

export const PatientAllergiesForm = props => {
  const {
    cancelHandler,
    handleSubmit,
    submitting,
    classes,
    patient,
    allergy,
    form,
    noAllergiesForm,
    verificationId,
    reset,
    formValues,
    isFromReconciliationAdd,
    submitCallback,
    editMode,
  } = props;

  const dispatch = useDispatch();

  const allergySeverities = useSelector(state => state.lookups.allergyLookups.severities);

  const allergyReactionTypes = useSelector(state => state.lookups.allergyReactionTypes);

  const allergyStatuses = useSelector(state => state.lookups.allergyLookups.statuses);

  let allergiesSources = useSelector(state => state.lookups.allergyLookups.sources);
  allergiesSources = allergiesSources
    ? allergiesSources.map(s => ({ value: s.id, label: s.value }))
    : [];

  const allergies = useSelector(state => state.allergies.allergiesList);

  const [valueToSearch, setValueToSearch] = useState(null);
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [displayOnlyMandatoryFields, setDisplayOnlyMandatoryFields] = useState(false);
  const [sameSourceForAll, setSameSourceForAll] = useState(false);

  if (!formValues) {
    return null;
  }

  const handleCancel = () => {
    cancelHandler();
    reset();
  };

  const submitMultipleRecords = values => {
    if (selectedAllergies.length) {
      const valuesToSubmit = selectedAllergies.map(item => {
        return {
          allergen: item,
          onset_dt: formValues[`onset_dt-${item.id}`] ?? null,
          status_id: formValues[`status_id-${item.id}`],
          reaction_type_ids: formValues[`reaction_type_ids-${item.id}`] ?? '',
          severity_id: formValues[`severity_id-${item.id}`] ?? null,
          allergen_notes: formValues[`allergen_notes-${item.id}`] ?? null,
          category: formValues[`category-${item.id}`] ?? '',
          allergy_source_id: formValues[`allergy_source_id-${item.id}`],
        };
      });
      onSubmit(valuesToSubmit);
    }
  };

  const onSubmit = valuesToSubmit => {
    const tempValuesToSubmit = !editMode ? valuesToSubmit : [valuesToSubmit];
    console.log('valuesToSubmit:', valuesToSubmit);
    console.log('tempValuesToSubmit:', tempValuesToSubmit);
    for (const values of tempValuesToSubmit) {
      let verifyPayload;
      const reactionTypeAsArr =
        Array.isArray(values.reaction_type_ids) || !values.reaction_type_ids
          ? values.reaction_type_ids
          : values.reaction_type_ids.split(',').map(val => Number(val));
      const categoryArr = values.category ? values.category.split(',') : [];
      let allergyPayload = {
        id: allergy ? allergy.id : null,
        allergen: {
          id: values.allergen?.id,
          is_environment: categoryArr && categoryArr.find(cat => cat === 'is_environment') ? 1 : 0,
          is_biologic: categoryArr && categoryArr.find(cat => cat === 'is_biologic') ? 1 : 0,
          is_food: categoryArr && categoryArr.find(cat => cat === 'is_food') ? 1 : 0,
          is_medication: categoryArr && categoryArr.find(cat => cat === 'is_medication') ? 1 : 0,
        },
        allergy_source_id: values.allergy_source_id,
        allergy_name: values.allergen?.definition,
        patient_id: patient.id,
        onset_dt: convertToArborDate(values.onset_dt).getUtcDate(),
        status_id: values.status_id,
        reaction_type_ids:
          values.reaction_type_ids && values.reaction_type_ids.length > 0
            ? reactionTypeAsArr
            : null,
        severity_id: values.severity_id,
        allergen_notes: values.allergen_notes,

        // drug_descriptor_id: medicationValues.idValue,
      };

      if (noAllergiesForm) {
        allergyPayload = {
          ...allergyPayload,
          allergen: { ...allergyPayload.allergen, id: NO_ALLERGY_INFORMATION_AVAILABLE_ID },
          noKnownAllergies: true,
        };
        verifyPayload = {
          patient_id: patient.id,
          verification_status_id: verificationId,
          verified_dt: convertToArborDate(getTodayDateTime()).getUtcDatetime(),
        };
      }
      if (allergy && !isFromReconciliationAdd) {
        dispatch(editAllergy(allergyPayload));
        verifyPayload = {
          patient_id: patient.id,
          verification_status_id: ALLERGIES_NEED_REVERIFY,
          verified_dt: convertToArborDate(getTodayDateTime()).getUtcDatetime(),
        };
        dispatch(verifyAllergies(verifyPayload));
      } else {
        let revisedAllergyPayload = allergyPayload;
        if (
          allergies &&
          allergies.some(a => a.allergen.id === NO_ALLERGY_INFORMATION_AVAILABLE_ID)
        ) {
          const noPatientAllergyId = allergies.find(
            a => a.allergen.id === NO_ALLERGY_INFORMATION_AVAILABLE_ID,
          ).id;
          revisedAllergyPayload = {
            ...revisedAllergyPayload,
            oldNoPatientAllergiesId: noPatientAllergyId,
          };
        }
        if (addedNoKnownAllergy(revisedAllergyPayload)) {
          revisedAllergyPayload = {
            ...revisedAllergyPayload,
            noKnownAllergies: true,
          };
        }
        if (verifyPayload) {
          dispatch(verifyAllergies(verifyPayload));
        }
        dispatch(addAllergy(revisedAllergyPayload));
      }
    }

    handleCancel();
    if (submitCallback) {
      submitCallback();
    }
  };

  const handleFetchAllergyOptions = searchText =>
    // TODO: improve performance by storing allergens in client and keying them to what
    // user just wrote.
    // eslint-disable-next-line no-confusing-arrow
    fetchAllergens(searchText).then(result =>
      result.data
        ? result.data.allergens.map(a => ({
            ...a,
            id: a.id,
            label: a.display,
          }))
        : [],
    );

  const handleFetchMultipleAllergyOptions = searchText => {
    // TODO: improve performance by storing allergens in client and keying them to what
    // user just wrote.
    // eslint-disable-next-line no-confusing-arrow
    return fetchAllergens(searchText).then(result =>
      result.data
        ? result.data.allergens.map(a => ({
            ...a,
            id: a.id,
            label: a.display,
          }))
        : [],
    );
  };

  const handleChange = newValue => {
    dispatch(change(form, 'category', getAllergenCategories(newValue)));
  };

  const handleDisplayMandatoryFields = () => {
    setDisplayOnlyMandatoryFields(prevState => !prevState);
  };

  const handleSameSourceForAll = () => {
    if (!sameSourceForAll) {
      const sourceValueToUse = formValues[`allergy_source_id-${selectedAllergies[0].id}`];
      if (sourceValueToUse !== undefined) {
        selectedAllergies.map((item, ind) => {
          if (ind > 0) {
            dispatch(change(form, `allergy_source_id-${item.id}`, sourceValueToUse));
          }
        });
      }
    }
    setSameSourceForAll(prevState => !prevState);
  };

  const checkIfSameSourceForAll = (sourceId, ind) => {
    if (ind === 0 && sameSourceForAll && sourceId !== undefined) {
      selectedAllergies.map((item, ind) => {
        if (ind > 0) {
          dispatch(change(form, `allergy_source_id-${item.id}`, sourceId));
        }
      });
    }
  };

  const capitalizeFirstLetter = text => {
    if (text && text.length) {
      return text
        .split(' ')
        .map(t => t.charAt(0).toUpperCase() + t.slice(1))
        .join(' ');
    }
    return text;
  };

  return (
    <form autoComplete="off" className={classes.tableAllergies}>
      <Grid container>
        {!noAllergiesForm && (
          <>
            {!editMode && (
              <>
                <Grid
                  item
                  xs={9}
                  className={classes.fieldContainer}
                  data-qa-id="add-allergy-form-field-allergen-wrapper"
                >
                  <Field
                    name="allergen"
                    label="Allergen *"
                    component={CustomAllergyReactSelectForMiniGrid}
                    validate={[required]}
                    fetchOptions={handleFetchMultipleAllergyOptions}
                    hint="Search by Allergy or code"
                    columnsToShow={{
                      display: 'Display',
                      category: 'Category',
                    }}
                    onChange={handleChange}
                    handleOnChange={values => {
                      setSelectedAllergies(values);
                    }}
                    qaId="selected_allergies_qaid"
                    isMulti
                    handleOnInputChange={value => {
                      if (value) {
                        setValueToSearch(value);
                      }
                    }}
                    valueToSearch={valueToSearch ?? null}
                    placeholder="Search by Allergy or code"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    checked={displayOnlyMandatoryFields}
                    onChange={handleDisplayMandatoryFields}
                    color="primary"
                    // value="archived"
                  />
                  <Typography component="div" className={classes.manadatoryFieldsCheckbox}>
                    Display only mandatory fields
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {!editMode &&
                selectedAllergies.map((x, ind) => {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.allergyName}>
                          {capitalizeFirstLetter(x.display)}
                        </Typography>
                      </Grid>
                      {!displayOnlyMandatoryFields && (
                        <Grid container xs={12}>
                          <Grid
                            item
                            xs={3}
                            className={classes.fieldContainer}
                            data-qa-id={`add-allergy-form-field-category-wrapper-${ind}`}
                          >
                            <Field
                              name={`category-${x.id}`}
                              label="Category"
                              id={`category-${x.id}`}
                              multiple
                              component={renderMultipleDropdown}
                              fields={allergenTypes}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={classes.fieldContainer}
                            data-qa-id={`add-allergy-form-field-reactions-wrapper-${ind}`}
                          >
                            <Field
                              name={`reaction_type_ids-${x.id}`}
                              label="Reactions"
                              id={`reaction_type_ids-${x.id}`}
                              multiple
                              component={renderMultipleDropdown}
                              fields={
                                allergyReactionTypes
                                  ? allergyReactionTypes.map(reaction => ({
                                      value: reaction.id,
                                      label: reaction.name,
                                    }))
                                  : []
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={classes.fieldContainer}
                            data-qa-id={`add-allergy-form-field-onset-wrapper-${ind}`}
                          >
                            <Field
                              name={`onset_dt-${x.id}`}
                              label="Onset"
                              component={renderDatePicker}
                              validate={[validateDate]}
                              placeholder="mm/dd/yyyy"
                              id={`onset-${x.id}`}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container xs={12}>
                        {allergySeverities && (
                          <>
                            {!displayOnlyMandatoryFields && (
                              <Grid
                                item
                                xs={3}
                                className={classes.fieldContainer}
                                data-qa-id={`add-allergy-form-field-severity-wrapper-${ind}`}
                              >
                                <Field
                                  name={`severity_id-${x.id}`}
                                  label="Severity"
                                  component={renderDropdown}
                                  fields={allergySeverities.map(cat => ({
                                    value: cat.id,
                                    label: cat.value,
                                  }))}
                                  id={`allergen-${x.id}`}
                                />
                              </Grid>
                            )}
                          </>
                        )}
                        <Grid
                          item
                          xs={3}
                          className={classes.fieldContainer}
                          data-qa-id="add-allergy-form-field-status-wrapper"
                        >
                          <Field
                            name={`status_id-${x.id}`}
                            label="Status *"
                            id={`status_id-${x.id}`}
                            component={renderDropdown}
                            validate={[required]}
                            fields={
                              allergyStatuses
                                ? allergyStatuses.map(status => ({
                                    value: status.id,
                                    label: status.value,
                                  }))
                                : []
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={classes.fieldContainer}
                          data-qa-id={`add-allergy-form-field-source-wrapper-${ind}`}
                        >
                          <Field
                            name={`allergy_source_id-${x.id}`}
                            label="Source *"
                            component={renderDropdown}
                            fields={allergiesSources}
                            validate={[required]}
                            id={`allergen-${x.id}`}
                            onChange={sourceId => checkIfSameSourceForAll(sourceId, ind)}
                          />
                        </Grid>
                        {ind === 0 && (
                          <Grid item xs={3}>
                            <Checkbox
                              checked={sameSourceForAll}
                              onChange={handleSameSourceForAll}
                              color="primary"
                              // value="archived"
                            />
                            <Typography
                              component="div"
                              className={classes.manadatoryFieldsCheckbox}
                            >
                              Define same source for all
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {!displayOnlyMandatoryFields && (
                        <Grid container xs={12}>
                          <Grid
                            item
                            xs={6}
                            className={classes.fieldContainer}
                            data-qa-id={`add-allergy-form-field-notes-wrapper-${ind}`}
                          >
                            <Field
                              name={`allergen_notes-${x.id}`}
                              label="Notes"
                              component={renderTextField}
                              multiline
                              rows={3}
                              id={`notes-${x.id}`}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <hr className={classes.divider} />
                    </>
                  );
                })}
              {editMode && !noAllergiesForm && (
                <>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs={6}
                      className={classes.fieldContainer}
                      data-qa-id="add-allergy-form-field-allergen-wrapper"
                    >
                      <Field
                        name="allergen"
                        label="Allergen *"
                        component={AutocompleteMinigrid}
                        validate={[required]}
                        fetchOptions={handleFetchAllergyOptions}
                        hint="Search by Allergy or code"
                        columnsToShow={{
                          display: 'Display',
                          category: 'Category',
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.fieldContainer}
                      data-qa-id="add-allergy-form-field-category-wrapper"
                    >
                      <Field
                        name="category"
                        label="Category"
                        id="category"
                        multiple
                        component={renderMultipleDropdown}
                        fields={allergenTypes}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.fieldContainer}
                      data-qa-id="add-allergy-form-field-reactions-wrapper"
                    >
                      <Field
                        name="reaction_type_ids"
                        label="Reactions"
                        id="reaction_type_ids"
                        multiple
                        component={renderMultipleDropdown}
                        fields={
                          allergyReactionTypes
                            ? allergyReactionTypes.map(reaction => ({
                                value: reaction.id,
                                label: reaction.name,
                              }))
                            : []
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs={3}
                      className={classes.fieldContainer}
                      data-qa-id="add-allergy-form-field-onset-wrapper"
                    >
                      <Field
                        name="onset_dt"
                        label="Onset"
                        component={renderDatePicker}
                        validate={[validateDate]}
                        placeholder="mm/dd/yyyy"
                        id="onset"
                      />
                    </Grid>
                    {allergySeverities && (
                      <Grid
                        item
                        xs={3}
                        className={classes.fieldContainer}
                        data-qa-id="add-allergy-form-field-severity-wrapper"
                      >
                        <Field
                          name="severity_id"
                          label="Severity"
                          component={renderDropdown}
                          fields={allergySeverities.map(cat => ({
                            value: cat.id,
                            label: cat.value,
                          }))}
                          id="allergen"
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={3}
                      className={classes.fieldContainer}
                      data-qa-id="add-allergy-form-field-status-wrapper"
                    >
                      <Field
                        name="status_id"
                        label="Status"
                        id="status_id"
                        component={renderDropdown}
                        fields={
                          allergyStatuses
                            ? allergyStatuses.map(status => ({
                                value: status.id,
                                label: status.value,
                              }))
                            : []
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.fieldContainer}
                      data-qa-id="add-allergy-form-field-source-wrapper"
                    >
                      <Field
                        name="allergy_source_id"
                        label="Source *"
                        component={renderDropdown}
                        fields={allergiesSources}
                        validate={[required]}
                        id="allergen"
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs={6}
                      className={classes.fieldContainer}
                      data-qa-id="add-allergy-form-field-notes-wrapper"
                    >
                      <Field
                        name="allergen_notes"
                        label="Notes"
                        component={renderTextField}
                        multiline
                        rows={3}
                        id="notes"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
        <ConfirmationPanel
          handleCancel={handleCancel}
          handleSubmit={!editMode ? submitMultipleRecords : handleSubmit(onSubmit)}
          disableSubmit={submitting}
          buttonIdPrefix={`patient_allergies_${allergy ? allergy.id : 'not_found'}`}
        />
      </Grid>
    </form>
  );
};

function handleFormName(allergy) {
  if (!allergy) {
    return ALLERGY_FORM;
  }
  const name = `${EDIT_ALLERGY_FORM}_${allergy.id}}`;
  return name;
}

function mapStateToProps(state, props) {
  const { patient } = state;
  const { allergy } = props;
  const name = handleFormName(allergy);

  const initialValues = {
    allergen: allergy
      ? {
          ...allergy.allergen,
          id: allergy.allergen.id,
          label: allergy.allergen.definition,
        }
      : null,
    onset_dt: allergy ? allergy.onset_dt : null,
    status_id: allergy ? allergy.status_id : PATIENT_ALLERGY_STATUS_ACTIVE,
    reaction_type_ids: allergy ? allergy.reactions.map(reaction => reaction.id) : null,
    severity_id: allergy ? allergy.severity_id : null,
    allergen_notes: allergy ? allergy.allergen_notes : null,
    category: allergy ? getAllergenCategories(allergy.allergen) : null,
    allergy_source_id: allergy ? allergy.allergy_source_id : null,
  };
  const formValues = getFormValues(name)(state) || initialValues;

  return {
    patient,
    form: name,
    formValues,
    initialValues,
    editMode: !!(formValues.allergen && Object.keys(formValues.allergen).length),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
)(reduxForm()(PatientAllergiesForm));
