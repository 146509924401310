import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    ninetyDaysSupplyDiv: {
      backgroundColor: '#eef4f9',
      padding: '3px',
      borderRadius: '3px',
      width: 'fit-content',
    },
    ninetyDaysSupplyIcon: {
      width: '12px',
      filter:
        'invert(27%) sepia(80%) saturate(540%) hue-rotate(180deg) brightness(90%) contrast(90%)', // Blue color for the image
      marginBottom: '-1px',
    },
    ninetyDaysSupplyText: {
      fontSize: '11px',
      color: '#6f7377',
      fontWeight: '450',
    },
  };
});
