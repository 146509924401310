import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { compose } from 'recompose';
import { Alert, AlertTitle, CircularProgress, Grid, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Field, InjectedFormProps, reduxForm, reset } from 'redux-form';
import { useDispatch } from 'react-redux';

import theme from '../../lib/themes/theme';
import { renderDropdown, renderMultipleDropdown, renderTextField } from '../form/field/redux-field';
import { maxLength, required, validateDate } from '../form/validation/validation';
import { renderDatePicker } from '../form/datepicker/datetime-picker';
import ConfirmationPanel from '../form/confirmation/confirmation-panel';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import { BulkSmsClient, IBulkSmsRecipient, IBulkSmsFilters } from '../../clients/bulk-sms';
import { notifyError, notifySuccess } from '../../actions/action-notifications';
import {
  AdherencePackagingOptions,
  FirstFillOptions,
  SmsOptions,
  transformToOptions,
} from '../../utils/bulk-sms';
import {
  CATEGORY_IN_PROGRESS,
  CATEGORY_OUTSTANDING,
  DIPENSING_ENROLLMENT_STATUS_OPT_IN,
  OPT_IN,
} from '../../constants';
import { TherapyAdministrationStatuses } from '../../constants/therapy-administration-statuses';

const sx = {
  confirmationPanelContainer: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
};

const FORM_NAME = 'bulk-sms-send-form';
const MAX_SMS_LENGTH = 500;

const maxSmsLength = maxLength(MAX_SMS_LENGTH);

export interface ISendBulkSmsFormProps extends IBulkSmsFilters {
  text_template: string;
}

type Props = InjectedFormProps<ISendBulkSmsFormProps>;
export const SendNewBulkSmsTabCmp: React.FC<Props> = (props: Props): JSX.Element => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const [recipients, setRecipients] = useState<IBulkSmsRecipient[]>([]);

  const customers = useTypedSelector(state => state.filters.customers || []);
  const serviceGroups = useTypedSelector(state => state.lookups.serviceGroups || []);
  const therapyTypes = useTypedSelector(state => state.lookups.therapyTypes || []);
  const enrollmentStatuses = useTypedSelector(
    state =>
      state.lookups.enrollmentStatuses.filter(i => ['Opt in', 'Opt out'].includes(i.status)) || [],
  );
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [showRefreshChip, setShowRefreshChip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState<Date | null>(null);
  const form = useTypedSelector(state => state.form[FORM_NAME]);
  const formValues = useMemo(() => form?.values, [form]);
  const errors = useMemo(() => form?.syncErrors || {}, [form]);
  const therapyAdministrationStatuses = useTypedSelector(
    state => state?.therapyStatuses?.therapyAdministrationStatuses || [],
  );

  const therapyAdministrationStatusOptions = useMemo(
    () =>
      transformToOptions(
        'id',
        (row: any) => [row.status, row.reason].filter(i => i !== null).join(' - '),
        therapyAdministrationStatuses.filter((i: any) =>
          [CATEGORY_OUTSTANDING, CATEGORY_IN_PROGRESS].includes(i.category_id),
        ),
      ),
    [therapyAdministrationStatuses],
  );
  const customerOptions = useMemo(() => transformToOptions('id', 'name', customers), [customers]);
  const serviceGroupOptions = useMemo(
    () => transformToOptions('id', 'display_name', serviceGroups),
    [serviceGroups],
  );
  const therapyTypeOptions = useMemo(
    () => transformToOptions('id', 'type', therapyTypes),
    [therapyTypes],
  );
  const enrollmentStatusesOptions = useMemo(
    () => transformToOptions('id', 'status', enrollmentStatuses),
    [enrollmentStatuses],
  );
  const [displayWarning, setDisplayWarning] = useState(false);

  const onChange = () => {
    setRefreshRequired(true);
  };

  useEffect(() => {
    const { text_template: _, ...formErrors } = errors;
    if (!refreshRequired || !formValues || Object.values(formErrors).length) {
      return;
    }
    setShowRefreshChip(true);
  }, [refreshRequired, errors, formValues]);

  useEffect(() => {
    if (!lastRefreshTimestamp) {
      return;
    }

    setLoading(true);
    setRefreshRequired(false);
    setShowRefreshChip(false);

    const { text_template: _, ...formErrors } = errors;
    if (!formValues || Object.values(formErrors).length) {
      setLoading(false);
      return;
    }

    const { text_template: _text, ...filters } = formValues;
    const from = moment().startOf('day').format('YYYY-MM-DD');
    const to = moment().add(1, 'd').startOf('day').format('YYYY-MM-DD');
    BulkSmsClient.previewRecipientList(filters)
      .then(response => setRecipients(response.data))
      // ignore error
      .catch(() => {})
      .finally(() => setLoading(false));
    BulkSmsClient.findBulkSmsSent({ ...filters, from, to, pageSize: 0 }).then(response =>
      setDisplayWarning(!!response?.data?.totalCount && response.data.totalCount > 0),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRefreshTimestamp]);

  return (
    <Grid container spacing={3} paddingTop={3}>
      <Grid item xs={12}>
        <Typography>
          Use this form to send mass texts to patients opted-in to patient messaging.{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Disclaimer: SMS is unsecure and unencrypted.{' '}
          <span
            style={{ color: theme.palette.error.main, fontWeight: theme.typography.fontWeightBold }}
          >
            DO NOT SEND PHI.
          </span>{' '}
          Do not include patient specific information.
        </Typography>
        <Typography>
          These texts should be used for informational purpose only (example use cases: pharmacy
          closures, holiday, weather events)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={9}>
              <h3>Filter Recipients</h3>
            </Grid>
            <Grid item xs={3} flexDirection="column" alignItems="flex-end">
              {loading && (
                <Chip
                  label="Loading..."
                  color="info"
                  icon={<CircularProgress color="secondary" size={20} />}
                />
              )}
              {!loading && showRefreshChip && (
                <Chip
                  label="Check receipients"
                  onClick={() => setLastRefreshTimestamp(new Date())}
                  variant="filled"
                  color="info"
                />
              )}
              {!loading && !refreshRequired && lastRefreshTimestamp && recipients?.length > 0 && (
                <Chip
                  label={
                    <>
                      Sending to {recipients.length} patients -
                      <CSVLink data={recipients} filename="sms-batch-preview-data.csv">
                        View List
                      </CSVLink>
                    </>
                  }
                  variant="outlined"
                  color="primary"
                />
              )}
              {!loading && !refreshRequired && lastRefreshTimestamp && !recipients?.length && (
                <Chip label="No recipients found" variant="outlined" color="error" />
              )}
            </Grid>
            <Grid item xs={4}>
              <Field
                name="customer_id"
                label="Site *"
                component={renderDropdown}
                onChange={() => onChange()}
                fields={customerOptions}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="service_group_id"
                label="Service Group"
                onChange={() => onChange()}
                component={renderMultipleDropdown}
                fields={serviceGroupOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="therapy_administration_status_id"
                label="Administration Status"
                onChange={onChange}
                component={renderMultipleDropdown}
                fields={therapyAdministrationStatusOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="sms_opt_out_status"
                label="SMS Opt-out status"
                onChange={onChange}
                component={renderDropdown}
                fields={SmsOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="therapy_type_id"
                label="Therapy type"
                onChange={onChange}
                component={renderMultipleDropdown}
                fields={therapyTypeOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="dispensing_decision_id"
                label="Dispense Decision"
                onChange={onChange}
                component={renderDropdown}
                fields={enrollmentStatusesOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="follow_up_date_from"
                label="Follow Up Date From *"
                component={renderDatePicker}
                onChange={onChange}
                validate={[validateDate, required]}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="follow_up_date_to"
                label="Follow Up Date To *"
                component={renderDatePicker}
                onChange={onChange}
                validate={[validateDate, required]}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="exclude_first_fill"
                label="First Fill"
                onChange={onChange}
                component={renderDropdown}
                fields={FirstFillOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="exclude_adherence_packaging"
                label="Adherence Packaging"
                component={renderDropdown}
                onChange={onChange}
                fields={AdherencePackagingOptions}
              />
            </Grid>
            {displayWarning && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  It seems a message with the same filters was already sent today. Please review
                  sent messages before you continue.
                </Alert>
              </Grid>
            )}
            <Grid item xs={9}>
              <h3>Add Message *</h3>
            </Grid>
            <Grid item xs={3} flexDirection="column" alignItems="flex-end">
              <Typography
                textAlign="right"
                fontWeight="bold"
                color={
                  formValues?.text_template?.length > MAX_SMS_LENGTH
                    ? theme.palette.error.main
                    : theme.palette.common.black
                }
              >
                {formValues?.text_template?.length || 0}/{MAX_SMS_LENGTH}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="text_template"
                rows={3}
                fullWidth
                multiline
                component={renderTextField}
                variant="outlined"
                placeholder="Enter your message"
                hideLabel
                width="100%"
                validate={[required, maxSmsLength]}
              />
            </Grid>
            <Grid item xs={12}>
              <h3>Message Preview</h3>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="text_template_preview"
                input={{
                  rows: 3,
                  fullWidth: true,
                  multiline: true,
                  sx: { backgroundColor: '#eef1f7' },
                  value: `${
                    formValues?.text_template || 'Text'
                  } - UNSUBSCRIBE to unsubscribe; HELP for help;`,
                }}
                readOnly
                component={renderTextField}
                variant="outlined"
                hideLabel
                width="100%"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={sx.confirmationPanelContainer}>
            <ConfirmationPanel
              cancelButtonText="Reset"
              submitButtonText="Send"
              handleSubmit={handleSubmit(values => {
                BulkSmsClient.sendBulkSms(values)
                  .then(() => {
                    dispatch(reset(FORM_NAME));
                    dispatch(notifySuccess('Bulk SMS sent with success!'));
                  })
                  .catch(err => {
                    console.error(err);
                    dispatch(notifyError('Failed to send SMS'));
                  });
              })}
              handleCancel={() => {
                dispatch(reset(FORM_NAME));
              }}
            />
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export const SendNewBulkSmsTab = compose<Props, {}>(
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      sms_opt_out_status: OPT_IN,
      dispensing_decision_id: DIPENSING_ENROLLMENT_STATUS_OPT_IN,
      therapy_administration_status_id: String(TherapyAdministrationStatuses.OnTherapy),
      exclude_first_fill: FirstFillOptions.find(opt => opt.label === 'No')?.value,
    },
  }),
)(SendNewBulkSmsTabCmp);
