import {
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  Grid,
  MenuItem,
  ClickAwayListener,
  Button,
  Menu,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import lodash from 'lodash';
import React, { Component, useState, useCallback, useEffect } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { SmallSearch } from 'components/icons/icons';
import { ReactSelectForReduxSingle, ReactSelectAsync } from 'components/form/field/react-select';
import RadioButton from 'components/form/radio/radio-button';
import ReactRadioGroup from 'components/form/radio/radio-group';
import FieldLabel from 'components/form/field/field-label';
import CheckboxGroup from 'components/form/field/checkbox-group';
import classNames from 'classnames';
import { styles } from './field-styles';
import Validation from '../validation/validation';

const INPUT_DEBOUNCE_MS = 500;
const INLINE_FLEX = 'inline-flex';

const dropdownReactSelect = props => <ReactSelectForReduxSingle {...props} simpleValue />;
const dropdownReactSelectNonSimple = props => <ReactSelectForReduxSingle {...props} />;

const dropdownReactSelectMultiple = props => (
  <ReactSelectForReduxSingle {...props} isMulti simpleValue />
);

const dropdownReactSelectMultipleAsync = props => (
  <ReactSelectAsync {...props} isMulti simpleValue />
);

export const renderDropdown = dropdownReactSelect;
export const renderDropdownNonSimple = dropdownReactSelectNonSimple;
export const renderMultipleDropdown = dropdownReactSelectMultiple;
export const renderMultipleDropdownAsync = dropdownReactSelectMultipleAsync;

const textField = ({
  input,
  classes,
  value,
  label,
  'meta': { touched, error, warning },
  'data-qa-id': qaId,
  variant = 'standard',
  missing_value_id,
  ...custom
}) => {
  const { width, hideLabel } = custom;
  const containerWidth = width || '75%';
  const missing_value_prop =
    missing_value_id && typeof missing_value_id === 'object'
      ? { [Object.keys(missing_value_id)[0]]: Object.values(missing_value_id)[0] }
      : {};
  return (
    <div
      style={{
        display: INLINE_FLEX,
        flexDirection: 'column',
        width: containerWidth,
      }}
    >
      {!hideLabel && <Typography className={classes.fieldLabel}>{label}</Typography>}
      <TextField
        variant={variant}
        data-qa-id={qaId}
        {...input}
        {...custom}
        {...missing_value_prop}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderTextField = withStyles(styles, { withTheme: true })(textField);

export const renderDebouncedTextField = withStyles(styles, { withTheme: true })(
  ({
    input,
    classes,
    value,
    onChange,
    label,
    meta,
    qaId,
    containerClassName,
    maxLength,
    validCharacters,
    missing_value_id,
    ...custom
  }) => {
    const getValidCharacters = (validCharacters, currentValue) => {
      if (currentValue) {
        const validChar = currentValue.split('').reduce((acc, itemCharacter) => {
          if (validCharacters.includes(itemCharacter)) {
            acc.push(String(itemCharacter));
          }
          return acc;
        }, []);
        return validChar.join('');
      }
      return null;
    };
    const missing_value_prop =
      missing_value_id && typeof missing_value_id === 'object'
        ? { [Object.keys(missing_value_id)[0]]: Object.values(missing_value_id)[0] }
        : {};

    const { touched, error, warning } = meta || {};
    const [val, setVal] = useState(
      validCharacters && input?.value
        ? getValidCharacters(validCharacters, input.value)
        : input?.value,
    );
    const { width } = custom;
    const containerWidth = width || '75%';
    useEffect(() => {
      setVal(
        validCharacters && input?.value
          ? getValidCharacters(validCharacters, input.value)
          : input?.value,
      );
    }, [input?.value]);
    const debouncedOnChange = useCallback(
      lodash.debounce((e, v) => {
        if (e && e.target) {
          if (validCharacters) {
            input.onChange(getValidCharacters(validCharacters, e.target.value));
          } else {
            input.onChange(e.target.value);
          }
          if (onChange) {
            onChange(v);
          }
        }
      }, INPUT_DEBOUNCE_MS),
      [],
    );
    return (
      <div
        className={containerClassName}
        style={{
          display: INLINE_FLEX,
          flexDirection: 'column',
          width: containerWidth,
        }}
      >
        {label && <Typography className={classes.fieldLabel}>{label}</Typography>}
        <TextField
          variant="standard"
          {...input}
          {...custom}
          onChange={e => {
            setVal(
              validCharacters && e.target.value
                ? getValidCharacters(validCharacters, e.target.value)
                : e.target.value,
            );
            e.persist();
            debouncedOnChange(e);
          }}
          value={val}
          inputProps={{
            'data-qa-id': qaId,
            'maxLength': maxLength ?? '',
          }}
          {...missing_value_prop}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...custom.InputProps,
            endAdornment: custom.suffix ? (
              <InputAdornment position="end">{custom.suffix}</InputAdornment>
            ) : undefined,
          }}
          className={custom.centerInput ? classes.centerInputValue : null}
        />
        <Validation touched={touched} error={error} warning={warning} />
      </div>
    );
  },
);

export const renderStaticTextField = withStyles(styles, { withTheme: true })(
  ({
    input,
    classes,
    value,
    onChange,
    label,
    meta: { touched, error, warning },
    qaId,
    containerClassName,
    ...custom
  }) => {
    const [val, setVal] = useState(input.value);
    const { width } = custom;
    const containerWidth = width || '75%';
    useEffect(() => {
      setVal(input.value);
    }, [input.value]);
    const debouncedOnChange = useCallback(
      lodash.debounce((e, v) => {
        if (e && e.target) {
          input.onChange(e);
          if (onChange) {
            onChange(v);
          }
        }
      }, INPUT_DEBOUNCE_MS),
      [],
    );
    return (
      <div
        className={containerClassName}
        style={{
          display: INLINE_FLEX,
          flexDirection: 'column',
          width: containerWidth,
        }}
      >
        {label && <Typography className={classes.fieldLabel}>{label}</Typography>}
        <TextField
          variant="standard"
          {...input}
          {...custom}
          onChange={e => {
            setVal(e.target.value);
            e.persist();
            debouncedOnChange(e);
          }}
          disabled
          value={val}
          multiline
          maxRows={4}
          inputProps={{
            'data-qa-id': qaId,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: custom.suffix ? (
              <InputAdornment position="end">{custom.suffix}</InputAdornment>
            ) : undefined,
          }}
        />
        <Validation touched={touched} error={error} warning={warning} />
      </div>
    );
  },
);

const selectTextField = ({
  input,
  select,
  classes,
  value,
  label,
  fields,
  meta: { touched, error, warning },
  ...custom
}) => {
  const { width } = custom;
  const containerWidth = width || '75%';
  return (
    <div
      style={{
        display: INLINE_FLEX,
        flexDirection: 'column',
        width: containerWidth,
      }}
    >
      {label && <Typography className={classes.fieldLabel}>{label}</Typography>}
      <TextField variant="standard" select {...input} {...custom}>
        {fields.map(field => (
          <MenuItem key={field.value} value={field.value}>
            {field.label}
          </MenuItem>
        ))}
      </TextField>
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderSelectTextField = withStyles(styles, { withTheme: true })(selectTextField);

const moneyTextField = ({
  input,
  classes,
  value,
  label,
  meta: { touched, error, warning },
  id,
  disabled,
  ...custom
}) => {
  const { width } = custom;
  const containerWidth = width || '75%';
  return (
    <div
      style={{
        display: INLINE_FLEX,
        flexDirection: 'column',
        width: containerWidth,
      }}
    >
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <NumericFormat
        {...input}
        variant="standard"
        customInput={TextField}
        thousandSeparator
        prefix="$"
        id={id}
        disabled={disabled}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderMoneyTextField = withStyles(styles, { withTheme: true })(moneyTextField);

const numberField = ({
  input,
  classes,
  value,
  label,
  disabled,
  allowNegative = false,
  meta: { touched, error, warning },
  ...custom
}) => {
  const { width } = custom;
  const containerWidth = width || '75%';

  return (
    <div
      style={{
        display: INLINE_FLEX,
        flexDirection: 'column',
        width: containerWidth,
      }}
    >
      {label && <Typography className={classes.fieldLabel}>{label}</Typography>}
      <NumericFormat
        {...input}
        {...custom}
        variant="standard"
        allowNegative={allowNegative}
        customInput={TextField}
        disabled={disabled}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderNumberField = withStyles(styles, { withTheme: true })(numberField);

const phoneTextField = ({
  input,
  classes,
  value,
  label,
  disabled,
  meta: { touched, error, warning },
  maskFormat,
  maskCharacter,
  allowEmptyFormatting,
  onValueChange,
  ...custom
}) => {
  const { width } = custom;
  const containerWidth = width || '75%';
  const format = maskFormat || '(###) ###-####';
  const mask = maskCharacter || '_';
  return (
    <div
      style={{
        display: INLINE_FLEX,
        flexDirection: 'column',
        width: containerWidth,
      }}
    >
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <PatternFormat
        {...input}
        customInput={TextField}
        variant="standard"
        format={format}
        mask={mask}
        disabled={disabled}
        allowEmptyFormatting={allowEmptyFormatting}
        onValueChange={onValueChange}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderPhoneTextField = withStyles(styles, { withTheme: true })(phoneTextField);

export const renderNumberMaskedField = withStyles(styles, { withTheme: true })(phoneTextField);

const numberFormat = ({
  input,
  classes,
  value,
  label,
  disabled,
  suffix,
  mask,
  formatNumber,
  meta: { touched, error, warning },
  ...custom
}) => {
  const { width } = custom;
  const containerWidth = width || '75%';
  return (
    <>
      <div
        style={{
          display: INLINE_FLEX,
          width: containerWidth,
        }}
      >
        <NumericFormat
          {...input}
          name={`${input.name}_days`}
          customInput={TextField}
          variant="standard"
          allowEmptyFormatting
          format={formatNumber}
          suffix={suffix}
          mask={mask}
          disabled={disabled}
        />
      </div>
      <Validation touched={touched} error={error} warning={warning} />
    </>
  );
};

export const renderNumberFormat = withStyles(styles, { withTheme: true })(numberFormat);

const checkbox = ({ input, classes, label, id, disabled, onChange, wrapperProps }) => (
  <div className={classes.checkboxWrapper} {...wrapperProps}>
    {label && (
      <Typography variant="caption" className={classes.smallerFieldLabel}>
        {label}
      </Typography>
    )}
    <Checkbox
      name={input.name}
      color="primary"
      checked={!!input.value}
      onChange={value => {
        if (onChange) {
          onChange(value);
        }
        if (input.onChange) {
          input.onChange(value);
        }
        if (input.onBlur) {
          input.onBlur();
        }
      }}
      id={id}
      disabled={disabled}
    />
  </div>
);

const checkboxInline = ({ input, classes, label, id, disabled, labelSize, labelClassName }) => (
  <div className={classes.checkBoxInline}>
    <Checkbox
      name={input.name}
      color="primary"
      checked={!!input.value}
      onChange={input.onChange}
      id={id}
      disabled={disabled}
    />
    {label && (
      <Typography
        variant="caption"
        className={
          labelClassName ||
          `checkBoxInlineLabel fieldLabel${
            labelSize ? labelSize.charAt(0).toUpperCase() + labelSize.slice(1).toLowerCase() : ''
          }`
        }
      >
        {label}
      </Typography>
    )}
  </div>
);

const checkboxHorizontal = ({
  input,
  classes,
  label,
  styleOverride,
  labelOverride,
  checkboxOverride,
  ...rest
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...(styleOverride || {}),
    }}
  >
    <Typography style={{ ...(labelOverride || {}) }} className={classes.fieldLabel}>
      {label}
    </Typography>
    <Checkbox
      name={input.name}
      color="primary"
      checked={!!input.value}
      onChange={input.onChange}
      className={classes.horizontalCheckbox}
      style={{ ...(checkboxOverride || {}) }}
      {...rest}
    />
  </div>
);

const toogle = ({ input, classes, leftLabel, rightLabel, ...rest }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography className={classNames(classes.fieldLabel, classes.switchLabel)}>
      {leftLabel}
    </Typography>
    <Switch
      name={input.name}
      color="primary"
      checked={!!input.value}
      onChange={input.onChange}
      {...rest}
    />
    <Typography className={classNames(classes.fieldLabel, classes.switchLabel)}>
      {rightLabel}
    </Typography>
  </div>
);

export const renderCheckbox = withStyles(styles, { withTheme: true })(checkbox);
export const renderCheckboxInline = withStyles(styles, { withTheme: true })(checkboxInline);
export const renderCheckboxHorizontal = withStyles(styles, { withTheme: true })(checkboxHorizontal);
export const renderToggle = withStyles(styles, { withTheme: true })(toogle);

const last4ssnField = ({
  input,
  classes,
  label,
  meta: { touched, error, warning },

  ...custom
}) => {
  const { adormentcontent } = custom;

  return (
    <div style={{ display: INLINE_FLEX, flexDirection: 'column', width: '75%' }}>
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <PatternFormat
        {...input}
        customInput={TextField}
        variant="standard"
        format="####"
        mask="_"
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.inputAdornment} position="start">
              {adormentcontent}
            </InputAdornment>
          ),
        }}
        {...custom}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderLast4ssnField = withStyles(styles, {
  withTheme: true,
})(last4ssnField);

const textFieldWithAdorment = ({
  input,
  classes,
  label,
  meta: { touched, error, warning },
  ...custom
}) => {
  const { adormentcontent } = custom;
  return (
    <div style={{ display: INLINE_FLEX, flexDirection: 'column', width: '75%' }}>
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <TextField
        variant="standard"
        {...input}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.inputAdornment} position="start">
              {adormentcontent}
            </InputAdornment>
          ),
        }}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderTextFieldWithAdorment = withStyles(styles, {
  withTheme: true,
})(textFieldWithAdorment);

const searchField = ({ input, classes, label, meta: { touched, error, warning }, ...custom }) => {
  const { width } = custom;
  return (
    <div style={{ display: INLINE_FLEX, flexDirection: 'column', width }}>
      {label ? <Typography className={classes.fieldLabel}>{label}</Typography> : <span />}
      <TextField
        variant="standard"
        {...input}
        InputProps={{
          classes: { input: classes.searchPlaceholder },
          startAdornment: (
            <InputAdornment className={classes.searchAdornment} position="start">
              <SmallSearch />
            </InputAdornment>
          ),
        }}
        {...custom}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderSearchField = withStyles(styles, {
  withTheme: true,
})(searchField);

const searchFieldWithAttachedField = ({
  input,
  classes,
  label,
  meta: { touched, error, warning },
  ...custom
}) => {
  const { width } = custom;
  return (
    <div style={{ display: INLINE_FLEX, flexDirection: 'column', width }}>
      {label ? <Typography className={classes.fieldLabel}>{label}</Typography> : <span />}
      <TextField
        variant="standard"
        {...input}
        InputProps={{
          classes: { input: classes.searchPlaceholder },
          startAdornment: (
            <InputAdornment className={classes.searchAdornment} position="start">
              <SmallSearch />
            </InputAdornment>
          ),
        }}
        {...custom}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderSearchFieldWithAttachedField = withStyles(styles, {
  withTheme: true,
})(searchFieldWithAttachedField);

export const renderYesNoDropdown = props => (
  <ReactSelectForReduxSingle
    {...props}
    simpleValue
    fields={[
      { value: 1, label: 'Yes' },
      { value: 0, label: 'No' },
    ]}
  />
);

const radioGroup = ({
  input,
  label,
  classes,
  fields,
  radioMap,
  caption,
  view,
  meta: { touched, error, warning },
  radioWidth = 48,
  disabled,
  qaId,
  ...custom
}) => {
  const { width } = custom;
  const containerWidth = width || '75%';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: containerWidth,
      }}
      name={`${input.name}-radio`}
    >
      <FormControl component="fieldset" className={classes.formControl}>
        {caption ? (
          <Typography className={classes.fieldLabel}>{label}</Typography>
        ) : (
          <FormLabel component="legend">{label}</FormLabel>
        )}

        <RadioGroup
          row
          name={input.name}
          className={classNames(classes.group, { [classes.formControlListView]: view === 'list' })}
          value={input.value}
          onChange={value => {
            input.onChange(value);
            input.onBlur();
          }}
          disabled={disabled}
        >
          {radioMap.map(radio => (
            <FormControlLabel
              key={radio.value}
              value={radio.value}
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Radio
                  style={{ width: radioWidth }}
                  color="primary"
                  inputProps={{
                    'data-qa-id': qaId ? `${qaId}_${radio.label}` : '',
                  }}
                />
              }
              label={radio.label}
              disabled={radio.disabled ? radio.disabled : disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export const renderRadioGroup = withStyles(styles, { withTheme: true })(radioGroup);

export const renderRadioGroupInline = withStyles(styles, { withTheme: true })(
  ({
    input,
    label,
    classes,
    fields,
    radioMap,
    caption,
    meta: { touched, error, warning },
    gridSize,
    name,
    valCallback,
    ...custom
  }) => {
    const [selectedValue, setSelectedValue] = useState(input.value);
    const handleChange = event => {
      setSelectedValue(event.target.value);
      valCallback(event.target.value);
    };
    const { width, customGridSize, hideRadioShowTitle } = custom;
    const containerWidth = width || '75%';
    let radioGridSize = customGridSize || 2;
    if (radioMap && radioMap.length > 0) {
      switch (radioMap.length) {
        case 1:
          radioGridSize = 12;
          break;
        case 2:
          radioGridSize = 6;
          break;
        case 3:
          radioGridSize = 4;
          break;
        case 4:
          radioGridSize = 3;
          break;
        default:
          break;
      }
    }
    const allValsTheSame = radioMap.every(val => val.value === radioMap[0].value);
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: containerWidth,
        }}
        name={`${input.name}-radio`}
      >
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Typography variant="caption">{label}</Typography>
          </Grid>
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  {radioMap.map(radio => (
                    <Grid key={radio.value} item xs={radioGridSize}>
                      <Grid container alignItems="center" justifyContent="flex-start">
                        {!hideRadioShowTitle && (
                          <Radio
                            checked={
                              selectedValue === radio.value ||
                              allValsTheSame ||
                              (!selectedValue && radio.value === null)
                            }
                            onChange={handleChange}
                            value={radio.value}
                            name={name}
                            inputProps={{ 'aria-label': radio.value }}
                            color="primary"
                            disabled={allValsTheSame}
                          />
                        )}
                        <Typography
                          variant="caption"
                          className={hideRadioShowTitle ? classes.radioLabelPadding : null}
                        >
                          {radio.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Validation touched={touched} error={error} warning={warning} />
        </Grid>
      </div>
    );
  },
);

class CustomRadioGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      more: false,
      additional: null,
      anchor: null,
    };
  }

  componentDidMount() {
    const {
      input: { onChange },
      radioMap,
      initialValueName,
    } = this.props;

    const initialValue = radioMap.find(it => it.label === initialValueName);

    if (initialValue) {
      onChange(initialValue.value);
    }
  }

  handleClickMore(element) {
    this.setState({ more: true, anchor: element });
  }

  handleClickAway() {
    this.setState({ more: false });
  }

  handleSelect(item) {
    const {
      input: { onChange },
    } = this.props;
    this.setState({ additional: item, more: false }, () => {
      onChange(item.value);
    });
  }

  render() {
    const {
      input,
      label,
      radioMap,
      minItemWidth,
      maxItemWidth,
      disabled = false,
      initialValueName,
      qaId,
      displayInline,
      classes,
      maxItems,
      meta: { touched, error, warning },
      buttonProps,
    } = this.props;
    const { more, additional, anchor } = this.state;
    let filteredOptions = radioMap
      .filter(it => !it.less || it.label === initialValueName)
      .filter((_, idx) => !maxItems || idx <= maxItems);
    if (additional) {
      filteredOptions.push(additional);
    }
    const showMoreButton = filteredOptions.length !== radioMap.length;
    if (showMoreButton) {
      const selectedIt = radioMap.find(it => it.value === input.value);
      const isVisible = filteredOptions.some(it => it.value === input.value);
      if (!isVisible && selectedIt) {
        filteredOptions = [
          ...filteredOptions.slice(0, Math.max(maxItems || 0, filteredOptions.length) - 1),
          selectedIt,
        ];
      }
    }

    return (
      <>
        <div className={displayInline && classes.radioGroupInlineWrapper}>
          <FieldLabel variant="caption" fieldName={label} />
          <div className={displayInline ? classes.radioGroupInline : classes.radioGroup}>
            <ReactRadioGroup
              horizontal
              {...input}
              wrap={minItemWidth}
              width={showMoreButton ? 'auto' : ''}
            >
              {filteredOptions.map(item => (
                <RadioButton
                  key={item.value}
                  value={item.value}
                  minItemWidth={minItemWidth}
                  maxItemWidth={maxItemWidth}
                  disabled={disabled || item.disabled}
                  qaID={qaId ? `${qaId}_${item.label}` : item.label}
                  ariaLabel={item.label}
                  {...buttonProps}
                >
                  {item.icon && <div>{item.icon}</div>}
                  {item.label}
                </RadioButton>
              ))}
            </ReactRadioGroup>
            {showMoreButton && (
              <Button
                data-qa-id="status-more-button"
                onClick={e => this.handleClickMore(e.currentTarget)}
                className={classes.radioGroupMoreButton}
              >
                More
              </Button>
            )}
            {more && (
              <ClickAwayListener onClickAway={() => this.handleClickAway()}>
                <Menu
                  open={more}
                  keepMounted
                  onClose={() => this.handleClickAway()}
                  anchorEl={anchor}
                >
                  {radioMap
                    .filter(it => !filteredOptions.some(fO => fO.value === it.value))
                    .map(item => (
                      <MenuItem
                        onClick={() => this.handleSelect(item)}
                        qa-id={qaId ? `${qaId}_${item.value}_menuItem` : ''}
                        disabled={disabled || item.disabled}
                        data-qa-id={`option-${item?.label}`}
                        className="qa-option"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </Menu>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <Validation touched={touched} error={error} warning={warning} />
      </>
    );
  }
}

class CustomRadioGroupFC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      more: false,
      additional: null,
      anchor: null,
    };
  }

  componentDidMount() {
    const {
      input: { onChange },
      radioMap,
      initialValueName,
    } = this.props;

    const initialValue = radioMap.find(it => it.label === initialValueName);

    if (initialValue) {
      onChange(initialValue.value);
    }
  }

  handleClickMore(element) {
    this.setState({ more: true, anchor: element });
  }

  handleClickAway() {
    this.setState({ more: false });
  }

  handleSelect(item) {
    const {
      input: { onChange },
    } = this.props;
    this.setState({ additional: item, more: false }, () => {
      onChange(item.value);
    });
  }

  render() {
    const {
      input,
      label,
      radioMap,
      minItemWidth,
      maxItemWidth,
      disabled = false,
      initialValueName,
      qaId,
      displayInline,
      classes,
      maxItems,
      meta: { touched, error, warning },
      buttonProps,
    } = this.props;
    const { more, additional, anchor } = this.state;

    let filteredOptions = radioMap
      .filter(it => !it.less || it.label === initialValueName)
      .filter((_, idx) => !maxItems || idx <= maxItems);
    if (additional) {
      filteredOptions.push(additional);
    }
    const showMoreButton = filteredOptions.length !== radioMap.length;
    if (showMoreButton) {
      const selectedIt = radioMap.find(it => it.value === input.value);
      const isVisible = filteredOptions.some(it => it.value === input.value);
      if (!isVisible && selectedIt) {
        filteredOptions = [
          ...filteredOptions.slice(0, Math.max(maxItems || 0, filteredOptions.length) - 1),
          selectedIt,
        ];
      }
    }

    return (
      <>
        <div className={displayInline && classes.radioGroupInlineWrapper}>
          <FieldLabel variant="caption" fieldName={label} />
          <div
            className={displayInline ? classes.radioGroupInline : classes.radioGroup}
            data-fc-mv-id={this.props.missing_value_id}
          >
            <ReactRadioGroup
              horizontal
              {...input}
              onChange={x => {
                input.onChange && input.onChange(x);
              }}
              wrap={minItemWidth}
              width={showMoreButton ? 'auto' : ''}
              indexOverride={this.props.indexOverride}
              methodButtonCallback={this.props.methodButtonCallback}
            >
              {filteredOptions.map(item => (
                <RadioButton
                  key={item.value}
                  value={item.value}
                  minItemWidth={minItemWidth}
                  maxItemWidth={maxItemWidth}
                  disabled={disabled || item.disabled}
                  qaID={qaId ? `${qaId}_${item.label}` : ''}
                  ariaLabel={item.label}
                  {...buttonProps}
                >
                  {item.icon && <div>{item.icon}</div>}
                  {item.label}
                </RadioButton>
              ))}
            </ReactRadioGroup>
            {showMoreButton && (
              <Button
                data-qa-id="status-more-button"
                onClick={e => this.handleClickMore(e.currentTarget)}
                className={classes.radioGroupMoreButton}
              >
                More
              </Button>
            )}
            {more && (
              <ClickAwayListener onClickAway={() => this.handleClickAway()}>
                <Menu
                  open={more}
                  keepMounted
                  onClose={() => this.handleClickAway()}
                  anchorEl={anchor}
                >
                  {radioMap
                    .filter(it => !filteredOptions.some(fO => fO.value === it.value))
                    .map(item => (
                      <MenuItem
                        onClick={() => this.handleSelect(item)}
                        qa-id={qaId ? `${qaId}_${item.value}_menuItem` : ''}
                        disabled={disabled || item.disabled}
                        data-qa-id={`option-${item?.label}`}
                        className="qa-option"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </Menu>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <Validation touched={touched} error={error} warning={warning} />
      </>
    );
  }
}

export const renderReactRadioGroup = withStyles(styles, { withTheme: true })(CustomRadioGroup);
export const renderReactRadioGroupFC = withStyles(styles, { withTheme: true })(CustomRadioGroupFC);

const switchComponent = ({ input, classes, label }) => (
  <div>
    <Typography className={classes.fieldLabel}>{label}</Typography>
    <Switch name={input.name} color="primary" checked={!!input.value} onChange={input.onChange} />
  </div>
);

export const renderSwitch = withStyles(styles, { withTheme: true })(switchComponent);

export const renderCheckboxGroup = ({ input, fields, label, qaId }) => (
  <Grid container>
    <CheckboxGroup
      value={input.value}
      fields={fields}
      updateParent={e => {
        input.onChange(parseInt(e, 10));
      }}
      checklistName={label}
      gridSize={6}
      qaId={qaId}
      {...input}
    />
  </Grid>
);
