import React from 'react';
import { StarIcon } from 'components/icons/icons';
import { useStyles } from './ninety-days-badge.styles';

const NinetyDaysBanner = (): JSX.Element => {
  const useStyle = useStyles();
  return (
    <div className={useStyle.ninetyDaysSupplyDiv}>
      <StarIcon className={useStyle.ninetyDaysSupplyIcon} />{' '}
      <span className={useStyle.ninetyDaysSupplyText}>90 Day Refill Opportunity</span>
    </div>
  );
};
export default NinetyDaysBanner;
