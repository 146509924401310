import React from 'react';
import JsonForm from 'components/dynamic-form/json-form';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from './task-form-hoc';
import { FillDeliveryConfirmationStatus } from '../../../interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { DeliveryMethodValue } from '../../../constants/enums';

function BundleTaskStatusForm(props) {
  const {
    data,
    formName,
    level,
    formId,
    title,
    type,
    onValidityChange,
    linkedFormIds,
    onInitialLoad,
    onFormChange,
    linkedTasks,
    additionalDocumentProps,
    drawBorder,
    taskIsSubmitting,
    dosisRegimenText,
    ...otherProps
  } = props;

  const providers = providerBuilder(type)(data, props);

  if (
    data.status_id === FillDeliveryConfirmationStatus.Packed &&
    data.preferred_rx_delivery_method === DeliveryMethodValue.PickUp
  ) {
    providers.json.statuses[
      providers.json.statuses.findIndex(status => status.id === 'packed')
    ].next = 'pickup';
  }

  return (
    <JsonForm
      title={title}
      jsonForm={providers.json}
      formName={formName}
      formId={formId}
      data={data}
      providers={providers}
      level={level}
      onValidityChange={onValidityChange}
      linkedFormIds={linkedFormIds}
      onInitialLoad={onInitialLoad}
      onFormChange={onFormChange}
      linkedTasks={linkedTasks}
      forceNextStatus
      additionalDocumentProps={additionalDocumentProps}
      drawBorder={drawBorder}
      taskIsSubmitting={taskIsSubmitting}
      dosisRegimenText={dosisRegimenText}
      {...otherProps}
    />
  );
}

export default taskProviderHoc(BundleTaskStatusForm);
