/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Select, { components as SelectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';
import { emphasize } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import NoSsr from '@mui/material/NoSsr';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  FormHelperText,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { defaultTheme } from 'lib/themes';
import Validation, { ImmediateValidation } from '../validation/validation';
import { globalZIndex } from '../../../constants/z-index';

const styles = theme => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '100%',
  },
  input: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    height: 'fit-content',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingRight: 2,
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
    height: 25,
    maxWidth: '100%',
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  deleteIcon: {
    color: `${theme.palette.primary.grey12} !important`,
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: theme.font.formItemSize,
    color: theme.palette.primary.formItem,
  },
  disabled: {
    color: theme.palette.primary.disabled,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: theme.font.formItemSize,
  },
  placeholderEnabled: {
    color: theme.palette.primary.formItem,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 12,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  fieldLabel: {
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
  },
  fieldLabelMedium: {
    fontSize: theme.font.mediumFontSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  fieldLabelLarge: {
    fontSize: theme.font.largeFontSize,
    fontWeight: theme.font.weight.thick,
    color: theme.palette.text.primary,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  fieldValue: {
    fontSize: theme.font.formItemSize,
    color: theme.palette.primary.formItem,
  },
  inputAdornment: {
    whiteSpace: 'nowrap',
    marginRight: 0,
  },
  fieldLabelShrink: {
    transform: 'translate(0, 1.5px)',
    position: 'relative',
    marginBottom: -18,
    minHeight: theme.layout.taskFormLabelMinHeight,
  },
  errorMessage: {
    color: theme.palette.primary.warning,
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
});

const narrowStyles = theme => ({
  ...styles,
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
    height: 25,
    fontSize: theme.font.extraSmallFontSize,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: theme.font.extraSmallFontSize,
    color: theme.palette.primary.formItem,
  },
  input: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    height: 'fit-content',
    marginTop: -8,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'wrap',
  },
});

const reactSelectMiniGridStyles = theme => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '100%',
  },
  input: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    height: 'fit-content',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingRight: 2,
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
    height: 25,
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.cornflowerblue,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: theme.font.formItemSize,
    color: theme.palette.primary.formItem,
  },
  disabled: {
    color: theme.palette.primary.disabled,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: theme.font.formItemSize,
  },
  placeholderEnabled: {
    color: theme.palette.primary.formItem,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 12,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(1),
  },
  fieldLabel: {
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
  },
  fieldLabelMedium: {
    fontSize: theme.font.mediumFontSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  fieldLabelLarge: {
    fontSize: theme.font.largeFontSize,
    fontWeight: theme.font.weight.thick,
    color: theme.palette.text.primary,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  fieldValue: {
    fontSize: theme.font.formItemSize,
    color: theme.palette.primary.formItem,
  },
  inputAdornment: {
    whiteSpace: 'nowrap',
    marginRight: 0,
  },
  fieldLabelShrink: {
    transform: 'translate(0, 1.5px)',
    position: 'relative',
    marginBottom: -18,
    minHeight: theme.layout.taskFormLabelMinHeight,
  },
  errorMessage: {
    color: theme.palette.primary.warning,
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
});

function NoOptionsMessage(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

const inputComponent = React.forwardRef((props, _ref) => {
  const { inputRef, ...rest } = props;
  return <div ref={inputRef} {...rest} />;
});

function Control(props) {
  const { selectProps, innerRef, children, innerProps } = props;
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...selectProps.textFieldProps}
    />
  );
}

function Placeholder(props) {
  const { selectProps, innerProps, children } = props;
  const { isDisabled } = selectProps;

  return (
    <Typography
      color="textSecondary"
      className={classNames(
        {
          [selectProps.classes.disabled]: isDisabled,
          [selectProps.classes.placeholderEnabled]: !isDisabled,
        },
        selectProps.classes.placeholder,
      )}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  const { selectProps, children, innerProps } = props;
  const { isDisabled } = selectProps;
  // If passed option is a React component then render it directly without wrapping it
  if (React.isValidElement(selectProps.value?.label)) {
    return children;
  }
  const inlineStyle = selectProps?.styles?.singleValue ? selectProps.styles.singleValue() : {};
  return (
    <Typography
      style={inlineStyle}
      className={classNames(selectProps.classes.singleValue, {
        [selectProps.classes.disabled]: isDisabled,
      })}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function ValueContainer(props) {
  const { selectProps, children } = props;
  // If passed option is a React component then unwrap styles
  const wrapperClassName = React.isValidElement(selectProps.value?.label)
    ? classNames(selectProps.classes.valueContainer, selectProps.classes.noWrap)
    : selectProps.classes.valueContainer;
  const missing_value_prop =
    selectProps.missing_value_id && typeof selectProps.missing_value_id === 'object'
      ? {
          [Object.keys(selectProps.missing_value_id)[0]]: Object.values(
            selectProps.missing_value_id,
          )[0],
        }
      : {};

  return (
    <div className={wrapperClassName} {...missing_value_prop}>
      {children}
    </div>
  );
}

function MultiValue(props) {
  const {
    children,
    selectProps,
    removeProps,
    isFocused,
    data: { isFixed },
  } = props;
  return (
    <Tooltip
      arrow
      title={children}
      placement={selectProps.tooltips?.placement}
      disableHoverListener={!selectProps.tooltips}
      disableFocusListener={!selectProps.tooltips}
      disableTouchListener={!selectProps.tooltips}
    >
      <Chip
        tabIndex={-1}
        label={children}
        className={classNames(selectProps.classes.chip, {
          [selectProps.classes.chipFocused]: isFocused,
        })}
        onDelete={isFixed ? false : removeProps.onClick}
        deleteIcon={<CancelIcon {...removeProps} className={selectProps.classes.deleteIcon} />}
      />
    </Tooltip>
  );
}

function Option(props) {
  const { innerProps, label, isSelected, data } = props;
  const labelValue = data.labelText ?? label;
  const dataQaId = data.dataQaId ?? label;
  const dataQaIdValue = `option-${dataQaId}`;

  return (
    <SelectComponents.Option
      {...props}
      innerProps={{
        ...innerProps,
        'aria-label': labelValue,
        'className': `qa-option ${isSelected ? 'qa-selected' : ''}`,
        'data-qa-id': dataQaIdValue,
      }}
    />
  );
}

function Input(props) {
  const {
    selectProps: { 'data-qa-id': dataQaId },
  } = props;
  return <SelectComponents.Input {...props} data-qa-id={dataQaId} />;
}

const components = {
  Control,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  Option,
  Input,
};

function ReactSelectForRedux(props) {
  const {
    input,
    label,
    classes,
    theme,
    meta: { touched, error, warning },
    tooltips,
    disabled,
  } = props;
  const { fields } = props;

  const selectStyles = {
    input: base => ({
      ...base,
      'color': theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: theme.palette.primary.grey12,
    }),
    dropdownIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
    clearIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
  };

  return (
    <div className={classes.root} id={`${input.name}-select`}>
      <NoSsr>
        <Select
          {...input}
          variant="standard"
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label,
            InputLabelProps: {
              disableAnimation: true,
              classes: {
                root: classes.fieldLabel,
                shrink: classes.fieldLabelShrink,
              },
              shrink: true,
            },
          }}
          options={fields}
          components={components}
          value={input.value}
          onChange={item => {
            if (input.onChange) input.onChange(item);
          }}
          onBlur={() => {
            if (input.onBlur) {
              input.onBlur();
            }
          }}
          isMulti
          tooltips={tooltips}
          isDisabled={disabled}
          isOptionDisabled={option => option.disabled}
        />
      </NoSsr>
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
}

const reactSelectForReduxSingle = props => {
  const {
    input,
    label,
    classes,
    theme,
    simpleValue,
    compareObject,
    isMulti,
    tooltips,
    inputLabelStyle,
    noDropdownIndicator,
    immediateValidation = false,
    highlightSingleValue = false,
    preventEmpty,
    labelSize,
    id,
    meta: { touched, error, warning },
    disabled,
    closeMenuOnSelect,
    fields,
    helpMessage,
    width,
    plainTextValue,
    maxOptions,
    onChange,
    dataQaId,
    defaultValues = {},
    ...customProps
  } = props;

  const selectStyles = {
    container: base => ({
      ...base,
    }),
    input: base => ({
      ...base,
    }),
    menu: base => ({
      ...base,
      zIndex: 10,
    }),
    singleValue: base => ({
      ...base,
      ...(highlightSingleValue
        ? {
            color: 'red',
            padding: '0 15px 0 15px',
            borderRadius: '15px',
            borderStyle: 'solid',
            borderColor: 'red',
            borderWidth: '1px',
          }
        : {}),
    }),
    // Ensure the menuPortal has the highest stack order so it will always be visible,
    // even when it is in a dialog and expands below the dialog boundary
    menuPortal: base => ({
      ...base,
      zIndex: globalZIndex.reactSelect,
    }),
    // eslint-disable-next-line arrow-body-style
    option: (base, { data, isSelected, isDisabled }) => {
      return {
        ...base,
        fontWeight: data.highlight ? 'bold' : undefined,
        // eslint-disable-next-line no-nested-ternary
        color: data.highlight
          ? defaultTheme.palette.primary.patina
          : isSelected
          ? defaultTheme.palette.primary.white
          : undefined,
        backgroundColor: isDisabled ? theme.palette.primary.grey25 : base.backgroundColor,
      };
    },
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: theme.palette.primary.grey12,
    }),
    dropdownIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
    clearIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
  };

  if (noDropdownIndicator) {
    selectStyles.dropdownIndicator = () => ({
      display: 'none',
    });
    selectStyles.indicatorsContainer = () => ({
      display: 'none',
    });
  }

  // when the options are objects, use the value property because
  // react-select needs the whole element, but our implementation
  // of redux form needs the value
  if (defaultValues && Object.keys(defaultValues).length && input.value === '') {
    input.value = defaultValues.value;
    if (input.onChange) input.onChange(defaultValues.value);
  }
  let { value } = input;
  if (isMulti) {
    value = String(value || '')
      .split(plainTextValue ? ', ' : ',')
      .map(v => fields?.find(it => (simpleValue && it ? it.value : it)?.toString() === v));
  } else if (compareObject && simpleValue && fields && fields[0] && typeof fields[0] === 'object') {
    const found = fields?.find(
      it =>
        Object.keys(it.value).every(key => it.value[key] == input.value?.[key]) &&
        Object.keys(input.value).every(key => input.value[key] == it.value[key]),
    );
    if (found) {
      value = found;
    } else if (preventEmpty) {
      const [firstField] = fields;
      ({ value } = firstField);
    }
  } else if (simpleValue && fields && fields[0] && typeof fields[0] === 'object') {
    const found = fields?.find(it => String(it.value) === String(input.value));
    if (found) {
      value = found;
    } else if (preventEmpty) {
      const [firstField] = fields;
      ({ value } = firstField);
    }
  }
  let textFieldLabelClassName = classes.fieldLabel;
  if (labelSize && labelSize === 'medium') {
    textFieldLabelClassName = classes.fieldLabelMedium;
  }
  if (labelSize && labelSize === 'large') {
    textFieldLabelClassName = classes.fieldLabelLarge;
  }
  const textFieldProps = label
    ? {
        label,
        InputLabelProps: {
          disableAnimation: true,
          classes: {
            root: textFieldLabelClassName,
            shrink: classes.fieldLabelShrink,
          },
          shrink: true,
          style: inputLabelStyle,
        },
      }
    : undefined;
  return (
    <div className={classes.root} id={`${input.name}-select`} style={{ width }}>
      <NoSsr>
        <Select
          {...input}
          variant="standard"
          menuPlacement="auto"
          data-qa-id={dataQaId}
          menuPortalTarget={document.body}
          minMenuHeight={300}
          maxMenuHeight={300}
          isMulti={isMulti}
          tooltips={tooltips}
          classes={classes}
          styles={selectStyles}
          textFieldProps={textFieldProps}
          closeMenuOnSelect={closeMenuOnSelect}
          options={fields}
          components={components}
          value={value}
          onChange={item => {
            if (item && item.onSelect) {
              item.onSelect();
            } else if (isMulti && item) {
              const newValue = item
                .map(it => (simpleValue && it ? it.value : it))
                .filter((it, index) => (maxOptions ? index < maxOptions : true))
                .join(plainTextValue ? ', ' : ',');
              if (input.onChange) input.onChange(newValue);
              if (onChange) {
                onChange(newValue);
              }
            } else {
              const newValue = simpleValue && item ? item.value : item;
              if (input.onChange) input.onChange(newValue);
              if (onChange) {
                onChange(newValue);
              }
            }
          }}
          onBlur={() => {
            if (input.onBlur) {
              input.onBlur();
            }
          }}
          inputId={id}
          isDisabled={disabled}
          {...customProps}
          isOptionDisabled={option => option.disabled === true}
        />
      </NoSsr>
      {helpMessage && (
        <FormHelperText className={classes.errorMessage}>{helpMessage}</FormHelperText>
      )}
      {immediateValidation ? (
        <ImmediateValidation error={error} />
      ) : (
        <Validation touched={touched} error={error} warning={warning} />
      )}
    </div>
  );
};

const ReactSelectForReduxSingleAsync = props => {
  const {
    input,
    label,
    classes,
    theme,
    isMulti,
    tooltips,
    provider,
    noDropdownIndicator,
    labelSize,
    id,
    meta: { touched, error, warning },
    disabled,
    closeMenuOnSelect,
    defaultOptions,
    placeholder,
    menuPlacement,
    getOptionLabel,
    getOptionValue,
  } = props;

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
    }),
    menu: base => ({
      ...base,
      zIndex: 10,
    }),
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: theme.palette.primary.grey12,
    }),
    dropdownIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
    clearIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
  };

  if (noDropdownIndicator) {
    selectStyles.dropdownIndicator = () => ({
      display: 'none',
    });
    selectStyles.indicatorsContainer = () => ({
      display: 'none',
    });
  }

  let textFieldLabelClassName = classes.fieldLabel;
  if (labelSize && labelSize === 'medium') {
    textFieldLabelClassName = classes.fieldLabelMedium;
  }
  if (labelSize && labelSize === 'large') {
    textFieldLabelClassName = classes.fieldLabelLarge;
  }
  const textFieldProps = label
    ? {
        label,
        InputLabelProps: {
          disableAnimation: true,
          classes: {
            root: textFieldLabelClassName,
            shrink: classes.fieldLabelShrink,
          },
          shrink: true,
        },
      }
    : undefined;

  const [currentOptions, setCurrentOptions] = useState([]);
  const loadOptions = async (inputValue, callback) => {
    const options = await provider(inputValue);
    setCurrentOptions(options);
    callback(options);
  };
  const onChange = selectedItems => {
    if (!selectedItems?.length) {
      setCurrentOptions([]);
    }
    if (input.onChange) input.onChange(selectedItems);
  };
  const onKeyDown = e => {
    if (e.key === 'Enter' && !currentOptions?.length) {
      e.preventDefault();
    }
  };

  return (
    <div className={classes.root} id={`${input.name}-select`}>
      <NoSsr>
        <AsyncSelect
          {...input}
          onKeyDown={onKeyDown}
          onChange={onChange}
          menuPlacement={menuPlacement || 'auto'}
          minMenuHeight={300}
          maxMenuHeight={300}
          isMulti={isMulti}
          tooltips={tooltips}
          classes={classes}
          styles={selectStyles}
          textFieldProps={textFieldProps}
          closeMenuOnSelect={closeMenuOnSelect}
          loadOptions={loadOptions}
          components={components}
          value={input.value}
          onBlur={() => input.onBlur()}
          inputId={id}
          isDisabled={disabled}
          defaultOptions={defaultOptions}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
        />
      </NoSsr>
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

const reactSelectControlled = ({
  classes,
  className,
  label,
  fields,
  value,
  onChange,
  theme,
  dataQaId,
  minimal,
  id,
  enabled = true,
  ...custom
}) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
    }),
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: theme.palette.primary.grey12,
    }),
    dropdownIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
    clearIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
  };

  if (minimal) {
    selectStyles.dropdownIndicator = () => ({
      display: 'none',
    });
    selectStyles.indicatorsContainer = () => ({
      display: 'none',
    });
    selectStyles.indicatorsSeparator = () => ({
      display: 'none',
    });
  }
  return (
    <div className={className || classes.root} data-qa-id={dataQaId}>
      <Select
        variant="standard"
        classes={classes}
        styles={selectStyles}
        textFieldProps={{
          label,
          InputLabelProps: {
            disableAnimation: true,
            classes: {
              root: classes.fieldLabel,
              shrink: classes.fieldLabelShrink,
            },
            shrink: true,
          },
        }}
        options={fields}
        components={components}
        value={value}
        onChange={onChange}
        inputId={id}
        isOptionDisabled={option => option.disabled === true}
        isDisabled={!enabled}
        {...custom}
      />
    </div>
  );
};

const reactSelect = props => {
  const {
    name,
    label,
    value,
    handleOnChange,
    classes,
    theme,
    fields,
    defaultValues,
    id,
    placeholder,
    isClearable,
    tooltips,
    isDisabled,
    isMulti = true,
    menuPosition = undefined,
    style = {},
    isOptionDisabled = () => false,
    handleOnInputChange = undefined,
    hideDropdownIcon = false,
  } = props;

  let addlMenuStyling = {};
  if (menuPosition) {
    addlMenuStyling = {
      ...addlMenuStyling,
      position: menuPosition,
    };
  }

  const selectStyles = {
    input: base => ({
      ...base,
      'color': theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menu: base => ({
      ...base,
      zIndex: 10,
      ...addlMenuStyling,
    }),
    ...style,
    menuPortal: base => {
      return {
        ...base,
        zIndex: Number.MAX_SAFE_INTEGER,
      };
    },
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: theme.palette.primary.grey12,
    }),
    dropdownIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
    clearIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
  };

  const componentType =
    hideDropdownIcon === false
      ? components
      : { ...components, DropdownIndicator: () => null, IndicatorSeparator: () => null };

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <div className={classes.root} name={name} data-qa-id={props['data-qa-id']}>
      <NoSsr>
        <Select
          variant="standard"
          defaultValue={defaultValues}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label,
            InputLabelProps: {
              disableAnimation: true,
              classes: {
                root: classes.fieldLabel,
                shrink: classes.fieldLabelShrink,
              },
              shrink: true,
            },
          }}
          options={fields}
          components={componentType}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          isMulti={isMulti}
          tooltips={tooltips}
          inputId={id || ''}
          isClearable={isClearable}
          menuPortalTarget={document.body}
          isDisabled={isDisabled}
          isOptionDisabled={isOptionDisabled}
        />
      </NoSsr>
    </div>
  );
};

const AllergyReactSelectForMiniGrid = props => {
  const {
    name,
    label,
    value,
    handleOnChange,
    classes,
    theme,
    fields,
    defaultValues,
    id,
    placeholder,
    isClearable,
    tooltips,
    isDisabled,
    isMulti = true,
    menuPosition = undefined,
    style = {},
    isOptionDisabled = () => false,
    handleOnInputChange = undefined,
    hideDropdownIcon = false,
    valueToSearch,
    fetchOptions,
    columnsToShow,
    valueFormatter,
    minSearchLength,
    hint,
    simplistic,
    defaultOptions,
    input,
  } = props;

  const [currentValueText, setCurrentValueText] = useState(valueToSearch ?? '');
  const [searchText, setSearchText] = useState(currentValueText);
  const [options, setOptions] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  let addlMenuStyling = {};
  if (menuPosition) {
    addlMenuStyling = {
      ...addlMenuStyling,
      position: menuPosition,
    };
  }

  useEffect(() => {
    if (
      defaultOptions &&
      defaultOptions.length &&
      editFlag === false &&
      input?.value &&
      Object.keys(input.value).length
    ) {
      setOptions(defaultOptions);
      setEditFlag(true);
    }
  }, [defaultOptions, editFlag, input]);

  const selectStyles = {
    input: base => ({
      ...base,
      'color': theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    control: base => ({
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderColor: theme.palette.primary.grey23,
    }),
    menu: base => ({
      ...base,
      zIndex: 10,
      ...addlMenuStyling,
    }),
    ...style,
    menuPortal: base => {
      return {
        ...base,
        zIndex: Number.MAX_SAFE_INTEGER,
      };
    },
    minigridTable: {
      maxHeight: 500,
    },
    minigridRow: {
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
        cursor: 'pointer',
      },
      'minWidth': 0,
      'height': 10,
    },
    minigridCellDisabled: {
      padding: theme.spacing(1),
      color: theme.palette.primary.lightGrey,
    },
    cell: {
      padding: theme.spacing(1),
      color: theme.palette.primary.black,
    },
    minigridCols: {
      padding: theme.spacing(1),
      minWidth: 125,
      height: 0,
    },
    minigridTableHeader: {
      height: 0,
      padding: 0,
    },
  };

  const getRowStyle = () => {
    return classes.minigridRow;
  };

  const getCellStyle = option => (option.disabled ? classes.minigridCellDisabled : classes.cell);
  const getOptionKey = (option, index) => option.id || `${option.name}-${index}`;

  const renderTableRows = items => {
    if (!items?.length) {
      return null;
    }
    return (
      <TableBody>
        {items.map((option, index) => {
          const rowStyle = getRowStyle();
          const cellStyle = getCellStyle(option);
          const row = Object.keys(columnsToShow).map(columnKey => {
            const value = valueFormatter
              ? valueFormatter(option, columnKey, option[columnKey])
              : option[columnKey];
            return (
              <TableCell
                className={`${cellStyle} qa-option`}
                key={columnKey}
                aria-label={value}
                data-qa-id={`option-${value}`}
              >
                {value}
              </TableCell>
            );
          });
          return (
            <TableRow className={rowStyle} key={getOptionKey(option, index)}>
              {row}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  useEffect(() => {
    if (valueToSearch) {
      setSearchText(valueToSearch);
      setCurrentValueText(valueToSearch);
    }
  }, [valueToSearch]);

  useEffect(() => {
    if (currentValueText) {
      setLoading(true);
      fetchOptions(currentValueText).then(result => {
        const res = result.map(item => {
          return {
            ...item,
            displayLabel: renderTableRows([item]),
            value: item.id,
            label: currentValueText,
          };
        });
        setOptions(res);
        setLoading(false);
      });
    }
  }, [currentValueText]);

  const CustomOption = props => {
    return <components.MultiValue {...props}>{`${props.data.display}`}</components.MultiValue>;
  };

  const formatOptionLabel = ({ displayLabel }) => {
    return (
      <div>
        <div>{displayLabel}</div>
      </div>
    );
  };

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <div className={classes.root} name={name} data-qa-id={props['data-qa-id']}>
      <NoSsr>
        <Select
          variant="standard"
          defaultValue={defaultValues}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label,
            InputLabelProps: {
              disableAnimation: true,
              classes: {
                root: classes.fieldLabel,
                shrink: classes.fieldLabelShrink,
              },
              shrink: true,
            },
          }}
          options={options}
          components={{ MultiValue: CustomOption }}
          value={editFlag && defaultOptions && defaultOptions.length ? defaultOptions : value}
          placeholder={placeholder}
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          isMulti={isMulti}
          tooltips={tooltips}
          inputId={id || ''}
          isClearable={isClearable}
          menuPortalTarget={document.body}
          isDisabled={isDisabled}
          isOptionDisabled={isOptionDisabled}
          formatOptionLabel={formatOptionLabel}
        />
      </NoSsr>
    </div>
  );
};

const ReactSelectForMiniGrid = props => {
  const {
    name,
    label,
    value,
    handleOnChange,
    classes,
    theme,
    fields,
    defaultValues,
    id,
    placeholder,
    isClearable,
    tooltips,
    isDisabled,
    isMulti = true,
    menuPosition = undefined,
    style = {},
    isOptionDisabled = () => false,
    handleOnInputChange = undefined,
    hideDropdownIcon = false,
    therapyToSearch,
    fetchOptions,
    columnsToShow,
    valueFormatter,
    minSearchLength,
    hint,
    simplistic,
    defaultOptions,
    input,
  } = props;

  const [currentValueText, setCurrentValueText] = useState(therapyToSearch ?? '');
  const [searchText, setSearchText] = useState(currentValueText);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editFlag, setEditFlag] = useState(false);

  let addlMenuStyling = {};
  if (menuPosition) {
    addlMenuStyling = {
      ...addlMenuStyling,
      position: menuPosition,
    };
  }

  useEffect(() => {
    if (
      defaultOptions &&
      defaultOptions.length &&
      editFlag === false &&
      input?.value &&
      Object.keys(input.value).length
    ) {
      setOptions(defaultOptions);
      setEditFlag(true);
    }
  }, [defaultOptions, editFlag, input]);

  const selectStyles = {
    input: base => ({
      ...base,
      'color': theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    control: base => ({
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderColor: theme.palette.primary.grey23,
    }),
    menu: base => ({
      ...base,
      zIndex: 10,
      ...addlMenuStyling,
    }),
    ...style,
    menuPortal: base => {
      return {
        ...base,
        zIndex: Number.MAX_SAFE_INTEGER,
      };
    },
    minigridTable: {
      maxHeight: 500,
    },
    minigridRow: {
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
        cursor: 'pointer',
      },
      'minWidth': 0,
      'height': 10,
    },
    minigridCellDisabled: {
      padding: theme.spacing(1),
      color: theme.palette.primary.lightGrey,
    },
    cell: {
      padding: theme.spacing(1),
      color: theme.palette.primary.black,
    },
    minigridCols: {
      padding: theme.spacing(1),
      minWidth: 125,
      height: 0,
    },
    minigridTableHeader: {
      height: 0,
      padding: 0,
    },
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: theme.palette.primary.grey12,
    }),
    dropdownIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
    clearIndicator: base => ({
      ...base,
      color: theme.palette.primary.grey12,
    }),
  };

  const componentType =
    hideDropdownIcon === false
      ? components
      : { ...components, DropdownIndicator: () => null, IndicatorSeparator: () => null };

  const getRowStyle = () => {
    return classes.minigridRow;
  };

  const getCellStyle = option => (option.disabled ? classes.minigridCellDisabled : classes.cell);
  const getOptionKey = (option, index) => option.id || `${option.name}-${index}`;

  const renderTableRows = items => {
    if (!items?.length) {
      return null;
    }
    return (
      <TableBody>
        {items.map((option, index) => {
          const isDisabled = option.disabled;

          const rowStyle = getRowStyle();
          const cellStyle = getCellStyle(option);
          const row = Object.keys(columnsToShow).map(columnKey => {
            const value = valueFormatter
              ? valueFormatter(option, columnKey, option[columnKey])
              : option[columnKey];
            return (
              <TableCell
                className={`${cellStyle} qa-option`}
                key={columnKey}
                aria-label={value}
                data-qa-id={`option-${value}`}
              >
                {value}
              </TableCell>
            );
          });
          return (
            <TableRow className={rowStyle} key={getOptionKey(option, index)}>
              {row}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const formatOptionLabel = ({ displayLabel }) => {
    return (
      <div>
        <div>{displayLabel}</div>
      </div>
    );
  };

  useEffect(() => {
    if (therapyToSearch) {
      setSearchText(therapyToSearch);
      setCurrentValueText(therapyToSearch);
    }
  }, [therapyToSearch]);

  useEffect(() => {
    if (currentValueText) {
      setLoading(true);
      fetchOptions(currentValueText).then(result => {
        const res = result.map(item => {
          return {
            ...item,
            displayLabel: renderTableRows([item]),
            value: item.id,
            label: currentValueText,
          };
        });
        setOptions(res);
        setLoading(false);
      });
    }
  }, [currentValueText]);

  const CustomOption = props => {
    return (
      <components.MultiValue {...props}>
        {`${props.data.drug_name} ${props.data.dosage_form} ${props.data.dose} ${
          props.data.dosage_form_description ? ` | ${props.data.dosage_form_description}` : ''
        }`}
      </components.MultiValue>
    );
  };

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <div className={classes.root} name={name} data-qa-id={props['data-qa-id']}>
      <NoSsr>
        <Select
          variant="standard"
          defaultValue={defaultValues}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label,
            InputLabelProps: {
              disableAnimation: true,
              classes: {
                root: classes.fieldLabel,
                shrink: classes.fieldLabelShrink,
              },
              shrink: true,
            },
          }}
          options={options}
          components={{ MultiValue: CustomOption }}
          value={editFlag && defaultOptions && defaultOptions.length ? defaultOptions : value}
          placeholder={placeholder}
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          isMulti={isMulti}
          tooltips={tooltips}
          inputId={id || ''}
          isClearable={isClearable}
          menuPortalTarget={document.body}
          isDisabled={isDisabled}
          isOptionDisabled={isOptionDisabled}
          formatOptionLabel={formatOptionLabel}
        />
      </NoSsr>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ReactSelectForRedux);
export const ReactSelect = withStyles(styles, { withTheme: true })(reactSelect);
export const CustomReactSelectForMiniGrid = withStyles(reactSelectMiniGridStyles, {
  withTheme: true,
})(ReactSelectForMiniGrid);
export const CustomAllergyReactSelectForMiniGrid = withStyles(reactSelectMiniGridStyles, {
  withTheme: true,
})(AllergyReactSelectForMiniGrid);
export const ReactSelectAsync = withStyles(styles, { withTheme: true })(
  ReactSelectForReduxSingleAsync,
);
export const ReactSelectNarrow = withStyles(narrowStyles, { withTheme: true })(reactSelect);
export const ReactSelectForReduxSingle = withStyles(styles, {
  withTheme: true,
})(reactSelectForReduxSingle);
export const ReactSelectControlled = withStyles(styles, { withTheme: true })(reactSelectControlled);
