import {
  validateDateTime,
  validateNumber,
  required,
  defined,
  validateSubForm,
  validateSubForms,
} from 'components/form/validation/validation';
import moment from 'moment';
import { evaluateCondition } from './helpers';

export const getValidators = (providers, field, disabled) => {
  const validators = [];
  if (field.required && !disabled) {
    if (evaluateCondition(providers.config, providers, field.required, providers.formValues)) {
      validators.push(required);
    }
  }
  Array.prototype.push.apply(
    validators,
    (field.validation || [])
      .map(name => {
        if (typeof name === 'function') {
          return name;
        }
        switch (name) {
          case 'required':
            return required;
          case 'datetime':
            return validateDateTime;
          case 'integer':
            return validateNumber;
          case 'defined':
            return defined;
          case 'subform':
            return validateSubForm;
          case 'subforms':
            return validateSubForms;
          default:
            return null;
        }
      })
      .filter(it => it),
  );
  return validators;
};

export const validateField = (field, value) => {
  if (field.validators) {
    return field.validators.reduce((valid, validator) => valid && validator(value), true);
  }
  return true;
};

export const haveValuesChanged = (prevFormValues, formValues) => {
  const prevKeys = Object.keys(prevFormValues);
  const keys = Object.keys(formValues);
  if (prevKeys.length !== keys.length) {
    return true;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    let newValue = formValues[key];
    let oldValue = prevFormValues[key];
    if (moment.isMoment(newValue)) {
      newValue = newValue.toISOString();
    }
    if (moment.isMoment(oldValue)) {
      oldValue = oldValue.toISOString();
    }
    if (newValue !== oldValue) {
      return true;
    }
  }
  return false;
};
