import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Phone } from '@mui/icons-material';
import { Typography, Grid, Tooltip, Button, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import Label from 'components/display/label';
import { togglePhonePanel, toggleOngoingPhoneCall, setPhoneNoteTags } from 'actions/action-view';
import { updateNoteBar } from 'actions/action-notes';
import { NoteTagTypes } from 'constants/note-tag-types';
import { NeedInterpreterIcon } from 'containers/patient/patient-overview/components/need-interpreter-icon';
import EditPhonesSmsPreferencesModal, {
  EditModalViewModeEnum,
} from 'components/edit-phones-sms-preferences-modal/edit-phones-sms-preferences-modal';
import { IContactListItem } from 'interfaces/redux/IContactList';
import { IState } from 'interfaces/redux/IState';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { SMSIcon } from 'components/icons/icons';
import { buildQaId } from 'utils/build-qa-id';
import { PhoneUtils } from 'utils/phone-utils';
import { InterpreterService } from 'clients/interpreter-service';
import { IInterpreterService } from 'models/services/interpreter-service/IInterpreterService';
import { openCommunicationNotesSideBar } from 'actions/action-notes';
import { NoteBarTrigger } from 'constants/enums';
import { styles } from './talkdesk-phone-number.styles';
import { IProps } from './IProps';

const TalkDeskPhoneNumber: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    classes,
    number: phoneNumber,
    headerText,
    additionalInlineText,
    availability,
    owner,
    tagInfo,
    preferSms,
    useOpenPanel = false,
  } = props;
  const [interpreterService] = React.useState<InterpreterService>(new InterpreterService());
  const [interpreterServicePhoneNumbers, setInterpreterServicePhoneNumbers] = React.useState<
    IInterpreterService[]
  >([]);
  const [visibleEditPhonesModal, setEditPhonesModal] = React.useState<boolean>(false);

  const getQaId = buildQaId('preferred-contact-phone');

  const dispatch = useDispatch();
  const currentPatientId = useTypedSelector((state: IState) => state.selectedPatientId);
  const tagInfoToPass = tagInfo || {
    tagTypeId: NoteTagTypes.Patient,
    tagResourceId: currentPatientId,
  };
  const isPhoneAvailable = PhoneUtils.isPhoneAvailable(availability);

  React.useEffect(() => {
    (async () => {
      if (owner && owner?.need_interpreter && currentPatientId) {
        const interpreterServicePhones = await interpreterService.getInterpreterService(
          currentPatientId,
        );
        if (interpreterServicePhones && interpreterServicePhones.phoneNumbers) {
          if (
            interpreterServicePhones.phoneNumbers.some(
              (item: IInterpreterService) => !item.service_group_name,
            )
          ) {
            setInterpreterServicePhoneNumbers(
              interpreterServicePhones.phoneNumbers.filter(
                (item: IInterpreterService) => !item.service_group_name,
              ),
            );
          } else {
            setInterpreterServicePhoneNumbers(interpreterServicePhones.phoneNumbers);
          }
        }
      }
    })();
  }, [currentPatientId, owner?.need_interpreter]);

  const handleClick = async () => {
    dispatch(updateNoteBar({ display: false }));
    if (useOpenPanel) {
      dispatch(togglePhonePanel(true));
      dispatch(toggleOngoingPhoneCall(true));
    } else if (tagInfoToPass.tagResourceId && tagInfoToPass.tagTypeId) {
      const payload = {
        noteBar: {
          display: true,
          tag: 'foobar',
          tagTypeId: tagInfoToPass?.tagTypeId,
          tagResourceId: tagInfoToPass.tagResourceId,
          trigger: NoteBarTrigger.Contact,
          initiatingTask: null,
        },
      };
      dispatch(openCommunicationNotesSideBar(payload));
    }
    if (tagInfoToPass && tagInfoToPass.tagTypeId && tagInfoToPass.tagResourceId) {
      dispatch(setPhoneNoteTags(tagInfoToPass));
    }
  };
  const renderCharmBack = () =>
    owner && owner?.need_interpreter ? (
      <NeedInterpreterIcon
        languages={owner?.languages}
        interpreterServicePhoneNumbers={interpreterServicePhoneNumbers}
      />
    ) : null;

  const renderCharmFront = () =>
    preferSms ? (
      <Tooltip title="SMS">
        <div className={classes.phoneInlineIconBackground}>
          <SMSIcon data-qa-id={getQaId('sms-icon')} className={classes.phoneInlineIcon} />
        </div>
      </Tooltip>
    ) : (
      <Tooltip title="Phone">
        <div className={classes.phoneInlineIconBackground}>
          <Phone data-qa-id={getQaId('phone-icon')} className={classes.phoneInlineIcon} />
        </div>
      </Tooltip>
    );

  const phoneText = `${additionalInlineText || ''}${phoneNumber} ${availability || ''}`;
  const placeHolder = (
    <Label
      charmFront={renderCharmFront()}
      className={classes.phoneLabel}
      labelName=""
      labelType="phone"
    />
  );
  const renderLink = () =>
    isPhoneAvailable ? (
      <a
        href={PhoneUtils.convertToTalkdeskCallableFormat(phoneNumber)}
        onClick={() => handleClick()}
      >
        <Label
          labelName={` ${phoneText}`}
          noMaxWidth
          charmFront={renderCharmFront()}
          charmBack={renderCharmBack()}
          labelType="phone"
        />
      </a>
    ) : (
      <>
        {isPhoneAvailable ? (
          <Label
            labelName={` ${phoneText}`}
            noMaxWidth
            charmFront={renderCharmFront()}
            charmBack={renderCharmBack()}
            labelType="disabled-phone"
          />
        ) : (
          <Tooltip title="Out of Available Hours">
            <Label
              labelName={` ${phoneText}`}
              noMaxWidth
              charmFront={renderCharmFront()}
              charmBack={renderCharmBack()}
              labelType="disabled-phone"
            />
          </Tooltip>
        )}
      </>
    );
  return (
    <>
      <div>
        <Grid container align-items="center">
          {headerText && (
            <Typography variant="caption" className={classes.detailHeader}>
              {headerText}
            </Typography>
          )}
        </Grid>
        {phoneNumber ? renderLink() : placeHolder}
        {!(owner as IContactListItem)?.relationship && (
          <IconButton
            onClick={() => {
              setEditPhonesModal(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        {visibleEditPhonesModal && (
          <EditPhonesSmsPreferencesModal
            visible={visibleEditPhonesModal}
            viewMode={EditModalViewModeEnum.EditPhone}
            onModalClose={() => {
              setEditPhonesModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(TalkDeskPhoneNumber);
